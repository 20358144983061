import React from "react";

const PriceBehaviorFactorDescription = ({ i18n }) => (
  <div>
    {i18n.language === "en" && (
      <div className="factor_info_en">
        <p>
          <strong>Awakened Strategy Overview</strong>
        </p>

        <p>
          <strong>Description</strong>
          <br />
          The Awakened strategy is a comprehensive trading strategy designed for
          financial markets, focusing on trend following and risk management. It
          combines traditional technical analysis methods with unique entry and
          exit rules, aiming to capture trends while minimizing risks associated
          with market gaps and extreme price movements.
        </p>

        <p>
          <strong>Features</strong>
          <br />
          - Ichimoku Indicator Calculation: Utilizes the Ichimoku Cloud
          components for trend identification, including the Conversion Line,
          Base Line, and Leading Spans A & B.
          <br />
          - Heikin Ashi Calculation: Employs Heikin Ashi candles to smooth out
          price action, making trends and potential reversals easier to
          identify.
          <br />
          - Moving Average Convergence: Analyzes the relationship between
          short-term and long-term moving averages to identify potential trend
          strength or weakness.
          <br />
          - Risk Management: Incorporates gap up and gap down checks to avoid
          entering positions during potentially risky market conditions.
          Additionally, it considers the price's position relative to the
          Ichimoku Cloud's Leading Span B to manage entry points more
          effectively.
          <br />- Dynamic Entry and Exit Criteria: Combines multiple indicators
          and conditions to define clear entry and exit signals, aiming to enter
          the market in favorable conditions and exit to protect gains or
          minimize losses.
        </p>

        <p>
          <strong>Entry Logic</strong>
          <br />
          The strategy enters a long position when the following conditions are
          met:
          <br />
          - The trend is identified as positive based on the Ichimoku and Heikin
          Ashi calculations.
          <br />
          - The price action is favorable, avoiding significant gaps and
          considering the moving average convergence.
          <br />- Risk management conditions are satisfied, ensuring the entry
          is made in a relatively safe market environment.
        </p>

        <p>
          <strong>Exit Logic</strong>
          <br />
          Positions are closed based on a cross-under of the price below a
          moving average or when the price action indicates a potential trend
          reversal. Additional conditions based on the Ichimoku Cloud and risk
          management factors further refine the exit strategy to protect against
          losses.
        </p>

        <p>
          <strong>Configuration</strong>
          <br />
          The strategy is highly configurable, allowing users to adjust various
          parameters related to the Ichimoku Cloud, moving averages, and risk
          management thresholds to tailor the strategy to different markets or
          personal risk tolerances.
        </p>

        <p>
          <strong>Disclaimer</strong>
          <br />
          This strategy is provided for informational purposes only and should
          not be considered financial advice. Users should conduct their own
          research and consult with a financial advisor before using this
          strategy in live trading.
        </p>

        <p>
          <strong>Installation and Usage</strong>
          <br />
          Please refer to the specific instructions provided with the strategy
          for details on how to install and configure it for use in your trading
          platform.
        </p>

        <p>
          <strong>Contributing</strong>
          <br />
          Feedback and contributions are welcome.
        </p>

        <p>
          <strong>License</strong>
          <br />
          This strategy is released under a proprietary license. Redistribution
          and commercial use are not permitted without prior written consent.
        </p>
      </div>
    )}
    {i18n.language === "zh" && (
      <div className="factor_info_zh">
        <p>
          <strong>觉悟策略概览</strong>
        </p>

        <p>
          <strong>描述</strong>
          <br />
          觉悟策略是一个为金融市场设计的综合交易策略，侧重于趋势跟踪和风险管理。它结合了传统的技术分析方法和独特的进出规则，旨在捕捉趋势，同时最小化市场缺口和极端价格运动相关的风险。
        </p>

        <p>
          <strong>特点</strong>
          <br />
          -
          一目均衡表指标计算：利用一目均衡表的组成部分进行趋势识别，包括转换线、基准线和前导跨度A&B。
          <br />
          -
          平均足计算：使用平均足蜡烛图来平滑价格动作，使趋势和潜在的反转更容易识别。
          <br />
          -
          移动平均线收敛：分析短期和长期移动平均线之间的关系，以识别潜在的趋势强度或弱点。
          <br />
          -
          风险管理：纳入向上和向下的缺口检查，以避免在潜在风险较高的市场条件下进入头寸。此外，它还考虑了价格相对于一目均衡表前导跨度B的位置，以更有效地管理进入点。
          <br />-
          动态进出标准：结合多个指标和条件来定义清晰的进出信号，目标是在有利条件下进入市场，并在保护收益或最小化损失时退出。
        </p>

        <p>
          <strong>进场逻辑</strong>
          <br />
          当满足以下条件时，策略进入多头头寸：
          <br />
          - 根据一目均衡表和平均足计算，趋势被识别为正面。
          <br />
          - 价格行为有利，避免重大缺口，并考虑移动平均线的收敛。
          <br />- 满足风险管理条件，确保在相对安全的市场环境中进行入场。
        </p>

        <p>
          <strong>出场逻辑</strong>
          <br />
          基于价格下穿移动平均线或当价格行为表明潜在趋势反转时关闭头寸。基于一目均衡表和风险管理因素的额外条件进一步完善了出场策略，以防止损失。
        </p>

        <p>
          <strong>配置</strong>
          <br />
          策略高度可配置，允许用户调整与一目均衡表、移动平均线和风险管理阈值相关的各种参数，以将策略定制到不同的市场或个人风险承受能力。
        </p>

        <p>
          <strong>免责声明</strong>
          <br />
          此策略仅供信息目的提供，不应被视为财务建议。用户应进行自己的研究，并在使用此策略进行实盘交易前咨询财务顾问。
        </p>

        <p>
          <strong>安装和使用</strong>
          <br />
          请参阅策略提供的具体说明，了解如何在交易平台中安装和配置它的详细信息。
        </p>

        <p>
          <strong>贡献</strong>
          <br />
          欢迎反馈和贡献。
        </p>

        <p>
          <strong>许可证</strong>
          <br />
          此策略在专有许可下发布。未经事先书面同意，不得重新分发和商业使用。
        </p>
      </div>
    )}
  </div>
);

export const PriceBehaviorFactor = {
  id: 1,
  nameKey: "FactorDetail:price_behavior_factor",
  descriptionComponent: PriceBehaviorFactorDescription,
  factorUrls: ["https://www.tradingview.com/script/yRT0CfsE-Awakened/"],
  imageName: "Awakened",
};
