import React, { useState } from "react";
import MatrixRainingLetters from "./common/MatrixRainingLetters";
import { Helmet } from "react-helmet";
const NotificationSender = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (username === "nhwt" && password === "nhwt") {
      localStorage.setItem("isLoggedIn", "true");
      setIsLoggedIn(true);
    } else {
      alert("用户名或密码错误");
    }
  };

  const handleNotificationSubmit = (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to send this message?"
    );
    if (isConfirmed) {
      // 发送消息到 Node 服务器
      fetch("https://api.nhwt.tech/extension-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Message sent successfully:", data);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
        });
    } else {
      console.log("Message sending cancelled");
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <div className="fixed top-0 left-0 w-full h-full">
        <MatrixRainingLetters custom_class="m-0 p-0 absolute w-full h-full" />
        <div className="flex justify-center items-center h-full">
          <div className="relative z-10 bg-black p-8 rounded-lg shadow-lg border border-green-500">
            <h2 className="text-2xl mb-6 text-green-500 font-mono">Login</h2>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              className="border-2 border-green-500 p-3 mr-2 w-full mb-4 bg-gray-900 text-green-500 placeholder-green-500 rounded focus:ring-2 focus:ring-green-500 focus:outline-none"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="p-3 w-full mb-6 border-2 border-green-500 bg-gray-900 text-green-500 placeholder-green-500 rounded focus:ring-2 focus:ring-green-500 focus:outline-none"
            />
            <button
              onClick={handleLoginSubmit}
              className="border-2 border-green-500 hover:border-green-500 bg-gray-900 text-green-500 hover:bg-green-700 p-3 rounded w-full transition duration-300 ease-in-out transform"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <MatrixRainingLetters custom_class="m-0 p-0 absolute w-full h-full" />
      <div className="flex justify-center items-center h-full">
        <div className="relative z-10 bg-black p-8 rounded-lg shadow-lg border border-green-500 w-full max-w-xl">
          <h2 className="text-2xl mb-6 text-green-500 font-mono">
            Send Message
          </h2>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Message"
            maxLength="200"
            rows="4"
            className="border-2 border-green-500 p-3 w-full mb-4 bg-gray-900 text-green-500 placeholder-green-500 rounded focus:ring-2 focus:ring-green-500 focus:outline-none"
          ></textarea>

          <div className="text-green-500 text-right mr-2 mb-4">
            {message.length}/200
          </div>
          <button
            onClick={handleNotificationSubmit}
            className="border-2 border-green-500 hover:border-green-500 bg-gray-900 text-green-500 hover:bg-green-700 p-3 rounded w-full transition duration-300 ease-in-out transform"
          >
            Send
          </button>
          <button
            onClick={handleLogout}
            className="mt-4 border-2 border-green-500 hover:border-green-500 bg-gray-900 text-green-500 hover:bg-green-700 p-3 rounded w-full transition duration-300 ease-in-out transform"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationSender;
