// NewsEducation.js

/* "News & Education" 页面应该包含以下模块：

1. **行业新闻** - 提供关于金融市场和经济的最新新闻。
2. **分析评论** - 提供市场趋势、经济数据分析和专家意见。
3. **教育资源** - 包括投资教育、市场原理、金融工具的教程和指南。
4. **研究报告** - 提供深度研究报告和白皮书，供用户下载和阅读。
5. **视频教程** - 提供视频格式的教育内容，包括操作指南、策略讲座等。
6. **FAQ** - 常见问题解答，帮助用户快速解决常见问题。
7. **网上研讨会和活动** - 信息关于即将举行的网上研讨会和其他教育活动。
8. **市场数据解读** - 如何阅读和理解市场数据的指导。

这些模块应综合设计以提供全面的信息和教育支持，使用户能够在一个集成的平台上获取知识和新闻。 */
import React from "react";
import Header from "./common/Header";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const NewsEducationStyles = `
  /* 样式优化: 使用现代化UI元素 */
  
  /* Style Enhancements: Inspired by Bloomberg Terminal UI Elements */
  .NewsEducation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Bloomberg-style dark background */
    color: #d1d1d1; /* Soft white text for better readability */
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Bloomberg Terminal font or similar */
  }
  

  .NewsEducation-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default layout for wider screens */
    gap: 20px;
    /* Other styles... */
  }

  /* Media query for screens with a max-width of 768px */
  @media screen and (max-width: 768px) {
    .NewsEducation-content {
      grid-template-columns: repeat(1, 1fr); /* Single column layout for narrower screens */
    }
  }

  
  .NewsEducation-card {    background: #111; /* Card background color similar to Bloomberg's palette */
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px; /* Rounded borders for cards */
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadows for depth */
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; /* Title font size */
    color: #2962ff; /* Bloomberg-like highlight color */
    margin-bottom: 15px; /* Space below title */
  }
  
  .content-text {
    font-size: 16px; /* Text font size */
    margin-bottom: 10px; /* Space below text */
    line-height: 1.6; /* Line height for readability */
  }
`;

function NewsEducation() {
  const { t } = useTranslation(); // 初始化useTranslation钩子

  return (
    <div>
      <Header />
      <div className="NewsEducation-container">
        <div className="NewsEducation-content">
          {/* 行业新闻 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:industryNewsTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:industryNewsContent")}
            </p>
          </motion.div>

          {/* 分析评论 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:analysisCommentaryTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:analysisCommentaryContent")}
            </p>
          </motion.div>

          {/* 教育资源 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:educationalResourcesTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:educationalResourcesContent")}
            </p>
          </motion.div>

          {/* 研究报告 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:researchReportsTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:researchReportsContent")}
            </p>
          </motion.div>

          {/* 视频教程 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:videoTutorialsTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:videoTutorialsContent")}
            </p>
          </motion.div>

          {/* FAQ */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">{t("NewsEducation:faqTitle")}</h1>
            <p className="content-text">{t("NewsEducation:faqContent")}</p>
          </motion.div>

          {/* 网上研讨会和活动 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:webinarsEventsTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:webinarsEventsContent")}
            </p>
          </motion.div>

          {/* 市场数据解读 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="NewsEducation-card"
          >
            <h1 className="content-title">
              {t("NewsEducation:marketDataTitle")}
            </h1>
            <p className="content-text">
              {t("NewsEducation:marketDataContent")}
            </p>
          </motion.div>

          {/* Additional card sections can be added below */}
          {/* ... */}
        </div>
        <style>{NewsEducationStyles}</style>
      </div>
    </div>
  );
}

export default NewsEducation;
