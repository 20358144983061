// NewPage.js
import React from "react";
import Header from "./common/Header";
import DonationBox from "./common/DonationBox";
import nhwt from "../assets/nhwt.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// 假设这个页面用于展示一些特定内容
const NewPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>Science and Education Fund - 科学与教育基金 | New Horizon</title>
        <meta
          name="description"
          content="Advancing education in Arts, STEM fields, and technology. Supporting programs, scholarships, and initiatives that inspire exploration and innovation."
        />
        <meta
          name="keywords"
          content="Science and Education Fund, 科学与教育基金, STEM Education, Scholarships, Technology, Engineering, Mathematics, New Horizon"
        />
        <link rel="canonical" href="https://nhwt.tech/science-education-fund" />
      </Helmet>
      <Header />
      <div className="flex flex-col items-center justify-center relative sm:top-[-50px] md:top-[-110px] lg:top-[-50px]">
        <img
          className="w-[80px] mb-4 mt-[180px]" // mb-4 是为了在图片和header之间提供一些空间
          src={nhwt}
          alt="New Horizon, Public Welfare Donation to Science and Education Fund|新长慧和科技，科教基金公益基金"
        />
        <h1 className="text-white text-2xl font-bold mb-[12px] sm:text-xl text-center">
          {t("ScienceEducationFundPage:publicWelfareDonation")}
        </h1>
        <h1 className="text-white text-2xl mb-[20px] sm:text-xl text-center">
          {t("ScienceEducationFundPage:slogan")}
        </h1>
        <DonationBox />
      </div>
    </div>
  );
};

export default NewPage;
