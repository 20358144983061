import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import earthMapTexture from "../../assets/earth/8081_earthlights2k.jpg";
import earthMapMaskTexture from "../../assets/earth/8081_earthlights2kMask.jpg";
import bumpMapTexture from "../../assets/earth/8081_earthbump2k.jpg";
import bumpCloudTexture from "../../assets/earth/8081-earthhiresclouds4k.jpg";
import specularMapTexture from "../../assets/earth/8081_earthspec2k.jpg";
import cloudMapTexture from "../../assets/earth/earthcloudmap.jpg";
import cloudMapTransTexture from "../../assets/earth/earthcloudmaptrans.jpg";

const Earth = () => {
  const mountRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [scaleUniform, setScaleUniform] = useState({ value: 1 });

  useEffect(() => {
    const currentMountRef = mountRef.current; // 存储当前的引用
    // 创建场景、相机和渲染器
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / 861,
      0.1,
      1000
    );
    camera.position.set(0, 3.5, 5);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, 861);
    renderer.shadowMap.enabled = true; // 启用阴影映射
    mountRef.current.appendChild(renderer.domElement);

    // 加载纹理并创建地球
    const textureLoader = new THREE.TextureLoader();
    const earthMap = textureLoader.load(earthMapTexture);
    const bumpMap = textureLoader.load(bumpMapTexture);
    const cloudMap = textureLoader.load(cloudMapTexture);
    const cloudMapTrans = textureLoader.load(cloudMapTransTexture);

    const atmosphereVertexShader = `
  varying vec3 vertexNormal;
  void main() {
    vertexNormal = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

    const atmosphereFragmentShader = `
  varying vec3 vertexNormal;
  void main() {
    float y = (vertexNormal.y + 1.0) / 2.0; // 将 y 分量映射到 [0, 1] 范围
    float alpha = smoothstep(0.25, 1.0, y); // 在 y = 0 到 y = 0.5 之间平滑过渡透明度
    float intensity = pow(0.9 - dot(vertexNormal, vec3(0, 0, 1)), 2.0);
    gl_FragColor = vec4(0.3, 0.5, 1.0, alpha) * intensity;
  }
`;

    const atmosphereMaterial = new THREE.ShaderMaterial({
      vertexShader: atmosphereVertexShader,
      fragmentShader: atmosphereFragmentShader,
      blending: THREE.AdditiveBlending,
      side: THREE.BackSide,
      transparent: true,
    });
    const atmosphereGeometry = new THREE.SphereGeometry(2.2, 64, 64); // 稍微大于地球的球体
    const atmosphere = new THREE.Mesh(atmosphereGeometry, atmosphereMaterial);
    scene.add(atmosphere);

    // Earth
    const earthGeometry = new THREE.SphereGeometry(2, 64, 64);
    const earthMaterial = new THREE.MeshPhysicalMaterial({
      map: earthMap,
      emissiveMap: textureLoader.load(earthMapMaskTexture), // 使用相同的纹理作为自发光贴图
      emissive: new THREE.Color(0xecc476), // 设置自发光颜色为白色
      emissiveIntensity: 1.5, // 调整自发光强度
      bumpMap: bumpMap,
      bumpScale: 8,
      blending: THREE.AdditiveBlending,
    });

    const earth = new THREE.Mesh(earthGeometry, earthMaterial);
    earth.castShadow = true; // 如果地球需要投射阴影
    earth.receiveShadow = true; // 如果地球需要接收阴影
    scene.add(earth);
    // Clouds
    const cloudGeometry = new THREE.SphereGeometry(2.02, 64, 64); // slightly bigger than the earth sphere
    const cloudMaterial = new THREE.MeshPhysicalMaterial({
      map: cloudMap,
      alphaMap: cloudMapTrans,
      opacity: 0.8, // 根据需要调整透明度
      transparent: true,
      blending: THREE.AdditiveBlending,
      color: new THREE.Color(0x0045ff), // 蓝紫色
    });
    const clouds = new THREE.Mesh(cloudGeometry, cloudMaterial);
    clouds.castShadow = true; // 如果云层需要投射阴影
    clouds.receiveShadow = false; // 云层通常不需要接收阴影
    scene.add(clouds);
    // 添加光源
    const ambientLight = new THREE.DirectionalLight(0x303f9f, 0.1);
    ambientLight.position.set(0, 500, 1);
    scene.add(ambientLight);

    // 添加一个定向光，模拟来自远处的光源，如月光
    const moonLight = new THREE.DirectionalLight(0x8888ff, 0.05);
    moonLight.position.set(0, 1000, 100);
    scene.add(moonLight);

    const directionalLightSun = new THREE.DirectionalLight(0xe0bc83, 0.8); // 调整定向光强度
    directionalLightSun.position.set(18, 15, -50);
    const directionalLight = new THREE.DirectionalLight(0xfdfdf5, 5); // 调整定向光强度
    directionalLight.position.set(12, 5, -50);
    scene.add(directionalLight);
    scene.add(directionalLightSun);

    // 设置OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableRotate = false;
    controls.enableDamping = true;
    controls.enableZoom = false;
    controls.maxDistance = 4.25;
    controls.minDistance = 4.25;

    // 动画循环
    const animate = () => {
      requestAnimationFrame(animate);
      earth.rotation.y += 0.002; // 地球自转
      clouds.rotation.y += 0.001; // Clouds rotation
      controls.update();
      renderer.shadowMap.enabled = true;
      renderer.render(scene, camera);
    };
    animate();

    // 处理滚轮事件来实现自定义缩放
    const handleZoom = (event) => {
      event.preventDefault(); // 阻止默认滚动行为
      const zoomDirection = event.deltaY > 0 ? -1 : 1;
      const zoomAmount = 0.1;
      const newTargetZ = camera.position.z + zoomDirection * zoomAmount;
      if (
        newTargetZ >= controls.minDistance &&
        newTargetZ <= controls.maxDistance
      ) {
        camera.position.z = newTargetZ;
        controls.update(); // 确保在缩放中更新控制器
      } else {
        // 如果达到缩放范围，则允许页面滚动
        window.scrollBy(0, event.deltaY);
      }
    };

    // 处理页面滚动事件来调整地球的缩放
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 900 && scrollY <= 2500) {
        const newScale = Math.min(1 + (scrollY - 900) / 1600, 1.2);
        setScale(newScale);
        earth.scale.set(newScale, newScale, newScale);
        clouds.scale.set(newScale, newScale, newScale);
        atmosphere.scale.set(newScale, newScale, newScale);
      }
    };

    renderer.domElement.addEventListener("wheel", handleZoom);

    window.addEventListener("scroll", handleScroll);

    // 清理资源
    return () => {
      renderer.domElement.removeEventListener("wheel", handleZoom);
      window.removeEventListener("scroll", handleScroll);
      controls.dispose();
      if (currentMountRef && currentMountRef.contains(renderer.domElement)) {
        currentMountRef.removeChild(renderer.domElement);
      }
    };
  }, []);

  return <div ref={mountRef} className="h-[800px]"></div>;
};

export default Earth;
