import React from "react";

const OscillationRangeAnalyzerDescription = ({ i18n }) => (
  <div>
    {i18n.language === "en" && (
      <div className="factor_info_en">
        <p>
          <b>Oscillation Range Analyzer [NewHorizon]</b>
        </p>
        <p>
          <b>Description:</b>
          <br />
          The Oscillation Range Analyzer is a TradingView indicator designed to
          identify and visualize oscillation ranges in price action. It uses a
          combination of moving averages and the Average True Range (ATR) to
          determine the boundaries of these ranges and provides visual cues to
          help traders make informed decisions.
        </p>
        <p>
          <b>Features:</b>
          <br />- <b>Range Identification:</b> Utilizes moving averages and ATR
          to identify oscillation ranges.
          <br />- <b>Visual Cues:</b> Displays boxes and lines to highlight the
          upper and lower bounds of the oscillation range, as well as the
          average line within the range.
          <br />- <b>Customizable Parameters:</b> Allows users to adjust the
          length of the moving average, the width of the range, and the length
          of the ATR calculation.
          <br />- <b>Color Coding:</b> Uses different colors to indicate the
          position of the closing price relative to the oscillation range,
          aiding in quick visual analysis.
        </p>
        <p>
          <b>Inputs:</b>
          <br />- <b>Minimum Range Length:</b> The length of the moving average
          used to determine the center of the oscillation range.
          <br />- <b>Range Width:</b> A multiplier for the ATR value to
          determine the width of the oscillation range.
          <br />- <b>ATR Length:</b> The length of the ATR calculation.
        </p>
        <p>
          <b>Usage:</b>
          <br />
          1. Add the indicator to your TradingView chart.
          <br />
          2. Adjust the input parameters according to your trading strategy or
          preferences.
          <br />
          3. Analyze the oscillation ranges and their relationship with the
          price action to make informed trading decisions.
        </p>
        <p>
          <b>Disclaimer:</b>
          <br />
          This indicator is provided as is, and the author is not responsible
          for any financial losses incurred from using this tool. Always conduct
          your own analysis and consult with a financial advisor before making
          trading decisions.
        </p>
        <p>
          <b>Author:</b>
          <br />
          NewHorizonWisdomTech
        </p>
      </div>
    )}
    {i18n.language === "zh" && (
      <div className="factor_info_zh">
        <p>
          <b>振荡范围分析器 [NewHorizon]</b>
        </p>
        <p>
          <b>描述：</b>
          <br />
          振荡范围分析器旨在识别和可视化价格波动范围。它利用移动平均线和平均真实波幅（ATR）的组合来确定这些范围的边界，并提供视觉线索，帮助交易员做出明智的决策。
        </p>
        <p>
          <b>特点：</b>
          <br />- <b>范围识别：</b> 利用移动平均线和ATR来识别价格波动范围。
          <br />- <b>视觉线索：</b>{" "}
          显示框和线条来突出显示波动范围的上限和下限，以及范围内的平均线。
          <br />- <b>可定制参数：</b>{" "}
          允许用户调整移动平均线的长度、范围的宽度以及ATR计算的长度。
          <br />- <b>颜色编码：</b>{" "}
          使用不同的颜色来指示收盘价格相对于波动范围的位置，帮助快速视觉分析。
        </p>
        <p>
          <b>输入：</b>
          <br />- <b>最小范围长度：</b> 用于确定波动范围中心的移动平均线长度。
          <br />- <b>范围宽度：</b> ATR值的乘数，用于确定波动范围的宽度。
          <br />- <b>ATR长度：</b> ATR计算的长度。
        </p>
        <p>
          <b>用法：</b>
          <br />
          1. 将指标添加到您的TradingView图表中。
          <br />
          2. 根据您的交易策略或偏好调整输入参数。
          <br />
          3. 分析波动范围及其与价格走势的关系，以做出明智的交易决策。
        </p>
        <p>
          <b>免责声明：</b>
          <br />
          本指标按原样提供，作者不对使用本工具而导致的任何财务损失负责。在做出交易决策之前，请始终进行自己的分析并咨询财务顾问。
        </p>
        <p>
          <b>作者：</b>
          <br />
          NewHorizonWisdomTech
        </p>
      </div>
    )}
  </div>
);

export const OscillationRangeAnalyzer = {
  id: 2,
  nameKey: "FactorDetail:OscillationRangeAnalyzer",
  descriptionComponent: OscillationRangeAnalyzerDescription,
  factorUrls: [
    "https://www.tradingview.com/script/MuLbuYnF-Oscillation-Range-Analyzer-NewHorizon/",
  ],
  imageName: "OscillationRangeAnalyzer",
};
