import Header from "./common/Header";
import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa";
import axios from "axios";
import { useFactors } from "./common/factors";
import Footer from "./common/Footer";
const URLDisplay = ({ factorUrls }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = (index) => {
    setCopiedIndex(index);

    // Reset copied state after a certain duration
    setTimeout(() => {
      setCopiedIndex(null);
    }, 2000);
  };

  return (
    <div>
      {factorUrls.map((url, index) => (
        <div
          className="relative bg-[#282A36] py-[14px] px-4 mt-3 rounded-md text-md"
          key={index}
        >
          <p>{url}</p>
          <CopyToClipboard
            className="absolute top-[6px] right-[6px] bg-[#44475A] p-1.5 rounded-md text-xs"
            text={url}
            onCopy={() => handleCopy(index)}
          >
            <button>
              {copiedIndex === index ? "Copied!" : "Copy to Clipboard"}
            </button>
          </CopyToClipboard>
        </div>
      ))}
    </div>
  );
};

function FactorDetail({ factorNumber }) {
  const factors = useFactors();
  const { t, i18n } = useTranslation();
  const [userRole, setUserRole] = useState("user"); // 默认为'guest'

  // 根据 factorNumber 获取对应因子的数据，这里假设有一个 factors 数据数组
  const isVIPPro = userRole === "VIPPro";

  useEffect(() => {
    const token = localStorage.getItem("token"); // 从本地存储中获取 token
    if (token) {
      axios
        .get("/api/user-role", {
          headers: {
            Authorization: `${token}`, // 确保使用正确的格式，通常是'Bearer <token>'
          },
        })
        .then((response) => {
          setUserRole(response.data.role); // 假设角色信息在response.data.role中
        })
        .catch((error) => {
          console.error("前台获取用户角色出错", error);
        });
    } else {
      console.error("未找到Token");
    }
  }, []);

  const navigate = useNavigate();

  const codeStrings = {
    1: `// This work is licensed under a Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0) https://creativecommons.org/licenses/by-nc-sa/4.0/
// © New Horizon

//@version=5
indicator("Market Structure CHoCH/BOS (Fractal) [LuxAlgo]", "LuxAlgo - Market Structure (Fractal)", overlay = true, max_lines_count = 500, max_labels_count = 500)
//------------------------------------------------------------------------------
// Settings
//-----------------------------------------------------------------------------{
length = input.int(5, minval = 3)

//Colors
showBull = input(true, 'Bullish Structures', inline = 'bull', group = 'Style')
bullCss = input.color(#089981, '', inline = 'bull', group = 'Style')

showBear = input(true, 'Bearish Structures', inline = 'bear', group = 'Style')
bearCss = input.color(#f23645, '', inline = 'bear', group = 'Style')

showSupport = input(false, 'Support', inline = 's', group = 'Style')
supCss = input.color(#089981, '', inline = 's', group = 'Style')

showResistance = input(false, 'Resistance', inline = 'r', group = 'Style')
resCss = input.color(#f23645, '', inline = 'r', group = 'Style')

//Dashboard
showDash  = input(false, 'Show Dashboard', group = 'Dashboard')
dashLoc  = input.string('Top Right', 'Location', options = ['Top Right', 'Bottom Right', 'Bottom Left'], group = 'Dashboard')
textSize = input.string('Small', 'Size'        , options = ['Tiny', 'Small', 'Normal']                 , group = 'Dashboard')

//-----------------------------------------------------------------------------}
//Types
//-----------------------------------------------------------------------------{
type fractal
    float value
    int loc
    bool iscrossed

//-----------------------------------------------------------------------------}
//Fractal Detection
//-----------------------------------------------------------------------------{
var p = int(length/2)
n = bar_index

dh = math.sum(math.sign(high - high[1]), p)
dl = math.sum(math.sign(low - low[1]), p)

bullf = dh == -p and dh[p] == p and high[p] == ta.highest(length)
bearf = dl == p and dl[p] == -p and low[p] == ta.lowest(length)

bullf_count = ta.cum(bullf ? 1 : 0)
bearf_count = ta.cum(bearf ? 1 : 0)

//-----------------------------------------------------------------------------}
//Bullish market structure
//-----------------------------------------------------------------------------{
var upper = fractal.new()
var line lower_lvl = na
var label ms_lbl = na
var bull_ms_count = 0
var broken_sup = false
var os = 0

if bullf
    upper.value := high[p]
    upper.loc := n-p
    upper.iscrossed := false

if ta.crossover(close, upper.value) and not upper.iscrossed
    line.new(upper.loc, upper.value, n, upper.value, color = showBull ? bullCss : na)

    ms_lbl := label.new(int(math.avg(n, upper.loc)), upper.value, os == -1 ? 'ChoCH' : 'BOS'
      , color = color(na)
      , textcolor = showBull ? bullCss : na
      , style = label.style_label_down
      , size = size.tiny)

    //Set support
    k = 2
    min = low[1]
    for i = 2 to (n - upper.loc)-1
        min := math.min(low[i], min)
        k := low[i] == min ? i : k

    if showSupport
        lower_lvl := line.new(n-k, min, n, min, color = bullCss, style = line.style_dashed)
        broken_sup := false

    upper.iscrossed := true
    bull_ms_count += 1
    os := 1

else if showSupport and not broken_sup
    lower_lvl.set_x2(n)

    if close < lower_lvl.get_y2()
        broken_sup := true

//-----------------------------------------------------------------------------}
//Bearish market structure
//-----------------------------------------------------------------------------{
var lower = fractal.new()
var line upper_lvl = na
var broken_res = false
var bear_ms_count = 0

if bearf
    lower.value := low[p]
    lower.loc := n-p
    lower.iscrossed := false

if ta.crossunder(close, lower.value) and not lower.iscrossed
    line.new(lower.loc, lower.value, n, lower.value, color = showBear ? bearCss : na)
    
    label.new(int(math.avg(n, lower.loc)), lower.value, os == 1 ? 'ChoCH' : 'BOS'
      , color = color(na)
      , textcolor = showBear ? bearCss : na
      , style = label.style_label_up
      , size = size.tiny)

    //Set resistance
    k = 2
    max = high[1]
    for i = 2 to (n - lower.loc)-1
        max := math.max(high[i], max)
        k := high[i] == max ? i : k

    if showResistance
        upper_lvl := line.new(n-k, max, n, max, color = bearCss, style = line.style_dashed)
        broken_res := false
    
    lower.iscrossed := true
    bear_ms_count += 1
    os := -1

else if showResistance and not broken_res
    upper_lvl.set_x2(n)

    if close > upper_lvl.get_y2()
        broken_res := true

//-----------------------------------------------------------------------------}
//Dashboard
//-----------------------------------------------------------------------------{
var table_position = dashLoc == 'Bottom Left' ? position.bottom_left 
  : dashLoc == 'Top Right' ? position.top_right 
  : position.bottom_right

var table_size = textSize == 'Tiny' ? size.tiny 
  : textSize == 'Small' ? size.small 
  : size.normal

var tb = table.new(table_position, 2, 3
  , bgcolor = #1e222d
  , border_color = #373a46
  , border_width = 1
  , frame_color = #373a46
  , frame_width = 1)

if showDash
    if barstate.isfirst
        tb.cell(0, 0, 'Structure To Fractal %', text_color = color.white, text_size = table_size)
        tb.merge_cells(0,0,1,0)

        tb.cell(0, 1, 'Bullish', text_color = #089981, text_size = table_size)
        tb.cell(1, 1, 'Bearish', text_color = #f23645, text_size = table_size)
    
    if barstate.islast
        tb.cell(0, 2, str.tostring(bull_ms_count / bullf_count * 100, format.percent), text_color = #089981, text_size = table_size)
        tb.cell(1, 2, str.tostring(bear_ms_count / bearf_count * 100, format.percent), text_color = #f23645, text_size = table_size)

//-----------------------------------------------------------------------------}
//Plots
//-----------------------------------------------------------------------------{
plot(broken_res and not broken_res[1] ? low : na, 'Resistance Breakout', #089981, 2, plot.style_circles)
plot(broken_sup and not broken_sup[1] ? high : na, 'Support Breakout', #f23645, 2, plot.style_circles)

//-----------------------------------------------------------------------------}`,

    2: `//@version=4
      study(title="Historical Volatility 历史波动率", shorttitle="HV", format=format.percentage)
      length = input(14, "Length", minval=1)
      src = input(close, "Source")
      logReturns = log(src / src[1])
      volatility = stdev(logReturns, length) * sqrt(252) * 100
      plot(volatility, "Volatility", color=color.blue)`,

    3: `//@version=4
    study(title="Historical Volatility 历史波动率", shorttitle="HV", format=format.percentage)
    length = input(14, "Length", minval=1)
    src = input(close, "Source")
    logReturns = log(src / src[1])
    volatility = stdev(logReturns, length) * sqrt(252) * 100
    plot(volatility, "Volatility", color=color.blue)`,

    4: `//@version=4
      study(title="Historical Volatility 历史波动率", shorttitle="HV", format=format.percentage)
      length = input(14, "Length", minval=1)
      src = input(close, "Source")
      logReturns = log(src / src[1])
      volatility = stdev(logReturns, length) * sqrt(252) * 100
      plot(volatility, "Volatility", color=color.blue)`,

    5: `//@version=4
    study(title="Historical Volatility 历史波动率", shorttitle="HV", format=format.percentage)
    length = input(14, "Length", minval=1)
    src = input(close, "Source")
    logReturns = log(src / src[1])
    volatility = stdev(logReturns, length) * sqrt(252) * 100
    plot(volatility, "Volatility", color=color.blue)`,

    6: `//@version=4
      study(title="Historical Volatility 历史波动率", shorttitle="HV", format=format.percentage)
      length = input(14, "Length", minval=1)
      src = input(close, "Source")
      logReturns = log(src / src[1])
      volatility = stdev(logReturns, length) * sqrt(252) * 100
      plot(volatility, "Volatility", color=color.blue)`,

    7: `//@version=4
    study(title="Historical Volatility 历史波动率", shorttitle="HV", format=format.percentage)
    length = input(14, "Length", minval=1)
    src = input(close, "Source")
    logReturns = log(src / src[1])
    volatility = stdev(logReturns, length) * sqrt(252) * 100
    plot(volatility, "Volatility", color=color.blue)`,
    /*   */
    // 添加更多代码字符串...
  };

  const [isCopied, setIsCopied] = useState(false);
  const onCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000); // Reset the copied status after 3 seconds
  };

  // 根据 factorNumber 查找对应因子的数据
  const factor = factors.find((factor) => factor.id === factorNumber);

  // 如果找不到对应因子，可以显示一个错误消息或重定向到其他页面
  if (!factor) {
    return <div>{t("FactorDetail:not_found")}</div>;
  }

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center h-full text-gray-300 font-[Bloomberg Terminal,Segoe UI,Tahoma,Geneva,Verdana,sans-serif]">
        <h1
          style={{
            color: "#ffffff",
          }}
        >
          {factor.name}
        </h1>

        {/* 在此处显示其他因子数据 */}
        <div className="mt-10 w-[90vw] pb-[80px]">
          <div
            style={{
              color: "#ffffff",
              textAlign: "left",
            }}
          >
            {factor.description}
          </div>
          <div className="flex justify-center items-center w-full">
            {/* 添加图片 */}
            <img
              src={require(`../assets/images/${factor.imageName}.jpg`)}
              alt={factor.nameKey}
              className="pt-[30px] w-full max-w-[860px] h-auto"
            />
          </div>
          {/*        <div
            style={{ position: "relative", margin: "50px 0px", width: "90vw" }}
          >
            {isVIPPro ? (
              <>
                <SyntaxHighlighter
                  language="javascript"
                  style={dracula}
                  className="syntax-highlighter"
                >
                  {codeStrings[factor.id]}
                </SyntaxHighlighter>

                <CopyToClipboard text={codeStrings[factor.id]} onCopy={onCopy}>
                  <button
                    style={{
                      position: "absolute",
                      top: "6px",
                      right: "6px",
                      backgroundColor: "#44475a",
                      color: "#f8f8f2",
                      border: "none",
                      cursor: "pointer",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "0.8em",
                    }}
                  >
                    {t("FactorDetail:copy")}
                  </button>
                </CopyToClipboard>

                {isCopied ? (
                  <span
                    style={{
                      position: "absolute",
                      top: "14px",
                      left: "6px",
                      color: "#50fa7b",
                      backgroundColor: "#44475a",
                      padding: "3px 6px",
                      borderRadius: "4px",
                      fontSize: "0.8em",
                    }}
                  >
                    {t("FactorDetail:copied")}
                  </span>
                ) : null}
              </>
            ) : (
              <div className="relative bg-[#282A36] p-5 text-[##d1d4dc] text-center rounded-md">
                {t("FactorDetail:protected_script")}
                <FaLock className="absolute top-[6px]    right-[6px] size-[10px] color-[##d1d4dc]" />
              </div>
            )}
          </div>
 */}

          <h1 className="text-mblue mt-[60px] ">
            {t("FactorDetail:get_access")}
          </h1>
          {isVIPPro ? (
            <div>
              <URLDisplay factorUrls={factor.factorUrls} />
            </div>
          ) : (
            <div className="subscribe-button-container">
              <button
                onClick={() => navigate("/subscribe")}
                className="mt-3 hover:bg-mblue rounded-md p-2 border w-[100%] border-[#434651] hover:border-mblue text-white"
              >
                {t("FactorDetail:purchase")}
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FactorDetail;
