// AccountManagement.js

//"Account Management" 页面应该包括以下几个模块：
/* 1. **个人信息管理**：允许用户更新他们的个人资料，如姓名、联系信息、密码和安全设置。
2. **账户安全**：提供两步验证、登录历史和安全通知等安全选项。
3. **订单管理**：展示账户余额、交易历史和银行信息。
4. **投资组合管理**：允许用户查看和管理他们的投资组合，包括股票、债券、基金等资产。
5. **通知设置**：让用户选择和管理他们希望接收的通知类型。
6. **支持和帮助**：提供常见问题解答（FAQ）、联系支持团队和其他帮助资源。
7. **订阅管理**：如果适用，允许用户管理他们的订阅设置，包括服务订阅、报告和通讯。
8. **登录和注销**：确保用户可以安全地登录和退出他们的账户。 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Footer from "./common/Footer";

const AccountManagemetStyles = `
  .AccountManagemet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: full;
    background-color: #000000;  
    color: #d1d1d1;  
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  }
  

  
  .AccountManagemet-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    gap: 20px;
  
  }

  @media screen and (max-width: 768px) {
    .AccountManagemet-content {
      grid-template-columns: repeat(1, 1fr); 
    }
  }

  .AccountManagemet-card {    
    background: #111;  
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px;  
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); 
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; 
    color: #2962ff;  
    margin-bottom: 15px;  
  }
  
  .content-text {
    font-size: 16px; 
    margin-bottom: 10px;  
    line-height: 1.6; 
  }
  .welcome-message {
    font-size: 1rem;  
    color: #ffffff;  
    background: #000000;  
    padding: 15px 15px; 
    border-left: 4px solid #2962ff;  
    margin: 0px auto;  
 
  }

  .download {
    font-size: 1rem;  
    color: #ffffff;  
    background: #000000;  
    padding: 15px 20px;  
   color:#2962ff;
    margin: 0px auto;  
    text-align: right; 
  }
  
 
  .username {
    text-transform: uppercase;  
    letter-spacing: 0.05em;  
  }


  @media (max-width: 768px) {
    .welcome-message {
      font-size: 1.5rem;  
      padding: 12px 20px;  
      border-left-width: 3px; 
    }
  }
  .welcome-message, .download {
    flex: 1; 
    margin: 0;  
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

function AccountManagemet() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/account-info");
  };
  function goToContactUs() {
    navigate("/contact-us");
  }

  function goDownload() {
    window.open(
      "https://chromewebstore.google.com/detail/newhorizon-optimizer/icjbokeihkhdilfokigedgpoifnagkjo",
      "_blank"
    );
  }
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("No token found in localStorage.");
          return;
        }
        const response = await axios.get("/profile", {
          headers: {
            Authorization: `${token}`,
          },
        });

        console.log("Fetched user data:", response.data);
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <div>
      <Header />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="welcome-message">
          Welcome {user?.role} {user?.username}
        </div>
        <div className="download" onClick={goDownload}>
          Chrome插件下载
        </div>
      </div>

      <div className="AccountManagemet-container pb-[80px] min-h-[800px]">
        <div className="AccountManagemet-content">
          {/* 账户安全 */}
          <motion.div
            onClick={handleNavigate}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="AccountManagemet-card"
          >
            <h1 className="content-title">{t("AccountManagement:Sec")}</h1>

            <div className="grid-container gap-x-[10px]">
              <div>
                <p>
                  {t("AccountManagement:Un")}:
                  <br />
                  {user?.username}
                </p>
              </div>
              <div>
                <p>
                  {t("AccountManagement:Ue")}:<br />
                  {user?.email}
                </p>
              </div>
              <div>
                <p className="whitespace-nowrap">
                  {t("AccountManagement:Uv")}:<br />
                  {user?.role}
                </p>
              </div>
            </div>
          </motion.div>

          {/* 订单管理 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/order-details")}
            className="AccountManagemet-card"
          >
            <h1 className="content-title">{t("AccountManagement:Fin")}</h1>
            <p className="content-text">{t("AccountManagement:FinDesc")}</p>
          </motion.div>

          {/* 订阅管理 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/subscribe")} // 点击时导航到'/subscribe'
            className="AccountManagemet-card"
          >
            <h1 className="content-title">{t("AccountManagement:Sub")}</h1>
            <p className="content-text">{t("AccountManagement:SubDesc")}</p>
          </motion.div>

          {/* 通知设置 */}
          <motion.div
            onClick={() =>
              window.open("https://discord.gg/RvNCUFY4tz", "_blank")
            }
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="AccountManagemet-card"
          >
            <h1 className="content-title">{t("AccountManagement:Notif")}</h1>
            <p className="content-text">{t("AccountManagement:NotifDesc")}</p>
          </motion.div>

          {/* 支持和帮助 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="AccountManagemet-card"
            onClick={goToContactUs} // 添加点击事件处理器
          >
            <h1 className="content-title">{t("AccountManagement:Sup")}</h1>
            <p className="content-text">{t("AccountManagement:SupDesc")}</p>
          </motion.div>
        </div>
        <style>{AccountManagemetStyles}</style>
      </div>
      <Footer />
    </div>
  );
}

export default AccountManagemet;
