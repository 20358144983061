import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./common/Header";
import { motion, useAnimation } from "framer-motion";
import backgroundImage from "../assets/loginSignin.mp4";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import GoogleSignInButton from "./common/firebase.js";
import ConfettiPop from "./common/ConfettiPop";
const VideoWithSkeleton = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative lg:block hidden  m-[10px] w-1/2 max-w-1/2 h-[calc(100vh-80px)]">
      {loading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 animate-pulse bg-[#111]  rounded-[24px]"></div>
      )}
      <video
        className="lg:block hidden rounded-[24px] h-[calc(100vh-80px)] object-cover"
        autoPlay
        loop
        muted
        onLoadedMetadata={() => setLoading(false)}
        alt="NewHorizon Tech Background Video"
        title="NewHorizon Tech Background Video"
      >
        <source src={backgroundImage} type="video/mp4" />
      </video>
    </div>
  );
};

function Register({ onRegister }) {
  const { t } = useTranslation();
  const [registerInfo, setRegisterInfo] = useState({
    username: "",
    email: "",
    password: "",
  });
  // 添加新的状态变量
  const [canRegister, setCanRegister] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const triggerConfetti = () => {
    setShowConfetti(true);
  };
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    const updatedRegisterInfo = { ...registerInfo, [name]: value };
    setRegisterInfo(updatedRegisterInfo);

    const isFormValid =
      updatedRegisterInfo.username.trim() !== "" &&
      isEmailValid(updatedRegisterInfo.email) &&
      updatedRegisterInfo.password.trim() !== "" &&
      isVerified;

    setCanRegister(isFormValid);
  };
  const handleUsernameBlur = () => {
    if (registerInfo.username.trim() === "") {
      const message = isVerified
        ? "Please enter a username."
        : "Please enter a username.";
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const handlePasswordBlur = () => {
    if (registerInfo.password.trim() === "") {
      const message = isVerified
        ? "Please enter a password."
        : "Please enter a password.";
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  const handleEmailBlur = () => {
    // 仅在用户已验证或尚未尝试验证时检查邮箱格式
    if (isVerified || !attemptedRegister) {
      if (!isEmailValid(registerInfo.email)) {
        toast.error(
          "Invalid email format. Please enter a valid email address.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      }
    }
  };

  useEffect(() => {
    // 当输入或验证状态改变时，重新计算 canRegister
    const isFormValid =
      registerInfo.username.trim() !== "" &&
      isEmailValid(registerInfo.email) &&
      registerInfo.password.trim() !== "" &&
      isVerified;

    setCanRegister(isFormValid);
  }, [registerInfo, isVerified]); // 添加依赖项

  const isEmailValid = (email) => {
    // 使用正则表达式验证邮箱格式
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const [attemptedRegister, setAttemptedRegister] = useState(false);
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    if (!isVerified || !canRegister) {
      // 验证是否为人类
      toast.error(
        "Please verify that you are not a robot before registering.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
      return;
    }

    if (!isEmailValid(registerInfo.email)) {
      // 如果邮箱格式无效，显示错误消息
      toast.error("Invalid email format. Please enter a valid email address.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/register",
        registerInfo
      );
      console.log(response.data);

      toast.success(
        "Registration successful! Please check your email to verify.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );

      // 注册成功后执行其他操作
    } catch (error) {
      console.error("注册时出错:", error);

      if (error.response) {
        if (error.response.status === 400) {
          toast.warning("Username already exists", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else if (error.response.status === 401) {
          toast.error("Email already exists", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error("An error occurred during registration", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    }

    setAttemptedRegister(true);

    // 在请求结束后重置 canRegister
    setCanRegister(false);
  };

  const [buttonPressed, setButtonPressed] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleButtonPress = () => {
    // 检查用户名是否为空
    if (registerInfo.username.trim() === "") {
      toast.error("Username cannot be empty.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return; // 阻止进一步执行
    }
    // 检查电子邮件格式
    if (!isEmailValid(registerInfo.email)) {
      toast.error("Invalid email format. Please enter a valid email address.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return; // 阻止进一步执行
    }
    // 检查密码是否为空
    if (registerInfo.password.trim() === "") {
      toast.error("Password cannot be empty.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return; // 阻止进一步执行
    }

    // 如果所有检查都通过，继续执行 as human 验证流程
    setButtonPressed(true);

    const newTimer = setTimeout(() => {
      setIsVerified(true);
    }, 500);

    setTimer(newTimer);
  };

  const handleButtonRelease = () => {
    // 如果按钮未被按下（即验证流程未开始），则不执行任何操作
    if (!buttonPressed) return;

    // 用户放开按钮
    setButtonPressed(false);
    clearTimeout(timer); // 清除定时器防止设置状态

    if (!isVerified) {
      // 如果定时器还没结束就放开了按钮，则不视为通过验证
      toast.info("Hold the button for at least 0.5 second to verify.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const verifyButtonVariants = {
    rest: { scale: 1 },
    hover: {
      scale: 1.02,
      backgroundColor: isVerified ? "#2962ff" : "#fff",
      color: isVerified ? "#fff" : "#2962ff",
    },
    press: {
      scale: 0.98,
      backgroundColor: isVerified ? "#2962ff" : "#fff",
      color: isVerified ? "#fff" : "#2962ff",
    },
  };

  const ProgressBar = ({ isHeld, setIsVerified }) => {
    const controls = useAnimation();

    useEffect(() => {
      if (isHeld) {
        controls.start({ width: "100%", transition: { duration: 0.5 } });
        // 设置定时器，在0.5秒后设置验证状态为true并隐藏进度条
        const timeout = setTimeout(() => {
          setIsVerified(true);
          controls.start({ width: "0%", transition: { duration: 0.5 } }); // 添加此行以隐藏进度条
        }, 500);
        return () => clearTimeout(timeout);
      } else {
        // 确保在组件挂载后再执行这一行
        controls.start({ width: "0%" });
      }
    }, [isHeld, setIsVerified, controls]);

    // 仅在未验证时显示进度条
    return isVerified ? null : (
      <motion.div
        initial={{ width: "0%" }}
        animate={controls}
        style={{
          background: "#2962ff",
          height: "5px",
          borderRadius: "5px",
        }}
      />
    );
  };

  const buttonStyle = {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid transparent",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#2962ff",
    color: "rgba(255, 255, 255, 1)",
    cursor: "pointer",
  };

  const verificationButtonStyle = {
    ...buttonStyle, // 你之前定义的按钮样式
    backgroundColor: isVerified ? "#2962ff" : "grey",
    color: "rgba(255, 255, 255, 1)",
    opacity: buttonPressed ? 1 : 1, // 如果按钮被按下，降低透明度
  };

  const verificationButtonStyleFin = {
    ...buttonStyle, // 你之前定义的按钮样式
    backgroundColor: isVerified ? "#2962ff" : "grey",
    color: "rgba(255, 255, 255, 1)",
    opacity: buttonPressed ? 1 : 1,
  };

  // Define your button styles with a conditional background color
  const submitButtonStyle = {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid transparent",
    width: "100%",
    boxSizing: "border-box",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: isVerified && canRegister ? "#2962ff" : "grey",
    cursor: isVerified && canRegister ? "pointer" : "default",
    opacity: isVerified && canRegister ? 1 : 0.6,
  };

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  const handleResendEmail = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/resend-verification-email",
        { email: registerInfo.email }
      );
      // 在控制台输出响应内容
      console.log(response);

      toast.success("Verification email sent again. Please check your inbox.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error resending email:", error);
      toast.error("Failed to resend verification email.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    setCountdown(30);
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>User Registration - 用户注册 | New Horizon</title>
          <meta
            name="description"
            content="加入新长慧和科技，体验定制化服务。Join  New Horizon site and experience customized services."
          />
          <meta
            name="keywords"
            content="注册, 用户, 订阅, Register, User, Subscription, New Horizon"
          />
          <link rel="canonical" href="https://nhwt.tech/register" />
        </Helmet>

        <Header />
        <ConfettiPop shouldPop={showConfetti} />
        <div className="flex overflow-hidden h-screen">
          <VideoWithSkeleton />
          <div className="flex flex-col items-center justify-center p-5 w-[400px] max-w-[80%] mx-auto h-screen">
            <ToastContainer />
            <div className="w-full relative sm:top-[-90px] md:top-[-90px] lg:top-[-80px]">
              <h1 className="text-center text-f">{t("Register:register")}</h1>
              <form
                onSubmit={handleRegisterSubmit}
                className="flex flex-col w-full"
              >
                <input
                  type="text"
                  name="username"
                  value={registerInfo.username}
                  onChange={handleRegisterChange}
                  onBlur={handleUsernameBlur} // 添加这一行
                  placeholder={t("Register:username")}
                  required
                  className="my-[10px] p-[10px] rounded-[4px] border border-c w-full bg-[rgba(255,255,255,0.1)] text-[#ffffff]"
                />
                <input
                  type="email" // Add this attribute for email format validation
                  name="email"
                  value={registerInfo.email}
                  onChange={handleRegisterChange}
                  onBlur={handleEmailBlur} // 添加这一行
                  placeholder={t("Register:email")}
                  required
                  className="my-[10px] p-[10px] rounded-[4px] border border-c w-full bg-[rgba(255,255,255,0.1)] text-f"
                />
                <input
                  type="password"
                  name="password"
                  value={registerInfo.password}
                  onChange={handleRegisterChange}
                  onBlur={handlePasswordBlur} // 添加这一行
                  placeholder={t("Register:password")}
                  required
                  className="my-[10px] p-[10px] rounded-[4px] border border-c w-full bg-[rgba(255,255,255,0.1)] text-f"
                />
                <motion.button
                  type="button"
                  initial="rest"
                  whileHover="hover"
                  whileTap="press"
                  variants={verifyButtonVariants}
                  onMouseDown={handleButtonPress}
                  onMouseUp={handleButtonRelease}
                  onTouchStart={handleButtonPress}
                  onTouchEnd={handleButtonRelease}
                  disabled={isVerified}
                  style={
                    isVerified
                      ? verificationButtonStyle
                      : verificationButtonStyleFin
                  }
                >
                  {isVerified
                    ? t("Register:verifiedHuman")
                    : t("Register:verifyHuman")}
                </motion.button>
                {/* 添加isVerified传递给ProgressBar以控制其显示 */}
                <ProgressBar
                  isHeld={buttonPressed}
                  setIsVerified={setIsVerified}
                  isVerified={isVerified}
                />

                <button
                  type="submit"
                  style={submitButtonStyle}
                  onClick={handleRegisterSubmit}
                >
                  {t("Register:register")}
                </button>
              </form>

              {attemptedRegister &&
                (countdown > 0 ? (
                  <p className="text-center text-[#cccccc] mb-2">
                    Please wait {countdown} seconds
                  </p>
                ) : (
                  <p className="text-center text-white">
                    <p
                      onClick={handleResendEmail}
                      className="cursor-pointer text-blue-500 underline mb-2"
                    >
                      Resend Verification Email
                    </p>
                  </p>
                ))}

              <p className="text-center text-white mt-1 mb-1">
                {t("Register:alreadyHaveAccount")}&nbsp;
                <Link to="/login" className="text-mblue underline">
                  {t("Register:login")}
                </Link>
              </p>
              <div className="flex items-center justify-center">
                <div className="flex-grow border-t border-gray-500"></div>
                <span className="flex-shrink mx-4 text-gray-500 text-lg">
                  or
                </span>
                <div className="flex-grow border-t border-gray-500"></div>
              </div>

              <GoogleSignInButton onGoogleLoginSuccess={triggerConfetti} />
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Register;
