import React, { useState } from "react";
import Header from "./common/Header";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import axios from "axios"; // 导入 axios
import { toast, ToastContainer } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "./common/Footer";
import "react-toastify/dist/ReactToastify.css";

const ContactUsStyles = `
  /* Style Enhancements: Consistent with Home.js */
  .ContactUs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #000; /* Dark background */
    color: #d1d1d1; /* Soft white text */
    font-family: 'Bloomberg Terminal', sans-serif;
    padding: 20px;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100vh; /* Full viewport height */
  }

  .contact-form {
    width: 80%;
    max-width: 600px;
    background: #111; /* Card-like background */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin: 20px 0;
  }

  .form-field {
    margin-bottom: 20px;
    width: 100%;
  }

  .input-field {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #434651;
    background-color: #222;
    color: white;
    font-size: 16px;
  }

  .submit-button {
    background-color: #2962ff; /* Bloomberg-like highlight color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    width: 100%;
  }

  .input-field, .submit-button {
    box-sizing: border-box;
    /* other styles... */
  }

  label {
    display: block;
    margin-bottom: 10px;
    /* other styles... */
  }
  #phone-country-code {
    width: 30%; /* 设置为小于 #phone-number 的宽度 */
  }
  
  #phone-number {
    width: 70%; /* 设置为大于 #phone-country-code 的宽度 */
  }
  .delete-button-container {
    display: inline-block;
    margin-left: 10px;
  }
  
  .delete-button {
    background-color: #2962ff; /* 与提交按钮相同的背景色 */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* 根据需要调整字体大小 */
  }
  
  .delete-button:hover {
    background-color:rgb(242, 54, 69);  /* 鼠标悬停时的背景色 */
  }
  
`;

const ContactUs = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState({ type: "other", custom: "" });
  const [attachment, setAttachment] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isExceeded, setIsExceeded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      // 根据subject.type的值确定实际的主题
      const actualSubject =
        subject.type === "custom" ? subject.custom : subject.type;
      formData.append("subject", actualSubject);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("countryCode", countryCode);
      formData.append("phoneNumber", phoneNumber);
      formData.append("message", message);
      if (attachment) {
        formData.append("attachment", attachment);
      }

      const response = await axios.post("/sendEmail", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Server response:", response.data);
      toast.success(
        "Email sent successfully! \n We will respond to you within two business days.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          style: {
            whiteSpace: "pre-line",
          },
        }
      );

      // 清空表单字段
      setSubject({ type: "other", custom: "" });
      setName("");
      setEmail("");
      setCountryCode("");
      setPhoneNumber("");
      setMessage("");
      setAttachment(null);
      setUploadedFiles([]);
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email. Please try again later.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.size > 100 * 1024 * 1024) {
        // 如果文件超过100MB，在控制台中输出错误消息
        console.log("文件大小超过限制（100MB）");
        // 显示错误消息给用户
        toast.error("File size must not exceed the limit (100MB)", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // 3秒后自动关闭
        });
        // toast.error('File size exceeds the limit (100MB)');
        return; // 不执行后续操作，直接退出函数
      }
      // 文件大小在限制范围内，可以继续处理
      console.log("Accepted files:", acceptedFiles); // 检查接受的文件是否正确
      setUploadedFiles([...uploadedFiles, file]);
      setAttachment(file); // 将文件保存到状态中
      toast.error(null); // 清除错误消息
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop, // 或者简写为 onDrop，确保函数名正确
    accept: "*/*", // 可以上传图像和PDF文件
    //    maxSize: 100 * 1024 * 1024, // 100MB，以字节为单位
  });

  const handleDeleteFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const handleChange = (e) => {
    const isLimitExceeded = e.target.value.length > 8000;
    setIsExceeded(isLimitExceeded);
    setMessage(e.target.value);
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Contact Us - 联系我们 | New Horizon</title>
          <meta
            name="description"
            content="联系New Horizon获取更多信息或提交您的请求。Contact New Horizon for more information or to submit your inquiries."
          />
          <meta
            name="keywords"
            content="联系, 查询, 客户服务, Contact, Inquiry, Customer Service, New Horizon"
          />
          <link rel="canonical" href="https://nhwt.tech/contact-us" />
        </Helmet>
        <Header />
        <ToastContainer /> {/* 添加Toast通知容器 */}
        <div className="ContactUs-container relative sm:top-2 lg:top-0  mb-10 !h-[100%] ">
          <h1>{t("ContactUs:contact_us")}</h1>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-field">
              <label className="block mb-2">
                {t("ContactUs:subject")} <span className="text-white">*</span>
              </label>
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="subject"
                    value="custom"
                    checked={subject.type === "custom"}
                    onChange={(e) =>
                      setSubject({ ...subject, type: e.target.value })
                    }
                    className="mr-2"
                  />
                  {t("ContactUs:custom")}
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="subject"
                    value="subscription_plan"
                    checked={subject.type === "subscription_plan"}
                    onChange={(e) =>
                      setSubject({ ...subject, type: e.target.value })
                    }
                    className="mr-2"
                  />
                  {t("ContactUs:subscription_plan")}
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="subject"
                    value="bug_report"
                    checked={subject.type === "bug_report"}
                    onChange={(e) =>
                      setSubject({ ...subject, type: e.target.value })
                    }
                    className="mr-2"
                  />
                  {t("ContactUs:bug_report")}
                </label>
              </div>
              {subject.type === "custom" && (
                <input
                  type="text"
                  className="input-field mt-2"
                  value={subject.custom}
                  onChange={(e) =>
                    setSubject({ ...subject, custom: e.target.value })
                  }
                  placeholder={t("ContactUs:custom_subject")}
                  required
                />
              )}
            </div>

            <div className="form-field">
              <label htmlFor="name">
                {t("ContactUs:name")} <span style={{ color: "white" }}>*</span>
              </label>
              <input
                type="text"
                id="name"
                className="input-field"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-field">
              <label htmlFor="email">
                {t("ContactUs:email")} <span style={{ color: "white" }}>*</span>
              </label>
              <input
                type="email"
                id="email"
                className="input-field"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-field">
              <label htmlFor="phone">{t("ContactUs:phone")}</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Input for entering the country code */}
                <input
                  type="text"
                  id="phone-country-code"
                  className="input-field"
                  style={{ marginRight: "10px" }}
                  placeholder={t("ContactUs:country_code")}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                />
                {/* Input for entering the phone number */}
                <input
                  type="text"
                  id="phone-number"
                  className="input-field"
                  placeholder={t("ContactUs:phone_number")}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="form-field">
              <label htmlFor="message">
                {t("ContactUs:Message")}{" "}
                <span style={{ color: "white" }}>*</span>
              </label>
              <div className="relative w-full h-full">
                <textarea
                  id="message"
                  className="input-field h-40"
                  value={message}
                  maxLength="8000"
                  onChange={handleChange}
                ></textarea>
                <span
                  id="remaining-chars"
                  className={`absolute bottom-2 right-2 text-sm ${
                    isExceeded ? "text-red-500" : "text-gray-500"
                  }`}
                >
                  {message.length} / 8000
                </span>

                <ToastContainer />
              </div>
            </div>
            <div className="form-field">
              <label htmlFor="attachment" className="file-input-label">
                {t("ContactUs:upload_file")}
              </label>
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #434651",
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <p style={{ color: "white" }}>
                  {t("ContactUs:drag_and_drop_file")}
                </p>
              </div>
            </div>
            {uploadedFiles.length > 0 && (
              <div>
                <h3>{t("ContactUs:uploaded_files")}</h3>
                <ul>
                  {uploadedFiles.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <div className="delete-button-container">
                        <div
                          className="delete-button"
                          onClick={() => handleDeleteFile(index)}
                        >
                          {t("ContactUs:delete")}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
              className="submit-button"
              type="submit"
            >
              {t("ContactUs:submit")}
            </motion.button>
          </form>
        </div>
        <style>{ContactUsStyles}</style>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ContactUs;
