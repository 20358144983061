// dashbroad:dashboard.js

/* Dashboard 页面通常是用户获取信息和执行任务的中心。因此，它应该包含以下模块：

1. **概览/总结** - 显示市场总览、重要新闻摘要、市场表现的图表和图形等。
2. **账户信息与设置** - 用户的账户概况、余额、持仓和最近活动的简要信息。
3. **快捷操作** - 执行常用任务和快速访问常用功能（如交易、存取款、历史记录等）的快捷链接。
4. **市场动态** - 实时显示市场数据、股票指数、价格变动、交易量等。
5. **警报和通知** - 重要财经事件、价格警报、性能提醒等个性化通知。
6. **个人化模块** - 用户可以自定义并添加其它感兴趣的内容或数据视图。
7. **教育内容** - 最新的金融知识、市场分析教程、投资策略等教育资源链接。

这个页面的设计应考虑到清晰易用，允许用户快速获取他们最关心的数据和执行最常用的任务。 */
import React from "react";
import Header from "./common/Header";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const DashboardStyles = `
  /* 样式优化: 使用现代化UI元素 */
  
  /* Style Enhancements: Inspired by Bloomberg Terminal UI Elements */
  .Dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Bloomberg-style dark background */
    color: #d1d1d1; /* Soft white text for better readability */
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Bloomberg Terminal font or similar */
  }
  
  .Dashboard-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default layout for wider screens */
    gap: 20px;
    /* Other styles... */
  }

  /* Media query for screens with a max-width of 768px */
  @media screen and (max-width: 768px) {
    .Dashboard-content {
      grid-template-columns: repeat(1, 1fr); /* Single column layout for narrower screens */
    }
  }

  .Dashboard-card {
    background: #111; /* Card background color similar to Bloomberg's palette */
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px; /* Rounded borders for cards */
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadows for depth */
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; /* Title font size */
    color: #2962ff; /* Bloomberg-like highlight color */
    margin-bottom: 15px; /* Space below title */
  }
  
  .content-text {
    font-size: 16px; /* Text font size */
    margin-bottom: 10px; /* Space below text */
    line-height: 1.6; /* Line height for readability */
  }
`;

function Dashboard() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="Dashboard-container">
        <div className="Dashboard-content">
          {/* 概览/总结 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="Dashboard-card"
          >
            <h1 className="content-title">
              {t("dashbroad:dashboard.marketSummary")}
            </h1>
            <p className="content-text">
              {t("dashbroad:dashboard.marketSummaryDescription")}
            </p>
          </motion.div>

          {/* 账户信息与设置 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="Dashboard-card"
          >
            <h1 className="content-title">
              {t("dashbroad:dashboard.accountInfo")}
            </h1>
            <p className="content-text">
              {t("dashbroad:dashboard.accountInfoDescription")}
            </p>
          </motion.div>

          {/* 快捷操作 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="Dashboard-card"
          >
            <h1 className="content-title">
              {t("dashbroad:dashboard.quickActions")}
            </h1>
            <p className="content-text">
              {t("dashbroad:dashboard.quickActionsDescription")}
            </p>
          </motion.div>

          {/* 市场动态 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="Dashboard-card"
          >
            <h1 className="content-title">
              {t("dashbroad:dashboard.marketDynamics")}
            </h1>
            <p className="content-text">
              {t("dashbroad:dashboard.marketDynamicsDescription")}
            </p>
          </motion.div>

          {/* 警报和通知 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="Dashboard-card"
          >
            <h1 className="content-title">
              {t("dashbroad:dashboard.alertsAndNotifications")}
            </h1>
            <p className="content-text">
              {t("dashbroad:dashboard.alertsAndNotificationsDescription")}
            </p>
          </motion.div>

          {/* 个人化模块 */}
          {/*             <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }} 
            className="Dashboard-card"
          >
            <h1 className="content-title">{t('dashbroad:dashboard.personalizedModules')}</h1>
            <p className="content-text">
              {t('dashbroad:dashboard.personalizedModulesDescription')}
            </p>
          </motion.div> */}

          {/* 教育内容 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="Dashboard-card"
          >
            <h1 className="content-title">
              {t("dashbroad:dashboard.educationalContent")}
            </h1>
            <p className="content-text">
              {t("dashbroad:dashboard.educationalContentDescription")}
            </p>
          </motion.div>
        </div>
        <style>{DashboardStyles}</style>
      </div>
    </div>
  );
}

export default Dashboard;
