// ForgotPassword.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./common/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [countdown, setCountdown] = useState(0); // 新增倒计时状态
  const [isDisabled, setIsDisabled] = useState(false); // 新增按钮禁用状态
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      setIsDisabled(false); // 倒计时结束，启用按钮
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/forgot-password",
        { email }
      );
      console.log(response);
      toast.success("Password reset link sent! Check your email.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setCountdown(30); // 设置倒计时为30秒
      setIsDisabled(true); // 禁用按钮
    } catch (error) {
      toast.error("Failed to send password reset link. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      console.error("Password reset error:", error);
    }
  };
  const inputStyle = {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "white",
  };

  const buttonStyle = {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "none",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#2962ff",
    color: "white",
    cursor: "pointer",
  };

  const formContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
    width: "400px",
    maxWidth: "80%",
    margin: "0 auto",
    height: "100vh",
  };

  // 根据按钮是否禁用动态设置样式
  const dynamicButtonStyle = {
    ...buttonStyle,
    backgroundColor: isDisabled ? "#808080" : "#2962ff",
    cursor: isDisabled ? "default" : "pointer",
    opacity: isDisabled ? 0.6 : 1,
  };

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div style={formContainerStyle}>
        <ToastContainer />
        <div className="w-full relative sm:top-[-90px] md:top-[-100px]  lg:top-[-90px]">
          <h1 className="text-center" style={{ color: "white" }}>
            Reset Password
          </h1>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              style={inputStyle}
            />
            <button
              type="submit"
              style={dynamicButtonStyle}
              disabled={isDisabled}
            >
              Send Reset Link
            </button>
            {countdown > 0 && (
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Please wait {countdown} seconds before resending.
              </div>
            )}
          </form>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default ForgotPassword;
