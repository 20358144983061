// MarketDataFundInfo.js
/* "Market Data & Fund Info" 页面应该包含以下模块：

1. **市场快照**：显示最新的股票市场数据、指数、价格走势图。
2. **AI基金概览**：概述自家发行基金的性能指标，如净值、年化收益率、基金规模等。
3. **实时报价**：提供股票、债券、外汇等的实时报价信息。
4. **基金历史表现**：图表和统计数据展示基金的历史业绩。
5. **资产配置**：分析基金资产的分布和配置。
6. **投资策略和管理团队介绍**：详细介绍基金的投资策略和管理团队背景。
7. **投资者资源**：提供基金的相关报告、公告和研究材料的下载链接。
8. **交互式工具**：如费用计算器、模拟投资组合构建工具等。
9. **用户定制模块**：允许用户根据偏好定制他们感兴趣的数据和报告。

这个页面应该设计得直观易用，以便用户可以轻松获取和分析他们需要的信息。 */
import React from "react";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next"; // 导入useTranslation

const MarketDataFundInfoStyles = `
  /* 样式优化: 使用现代化UI元素 */
  
  /* Style Enhancements: Inspired by Bloomberg Terminal UI Elements */
  .MarketDataFundInfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Bloomberg-style dark background */
    color: #d1d1d1; /* Soft white text for better readability */
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Bloomberg Terminal font or similar */
  }
  

  .MarketDataFundInfo-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default layout for wider screens */
    gap: 20px;
    /* Other styles... */
  }

  /* Media query for screens with a max-width of 768px */
  @media screen and (max-width: 768px) {
    .MarketDataFundInfo-content {
      grid-template-columns: repeat(1, 1fr); /* Single column layout for narrower screens */
    }
  }

  
  .MarketDataFundInfo-card { 
   background: #111; /* Card background color similar to Bloomberg's palette */
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px; /* Rounded borders for cards */
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadows for depth */
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; /* Title font size */
    color: #2962ff; /* Bloomberg-like highlight color */
    margin-bottom: 15px; /* Space below title */
  }
  
  .content-text {
    font-size: 16px; /* Text font size */
    margin-bottom: 10px; /* Space below text */
    line-height: 1.6; /* Line height for readability */
  }
  
`;

function MarketDataFundInfo() {
  const { t } = useTranslation(); // 初始化useTranslation钩子

  let navigate = useNavigate();

  function goToChart() {
    navigate("/fund");
  }

  return (
    <div>
      <Header />
      <div className="MarketDataFundInfo-container">
        <div className="MarketDataFundInfo-content">
          {/* 市场快照模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:marketSnapshotTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:marketSnapshotText")}
            </p>
          </motion.div>

          {/* AI基金概览模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
            onClick={goToChart}
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:fundOverviewTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:fundOverviewText")}
            </p>
          </motion.div>

          {/* 实时报价模块 */}
          {/*           <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }} 
            className="MarketDataFundInfo-card">
            <h1 className="content-title">{t('MarketDataFundInfo:liveQuotesTitle')}</h1>
            <p className="content-text">{t('MarketDataFundInfo:liveQuotesText')}</p>
          </motion.div>
 */}
          {/* 基金历史表现模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:fundPerformanceTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:fundPerformanceText")}
            </p>
          </motion.div>

          {/* 资产配置模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:assetAllocationTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:assetAllocationText")}
            </p>
          </motion.div>

          {/* 投资策略与管理团队模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:investmentStrategyTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:investmentStrategyText")}
            </p>
          </motion.div>

          {/* 投资者资源模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:investorResourcesTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:investorResourcesText")}
            </p>
          </motion.div>

          {/* 交互式工具模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="MarketDataFundInfo-card"
          >
            <h1 className="content-title">
              {t("MarketDataFundInfo:interactiveToolsTitle")}
            </h1>
            <p className="content-text">
              {t("MarketDataFundInfo:interactiveToolsText")}
            </p>
          </motion.div>

          {/* 用户定制模块 */}
          {/*           <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }} 
            className="MarketDataFundInfo-card">
            <h1 className="content-title">{t('MarketDataFundInfo:customPreferencesTitle')}</h1>
            <p className="content-text">{t('MarketDataFundInfo:customPreferencesText')}</p>
          </motion.div> */}

          {/* 可以继续添加其他卡片布局的模块 */}
        </div>
        <style>{MarketDataFundInfoStyles}</style>
      </div>
    </div>
  );
}

export default MarketDataFundInfo;
