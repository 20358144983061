import React from "react";

const TradingDashboardDescription = ({ i18n }) => (
  <div>
    {i18n.language === "en" && (
      <div className="factor_info_en">
        <p>
          <strong>Trading Dashboard</strong>
        </p>

        <p>
          This Trading Dashboard is provides a visual representation of various
          technical indicators and economic data to assist traders in making
          informed decisions.
        </p>

        <p>
          <strong>Features</strong>
        </p>

        <ul>
          <li>
            Kumo Cloud Status: Displays the status of the Kumo Cloud for
            different timeframes (15-minute, 1-hour, 4-hour, and daily). The
            cloud status is indicated as "Up" (green) or "Down" (red) based on
            the position of the Lead Span A relative to Lead Span B.
          </li>
          <li>
            Economic Indicators: Shows the current values of key economic
            indicators such as the US GDP Year-over-Year (YoY), US Services
            Purchasing Managers' Index (PMI), US Inflation YoY, and the Federal
            Reserve interest rate. The background color of each cell indicates
            whether the value is considered positive (green) or negative (red)
            based on predefined conditions.
          </li>
        </ul>

        <p>
          <strong>Usage</strong>
        </p>

        <p>To use this dashboard:</p>

        <ol>
          <li>Open your TradingView chart.</li>
          <li>Click on "Pine Editor" at the bottom of the screen.</li>
          <li>
            Copy and paste the provided Pine Script code into the Pine Editor.
          </li>
          <li>
            Click on "Add to Chart" to display the dashboard on your chart.
          </li>
        </ol>

        <p>
          <strong>Customization</strong>
        </p>

        <p>
          You can customize the dashboard by modifying the Pine Script code. For
          example, you can change the timeframes for the Kumo Cloud status or
          add new economic indicators to the table.
        </p>

        <p>
          <strong>Disclaimer</strong>
        </p>

        <p>
          This dashboard is provided for informational purposes only and does
          not constitute financial advice. Please conduct your own research and
          consult with a financial advisor before making any trading decisions.
        </p>
      </div>
    )}
    {i18n.language === "zh" && (
      <div className="factor_info_zh">
        <p>
          <strong>交易仪表盘</strong>
        </p>

        <p>
          这个交易仪表盘它提供了各种技术指标和经济数据的视觉表示，以帮助交易者做出明智的决策。
        </p>

        <p>
          <strong>特点</strong>
        </p>

        <ul>
          <li>
            云状态：显示不同时间框架（15分钟、1小时、4小时和每日）的云状态。云状态根据领先跨度
            A 相对于领先跨度 B 的位置显示为“上升”（绿色）或“下降”（红色）。
          </li>
          <li>
            经济指标：显示关键经济指标的当前值，如美国国内生产总值年增长率（YoY）、美国服务业采购经理人指数（PMI）、美国通胀率年增长率和联邦储备利率。每个单元格的背景颜色表明该值是被视为正面（绿色）还是负面（红色），这是基于预定义的条件。
          </li>
        </ul>

        <p>
          <strong>使用方法</strong>
        </p>

        <p>要使用这个仪表盘：</p>

        <ol>
          <li>打开您的 TradingView 图表。</li>
          <li>点击屏幕底部的“Pine 编辑器”。</li>
          <li>将提供的 Pine 脚本代码复制并粘贴到 Pine 编辑器中。</li>
          <li>点击“添加到图表”以在您的图表上显示仪表盘。</li>
        </ol>

        <p>
          <strong>定制</strong>
        </p>

        <p>
          您可以通过修改 Pine
          脚本代码来定制仪表盘。例如，您可以更改云状态的时间框架或在表格中添加新的经济指标。
        </p>

        <p>
          <strong>免责声明</strong>
        </p>

        <p>
          此仪表盘仅供参考，不构成财务建议。请进行自己的研究，并在做出任何交易决定之前咨询财务顾问。
        </p>
      </div>
    )}
  </div>
);

export const TradingDashboard = {
  id: 3,
  nameKey: "factorLibrary:factorLibrary.TradingDashboardTitle",
  descriptionComponent: TradingDashboardDescription,
  factorUrls: [
    "https://www.tradingview.com/script/QIeu8xNT-Dashboard-NewHorizon/",
  ],
  imageName: "TradingDashboard",
};
