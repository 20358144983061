import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./common/Header";
import { MdCheckCircle } from "react-icons/md";
import { useTranslation } from "react-i18next";

const PaymentSuccessPage = () => {
  const { t } = useTranslation();

  const [orderDetails, setOrderDetails] = useState({
    orderNumber: "",
    subscriptionType: "",
    amount: "",
    subscriptionPeriod: "",
    vipExpirationDate: "", // 添加会员到期日期字段
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/order-details", {
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log("Order Details:", response.data); // 在这里添加日志
        //   setOrderDetails(response.data);

        // 获取第一个订单的信息
        // 获取最后一个订单的信息
        const lastOrderIndex = response.data.length - 1;
        const selectedOrder = response.data[lastOrderIndex];

        setOrderDetails({
          orderNumber: selectedOrder.orderNumber,
          subscriptionType: selectedOrder.subscriptionType,
          amount: selectedOrder.amount,
          subscriptionPeriod: selectedOrder.subscriptionPeriod,
          vipExpirationDate: selectedOrder.vipExpirationDate, // 添加会员到期日期字段
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    // 检查日期是否有效
    if (isNaN(date.getTime())) {
      return "-";
    }
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const {
    orderNumber,
    subscriptionType,
    amount,
    subscriptionPeriod,
    vipExpirationDate,
  } = orderDetails;

  return (
    <div>
      <Header />
      <div className="min-h-[800px] bg-[#111] flex flex-col items-center justify-center">
        <div className="p-6 max-w-md w-full bg-[#111] rounded-lg border border-[#434651] drop-shadow-md">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="text-2xl text-gray-300">
                {t("PaymentSuccess:loading")}
              </div>
            </div>
          ) : (
            <>
              <h1 className="text-2xl font-bold mb-2 text-green-500 flex items-center">
                <MdCheckCircle className="mr-2" />
                {t("PaymentSuccess:successTitle")}
              </h1>
              <p className="mb-4 text-gray-300">
                {t("PaymentSuccess:successMessage")}
              </p>
              <div className="border-t border-[#434651] pt-4">
                <h3 className="text-lg font-semibold mb-2 text-gray-300">
                  {t("PaymentSuccess:orderDetailsTitle")}
                </h3>
                <p className="text-gray-300">
                  <strong className="mr-2">
                    {t("PaymentSuccess:orderNumber")}
                  </strong>
                  {orderNumber}
                </p>
                <p className="text-gray-300">
                  <strong className="mr-2">
                    {t("PaymentSuccess:subscriptionType")}
                  </strong>
                  {subscriptionType}
                </p>
                <p className="text-gray-300">
                  <strong className="mr-2">
                    {t("PaymentSuccess:paymentAmount")}
                  </strong>
                  ${amount}
                </p>
                <p className="text-gray-300">
                  <strong className="mr-2">
                    {t("PaymentSuccess:subscriptionTime")}
                  </strong>
                  {formatDate(subscriptionPeriod)}
                </p>
                {/* 显示会员到期日期 */}
                <p className="text-mblue font-bold ">
                  <strong className="mr-2">
                    {t("PaymentSuccess:vipExpirationDate")}
                  </strong>
                  {formatDate(vipExpirationDate)}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
