import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

function SetPasswordModal({ isHomePage, onClose }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetInfo, setResetInfo] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSetChange = (e) => {
    const { name, value } = e.target;
    setResetInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();

    if (resetInfo.newPassword !== resetInfo.confirmPassword) {
      toast.error(
        "Passwords do not match. Please make sure the passwords match.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found in localStorage.");
        return;
      }

      const response = await axios.post(
        "/set-password",
        {
          newPassword: resetInfo.newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      console.log("Password set response data:", response.data);

      toast.success("Password set successful!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      if (isHomePage) {
        onClose(); // 调用传入的 onClose 函数关闭模态窗口
      }
    } catch (error) {
      console.error("Password set error:", error);

      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Password set failed. Unauthorized.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else if (error.response.status === 422) {
          toast.warning("Password set failed. Validation error.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        }
      }
    }
  };

  return (
    <div className="modal-container">
      <ToastContainer />
      <h1 className="text-white text-center whitespace-pre-line">
        {isHomePage
          ? "Welcome!\nYou can set a new password for New Horizon site."
          : "Set Password"}
      </h1>
      <form className="w-[360px]" onSubmit={handleResetSubmit}>
        <div className="relative mt-2.5 mb-2.5">
          <input
            type={showNewPassword ? "text" : "password"}
            name="newPassword"
            value={resetInfo.newPassword}
            onChange={handleSetChange}
            placeholder="Set Password"
            required
            className="w-full p-2.5 border border-gray-300 rounded bg-[rgba(255,255,255,0.1)] text-gray-400"
          />
          <span
            onClick={() => setShowNewPassword(!showNewPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-gray-400"
          >
            {showNewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </span>
        </div>

        <div className="relative mt-2.5 mb-2.5">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={resetInfo.confirmPassword}
            onChange={handleSetChange}
            placeholder="Confirm Password"
            required
            className="w-full p-2.5 border border-gray-300 rounded bg-[rgba(255,255,255,0.1)] text-gray-400"
          />
          <span
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-gray-400"
          >
            {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </span>
        </div>

        <button
          type="submit"
          className="w-full p-2.5 mt-2.5 mb-2.5 bg-blue-600 text-white rounded cursor-pointer"
        >
          Set Password
        </button>
      </form>
    </div>
  );
}

export default SetPasswordModal;
