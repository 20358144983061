import React, { useState } from "react";
import Header from "./common/Header";
import SetPasswordModal from "./common/SetPasswordModal";

function SetPasswordForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-[800px]">
        <SetPasswordModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
}

export default SetPasswordForm;
