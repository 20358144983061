import React from "react";
import { useTranslation } from "react-i18next";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  const { t } = useTranslation();

  const contentStyles = {
    backgroundColor: "#000", // Dark background
    color: "#ffffff", // White text for dark background
    padding: "40px 20px", // Padding for content
    lineHeight: "1.8", // Line height for readability
    fontSize: "16px", // Font size for readability
    maxWidth: "800px", // Max width of the content
    margin: "0 auto", // Centering the content
  };

  return (
    <div>
      <Helmet>
        <title>Terms of Service - 服务条款 | New Horizon</title>
        <meta
          name="description"
          content="阅读新长慧和的服务条款，了解您使用我们服务的法律条款和条件。Read the terms of service of New Horizon to understand the legal terms and conditions of using our services."
        />
        <meta
          name="keywords"
          content="服务条款, 法律, 条件, Terms of Service, Legal, Conditions, New Horizon"
        />
        <link rel="canonical" href="https://nhwt.tech/terms-of-service" />
      </Helmet>
      <Header />
      <div style={contentStyles} className="pb-[80px]">
        <h1>{t("TermsOfService:Header")}</h1>
        <p>{t("TermsOfService:Introduction")}</p>
        <br />
        <h2>{t("TermsOfService:Acceptance")}</h2>
        <p>{t("TermsOfService:AcceptanceDetails")}</p>
        <br />
        <h2>{t("TermsOfService:ServiceUse")}</h2>
        <p>{t("TermsOfService:ServiceUseDetails")}</p>
        <br />
        <h2>{t("TermsOfService:UserResponsibility")}</h2>
        <p>{t("TermsOfService:UserResponsibilityDetails")}</p>
        <br />
        <h2>{t("TermsOfService:IntellectualProperty")}</h2>
        <p>{t("TermsOfService:IntellectualPropertyDetails")}</p>
        <br />
        <h2>{t("TermsOfService:Termination")}</h2>
        <p>{t("TermsOfService:TerminationDetails")}</p>
        <br />
        <h2>{t("TermsOfService:LiabilityLimitation")}</h2>
        <p>{t("TermsOfService:LiabilityLimitationDetails")}</p>
        <br />
        <h2>{t("TermsOfService:GoverningLaw")}</h2>
        <p>{t("TermsOfService:GoverningLawDetails")}</p>
        {/* Add more sections as needed */}
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
