// VerifyEmail.js
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import Header from "./common/Header";

function VerifyEmail() {
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState(
    "Verifying your email..."
  );
  const [isVerified, setIsVerified] = useState(false);
  const [verificationAttempted, setVerificationAttempted] = useState(false);

  // 从 URL 中提取令牌并执行验证
  useEffect(() => {
    if (!verificationAttempted) {
      const token = new URLSearchParams(location.search).get("token");
      if (token) {
        setVerificationAttempted(true);
        verifyEmailToken(token);
      }
    }
  }, [verificationAttempted, location.search]);

  // 将验证逻辑封装到函数中
  const verifyEmailToken = (token) => {
    axios
      .get(`/verify-email?token=${token}`)
      .then((response) => {
        setVerificationStatus(response.data.message);
        setIsVerified(response.status === 200);
      })
      .catch((error) => {
        if (error.response) {
          setVerificationStatus(error.response.data.message);
        } else {
          setVerificationStatus(
            "Email verification failed, please try again or contact support."
          );
        }
        setIsVerified(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="text-white text-center text-2xl mb-4">
          {verificationStatus}
        </div>
        {isVerified && (
          <Link
            to="/login"
            className="bg-mblue w-[200px] text-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
