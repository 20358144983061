import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt } from "react-icons/fa";
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // 黑色半透明遮罩
    zIndex: 1000, // 增加遮罩层的 z-index
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    width: "90%",
    backgroundColor: "#1a202c", // 深色背景
    color: "#fff", // 白色文字
    borderRadius: "10px", // 圆角
    zIndex: 1001, // 增加内容层的 z-index
    border: "none", // 移除边框
  },
};

// 确保在应用程序的根元素中添加此设置
Modal.setAppElement("#root");

function MapModal() {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden"; // 禁用滚动
    document.body.style.touchAction = "none"; // 禁止触摸操作，包括缩放
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "auto"; // 恢复滚动
    document.body.style.touchAction = "auto"; // 恢复触摸操作
  }

  return (
    <div>
      <button onClick={openModal} className="text-white">
        <div className="flex items-start md:justify-center sm:justify-center">
          <FaMapMarkerAlt className="md:mr-2 lg:mr-2 mt-1" />
          <div className="lg:text-left">
            <span>{t("Footer:Address")} (0755) 2162 6786</span>
          </div>
        </div>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Map Modal"
      >
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="mt-2">
          <iframe
            src="https://maps.google.com/maps?q=36%20Man%20Yue%20St,%20Hung%20Hom,%20Kowloon,%20Hong%20Kong&t=&z=11&ie=UTF8&iwloc=&output=embed"
            width="100%"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}

export default MapModal;
