import Header from "./common/Header";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "./common/Footer";
const DashboardStyles = `
  
  .Dashboard-content {
    width: 85%;
    max-width: 1200px; 
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
  }
  

  
  @media (max-width: 768px) {
    .syntax-highlighter {
      width: calc(85vw*1.02 - 40px); 
   
    }

    .Dashboard-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Dashboard-card {
      width: 100%;
  }

`;

const initialCards = [
  {
    key: "Awakened",
    category: "交易价格行为",
  },
  {
    key: "OscillationRangeAnalyzer",
    category: "动量震荡",
  },
  {
    key: "TradingDashboard",
    category: "宏观价值",
  },
  {
    key: "RangeDetectorStrategy",
    category: "动量震荡",
  },
  /* ,
  {
    key: "trendFollowing",
    category: "趋势追踪",
  },
  {
    key: "volatility",
    category: "波动率",
  },
  {
    key: "macroValue",
    category: "宏观价值",
  },
  {
    key: "priceVolumeFactor",
    category: "量价因子",
  },
  {
    key: "monteCarloSimulation",
    category: "蒙特卡罗模拟",
  }, */
  // ... 其他卡片
];

initialCards.forEach((card, index) => {
  card.id = index + 1;
  card.url = `${index + 1}`;
});

function FactorLibrary() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [cards, setCards] = useState(initialCards); // 设置初始状态为所有卡片
  // eslint-disable-next-line no-unused-vars
  const [selectedCategory, setSelectedCategory] = useState(""); // 初始时没有选中的类别

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (!searchTerm) {
      setCards(initialCards);
      return;
    }

    const filteredCards = initialCards.filter(
      (card) =>
        card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        card.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setCards(filteredCards);
  };

  // 根据类别过滤卡片的函数
  const filterByCategory = (categories) => {
    console.log("Selected categories:", categories); // 打印选择的类别
    // 确保categories是一个数组
    const categoryArray = Array.isArray(categories) ? categories : [categories];
    console.log("Category array:", categoryArray); // 打印处理后的类别数组
    setSelectedCategory(categoryArray.join(", "));
    if (!searchTerm) {
      const filteredCards = initialCards.filter((card) =>
        categoryArray.some((category) =>
          Array.isArray(card.category)
            ? card.category.includes(category)
            : card.category === category
        )
      );
      setCards(filteredCards);
    } else {
      const filteredCards = initialCards.filter(
        (card) =>
          (card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            card.content.toLowerCase().includes(searchTerm.toLowerCase())) &&
          categoryArray.some((category) =>
            Array.isArray(card.category)
              ? card.category.includes(category)
              : card.category === category
          )
      );

      console.log("Filtered cards:", filteredCards); // 打印过滤后的卡片数组
      setCards(filteredCards);
    }
  };

  // 样式类，用于按钮
  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "rgba(30, 34, 45, 0.75)",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s, box-shadow 0.3s",
    marginRight: "10px", // 按钮之间的间距
    fontSize: "0.75rem",
    lineHeight: "1rem",
    fontStyle: "normal",
    fontWeight: 500,
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Quantitative Factors Library - 量化因子库 | New Horizon</title>
          <meta
            name="description"
            content="探索New Horizon的量化因子库，深入了解动量震荡、趋势追踪、波动率等因子。Explore New Horizon's Quantitative Factors Library to understand momentum, trend following, volatility, and more."
          />
          <meta
            name="keywords"
            content="量化因子, 动量震荡, 趋势追踪, 波动率, Quantitative Factors, Momentum, Trend Following, Volatility, New Horizon"
          />
          <link rel="canonical" href="https://nhwt.tech/FactorLibrary" />
        </Helmet>
        <Header />
        <div className="flex flex-col items-center h-full text-gray-300 font-[Bloomberg Terminal,Segoe UI,Tahoma,Geneva,Verdana,sans-serif] pb-[80px]">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "85%",
              marginTop: 60,
              marginBottom: 100,
            }}
          >
            <input
              className="p-2.5 rounded border border-gray-300 bg-white/10 text-white mr-2.5 w-11/12"
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button
              className="w-1/3 py-2.5 px-5 bg-blue-600 text-white rounded-md cursor-pointer shadow-md transition duration-300 ease-in-out hover:bg-blue-700 hover:shadow-lg"
              onClick={handleSearch}
            >
              {t("factorLibrary:search")}
            </button>
          </div>

          {/* 类别按钮 */}

          <div className="mx-7">
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() => filterByCategory("交易价格行为")}
            >
              {t("factorLibrary:tradingPriceBehavior")}
            </button>
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() => filterByCategory("动量震荡")}
            >
              {t("factorLibrary:momentumOscillation")}
            </button>
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() => filterByCategory("趋势追踪")}
            >
              {t("factorLibrary:trendFollowing")}
            </button>
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() => filterByCategory("波动率")}
            >
              {t("factorLibrary:volatility")}
            </button>
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() => filterByCategory("宏观价值")}
            >
              {t("factorLibrary:macroValue")}
            </button>
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() => filterByCategory("量价")}
            >
              {t("factorLibrary:priceVolumeFactor")}
            </button>
            <button
              className="mb-3"
              style={buttonStyle}
              onClick={() =>
                filterByCategory([
                  "动量震荡",
                  "交易价格行为",
                  "趋势追踪",
                  "宏观价值",
                  "量价",
                  "波动率",
                ])
              }
            >
              {t("factorLibrary:all")}
            </button>

            {/* 根据需要添加更多类别按钮 */}
          </div>

          <div className="Dashboard-content">
            {cards.map((card, index) => {
              //     const imagePath = `../assets/images/${card.key}.jpg`;
              //     console.log("Image path for", card.key, ":", imagePath);
              return (
                <motion.div
                  className="Dashboard-card bg-[#111111] border border-[#434651] rounded-lg shadow-md flex flex-col overflow-hidden"
                  key={index}
                >
                  <img
                    src={require(`../assets/images/${card.key}.jpg`)}
                    alt={`${card.key}_Image `}
                    className="object-cover object-center max-h-[320px] w-full h-auto"
                  />
                  <div className="flex flex-col justify-between p-[20px] h-full">
                    <h2 className="text-lg text-blue-600 mb-4">
                      {t(`factorLibrary:factorLibrary.${card.key}Title`)}
                    </h2>
                    <p className="text-base mb-2.5 leading-relaxed">
                      {t(`factorLibrary:factorLibrary.${card.key}Content`)}
                    </p>
                    <button
                      className="px-6 py-2.5 bg-blue-700 text-white text-sm font-medium uppercase rounded shadow-md transition duration-300 ease-in-out hover:scale-105 active:scale-95   select-none"
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/factor/${card.url}`)
                      }
                    >
                      {t("factorLibrary:readMore")}
                    </button>
                  </div>
                </motion.div>
              );
            })}
          </div>
          <style>{DashboardStyles}</style>
        </div>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default FactorLibrary;
