import React, { useRef, useEffect } from "react";
import confetti from "canvas-confetti";

const ConfettiPop = ({ shouldPop }) => {
  const confettiRef = useRef();

  useEffect(() => {
    confettiRef.current = confetti.create(
      document.getElementById("confetti-canvas"),
      {
        resize: true,
      }
    );
  }, []);

  useEffect(() => {
    if (shouldPop) {
      confettiRef.current({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: { x: 0.5, y: 0 },
        shapes: ["square"],
        gravity: 0.5,
        scalar: 1.2,
      });
    }
  }, [shouldPop]); // 依赖于shouldPop的变化

  return (
    <canvas
      id="confetti-canvas"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 10,
      }}
    ></canvas>
  );
};

export default ConfettiPop;
