import React from "react";
import { useTranslation } from "react-i18next";
import { PriceBehaviorFactor } from "../articles/priceBehaviorFactor";
import { OscillationRangeAnalyzer } from "../articles/oscillationRangeAnalyzer";
import { TradingDashboard } from "../articles/tradingDashboard";
import { RangeDetectorStrategy } from "../articles/rangeDetectorIndicator";

export const useFactors = () => {
  const { t, i18n } = useTranslation();

  const factors = [
    PriceBehaviorFactor,
    OscillationRangeAnalyzer,
    TradingDashboard,
    RangeDetectorStrategy,
  ].map((factor) => ({
    ...factor,
    name: t(factor.nameKey),
    description: <factor.descriptionComponent i18n={i18n} />,
  }));

  return factors;
};
