// QuantResearch.js
/* “Quant Research”页面是量化研究和策略开发的核心组成部分，应该包含以下几个模块：

1. **策略研发**: 提供策略回测、模型构建和算法开发的工具和编辑器。
2. **数据分析**: 集成数据探索工具，如统计分析、图表绘制和数据挖掘。
3. **风险管理**: 包括风险评估工具，如VaR（Value at Risk）计算和压力测试。
4. **优化工具**: 提供资产配置和组合优化的模块。
5. **研究资源**: 访问历史数据集、研究论文和其他研究材料的链接。
6. **模型库**: 一个包含预构建量化模型和指标的库，方便用户选用和定制。
7. **模拟交易**: 允许用户在没有金钱风险的环境中测试他们的策略。
8. **学习资源**: 提供教程、文档和最佳实践，帮助用户提高他们的量化交易技能。 */

import React from "react";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { FaCog } from "react-icons/fa";

const QuantResearchStyles = `
  /* 样式优化: 使用现代化UI元素 */
  
  /* Style Enhancements: Inspired by Bloomberg Terminal UI Elements */
  .QuantResearch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Bloomberg-style dark background */
    color: #d1d1d1; /* Soft white text for better readability */
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Bloomberg Terminal font or similar */
  }
  
  .QuantResearch-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default layout for wider screens */
    gap: 20px;
    /* Other styles... */
  }

  /* Media query for screens with a max-width of 768px */
  @media screen and (max-width: 768px) {
    .QuantResearch-content {
      grid-template-columns: repeat(1, 1fr); /* Single column layout for narrower screens */
    }
  }
  
  .QuantResearch-card {    background: #111; /* Card background color similar to Bloomberg's palette */
 
  border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px; /* Rounded borders for cards */
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadows for depth */
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; /* Title font size */
    color: #2962ff; /* Bloomberg-like highlight color */
    margin-bottom: 15px; /* Space below title */
  }
  
  .content-text {
    font-size: 16px; /* Text font size */
    margin-bottom: 10px; /* Space below text */
    line-height: 1.6; /* Line height for readability */
  }
  .card-header {
    margin-bottom: 15px; /* Adjust space as needed */
  }
`;

function QuantResearch() {
  const { t } = useTranslation(); // 初始化useTranslation钩子

  let navigate = useNavigate();

  function goToFactorLibrary() {
    navigate("/FactorLibrary");
  }

  return (
    <div>
      <Header />
      <div className="QuantResearch-container">
        <div className="QuantResearch-content">
          {/* 策略研发模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">
              {t("QuantResearch:stratDevTitle")}
            </h1>
            <p className="content-text">{t("QuantResearch:stratDevDesc")}</p>
          </motion.div>

          {/* 数据分析模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">
              {t("QuantResearch:dataAnalTitle")}
            </h1>
            <p className="content-text">{t("QuantResearch:dataAnalDesc")}</p>
          </motion.div>

          {/* 风险管理模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">{t("QuantResearch:riskManTitle")}</h1>
            <p className="content-text">{t("QuantResearch:riskManDesc")}</p>
          </motion.div>

          {/* 优化工具模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">
              {t("QuantResearch:optiToolsTitle")}
            </h1>
            <p className="content-text">{t("QuantResearch:optiToolsDesc")}</p>
          </motion.div>

          {/* 研究资源模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">
              {t("QuantResearch:resResourcesTitle")}
            </h1>
            <p className="content-text">
              {t("QuantResearch:resResourcesDesc")}
            </p>
          </motion.div>

          {/* 因子库模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={goToFactorLibrary}
            className="QuantResearch-card"
          >
            {/* Use a flex container for the header to align items in a row and center them vertically */}
            <div
              className="card-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <FaCog
                size={24}
                color="#2962ff"
                style={{ margin: "0px 10px -5px 0px" }}
              />{" "}
              {/* Icon with a margin to separate it from the text */}
              <h1 className="content-title">
                {t("QuantResearch:modLibTitle")}
              </h1>
            </div>
            <p className="content-text">{t("QuantResearch:modLibDesc")}</p>
          </motion.div>

          {/* 模拟交易模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">
              {t("QuantResearch:simTradeTitle")}
            </h1>
            <p className="content-text">{t("QuantResearch:simTradeDesc")}</p>
          </motion.div>

          {/* 学习资源模块 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="QuantResearch-card"
          >
            <h1 className="content-title">
              {t("QuantResearch:learnResTitle")}
            </h1>
            <p className="content-text">{t("QuantResearch:learnResDesc")}</p>
          </motion.div>
          {/* 可以继续添加其他卡片布局的模块 */}
        </div>
        <style>{QuantResearchStyles}</style>
      </div>
    </div>
  );
}

export default QuantResearch;
