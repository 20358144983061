//AccountInfoPage.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./common/Header";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "./common/Footer";

const AccountManagementStyles = `
  /* 样式优化: 使用现代化UI元素 */
  
  /* Style Enhancements: Inspired by Bloomberg Terminal UI Elements */
  .AccountManagemet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #000; /* Bloomberg-style dark background */
    color: #d1d1d1; /* Soft white text for better readability */
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Bloomberg Terminal font or similar */
  }
  

  
  .AccountManagemet-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    min-height: 400px;
    grid-template-columns: repeat(1, 1fr); /* Default layout for wider screens */
    gap: 20px;
    /* Other styles... */
  }

  /* Media query for screens with a max-width of 768px */
  @media screen and (max-width: 768px) {
    .AccountManagemet-content {
      grid-template-columns: repeat(1, 1fr); /* Single column layout for narrower screens */
    }
  }




  .AccountManagemet-card {    background: #111; /* Card background color similar to Bloomberg's palette */
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px; /* Rounded borders for cards */
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadows for depth */
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; /* Title font size */
    color: #2962ff; /* Bloomberg-like highlight color */
    margin-bottom: 15px; /* Space below title */
  }
  
  .content-text {
    font-size: 16px; /* Text font size */
    margin-bottom: 10px; /* Space below text */
    line-height: 1.6; /* Line height for readability */
  }
  .welcome-message {
    font-size: 1rem; /* A size that balances visibility and space */
    color: #ffffff; /* White text for stark contrast on a flat UI */
    background: #; /* Deep black background for a flat, modern look */
    padding: 15px 15px; /* Uniform padding for a neat layout */
    border-left: 4px solid #2962ff; /* A pop of Bloomberg's brand color */
    margin: 0px auto; /* Centered margin with some breathing room */
  //  text-align: center; /* Centered text to balance the content */
  }

  .download {
    font-size: 1rem; /* A size that balances visibility and space */
    color: #ffffff; /* White text for stark contrast on a flat UI */
    background: #000000; /* Deep black background for a flat, modern look */
    padding: 15px 20px; /* Uniform padding for a neat layout */
   color:#2962ff;
    margin: 0px auto; /* Centered margin with some breathing room */
    text-align: right; /* Centered text to balance the content */
  }
  
  /* Ensuring the username is always capitalized for uniformity */
  .username {
    text-transform: uppercase; /* Capitalize username */
    letter-spacing: 0.05em; /* Spacing out the letters for readability */
  }

  /* Responsive adjustments for a consistent experience across devices */
  @media (max-width: 768px) {
    .welcome-message {
      font-size: 1.5rem; /* Slightly smaller font size for mobile devices */
      padding: 12px 20px; /* Smaller padding for less screen real estate */
      border-left-width: 3px; /* Slimmer border-left to keep the design tidy on mobile */
    }
  }
  .welcome-message, .download {
    /* Adjust width as necessary, remove if not needed */
    flex: 1; /* If you want them to share available space equally */
    margin: 0; /* Adjust margin as needed */
  
    /* Other styles remain the same */
  }
`;

const AccountInfoPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 新增状态用于控制表单的显示
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [job, setJob] = useState("");
  const [gender, setGender] = useState("");
  const [phoneError, setPhoneError] = useState(""); // 新增状态来存储电话号码的错误信息
  const [hasPassword, setHasPassword] = useState(false);
  const phoneSchema = Yup.string()
    .matches(
      /^\+?[0-9]+$/,
      "Phone number is not valid / 输入的电话号码不符合规则"
    )
    .min(3, "Phone number must be at least 3 digits / 电话号码至少需要3位数字");
  //.required("Phone number is required / 需要填写电话号码");

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found in localStorage.");
        return;
      }
      const response = await axios.get("/profile", {
        headers: {
          Authorization: `${token}`,
        },
      });

      setUser(response.data.user);
      setHasPassword(response.data.user.password !== undefined);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const validatePhone = async (value) => {
    try {
      await phoneSchema.validate(value);
      setPhoneError(""); // 如果校验通过，清除错误信息
    } catch (err) {
      setPhoneError(err.message); // 如果校验失败，设置错误信息
    }
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhone(newPhone);
    validatePhone(newPhone); // 校验电话号码
  };

  const handleEditClick = () => {
    setIsEditing(true); // 点击编辑时设置为true
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found in localStorage.");
        return;
      }

      const updatedInfo = { phone, country, job, gender };
      const response = await axios.post("/update-user-info", updatedInfo, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      console.log("Profile updated:", response.data);
      await fetchUserData();
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("No token found in localStorage.");
          return;
        }
        const response = await axios.get("/profile", {
          headers: {
            Authorization: `${token}`,
          },
        });

        //   console.log("Fetched user data:", response.data);
        setUser(response.data.user);
        setHasPassword(response.data.user.password !== undefined); // Check if password field exists
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      }
    };

    fetchUserData();
  }, []);
  return (
    <div>
      <Header />
      <div className="AccountManagemet-container mt-10 pb-[80px] min-h-[800px]">
        <div className="AccountManagemet-content ">
          {/* 账户安全 */}
          <motion.div className="AccountManagemet-card">
            <h1 className="content-title">{t("AccountManagement:Sec")}</h1>

            <div className="grid grid-cols-3">
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:Un")}:
                  <br />
                  <p className="font-normal"> {user?.username}</p>
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:Ue")}:<br />
                  <p className="font-normal"> {user?.email}</p>
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:Uv")}:<br />
                  <p className="font-normal"> {user?.role}</p>
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:phone")}
                  :
                  <br />
                  <p className="font-normal"> {user?.phone}</p>
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:country")} :
                  <br />
                  <p className="font-normal"> {user?.country}</p>
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:job")} :
                  <br />
                  <p className="font-normal"> {user?.job}</p>
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold">
                  {t("AccountManagement:gender")} :
                  <br />
                  <p className="font-normal"> {user?.gender}</p>
                </p>
              </div>
            </div>
            {!isEditing && (
              <button
                onClick={handleEditClick}
                className="bg-gradient-to-r mt-8 border border-customGray hover:bg-mblue  to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out border border-blue-300 hover:border-mblue text-shadow"
              >
                {t("AccountManagement:EditProfile")}
              </button>
            )}
            {isEditing && (
              <form
                onSubmit={handleSubmit}
                className="bg-[#111111] p-6 rounded-lg shadow-lg text-gray-300"
              >
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-bold mb-2"
                  >
                    Phone
                  </label>
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange} // 更新onChange处理函数
                    placeholder="Phone"
                    className="shadow appearance-none border border-customGray rounded w-full py-2 px-3 bg-[#111111] text-white leading-tight focus:outline-none focus:shadow-outline"
                  />{" "}
                  {phoneError && (
                    <p className="text-orange-500">{phoneError}</p>
                  )}{" "}
                  {/* 显示错误信息 */}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="country"
                    className="block text-sm font-bold mb-2"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Country"
                    className="shadow appearance-none border  border-customGray rounded w-full py-2 px-3 bg-[#111111] text-white leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="job" className="block text-sm font-bold mb-2">
                    Job
                  </label>
                  <input
                    type="text"
                    id="job"
                    value={job}
                    onChange={(e) => setJob(e.target.value)}
                    placeholder="Job"
                    className="shadow appearance-none border  border-customGray rounded w-full py-2 px-3 bg-[#111111] text-white leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-bold mb-2"
                  >
                    Gender
                  </label>
                  <select
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="shadow border border-customGray rounded w-full py-2 px-3 bg-[#111111] text-white leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>

                <button
                  type="submit"
                  className="w-full border border-customGray hover:bg-mblue  hover:border-mblue  h text-white font-semibold py-2 px-4 rounded-lg shadow hover:shadow-lg transition duration-200 ease-in-out mt-4"
                >
                  Save Profile
                </button>
              </form>
            )}
            {hasPassword ? (
              <button
                onClick={() => navigate("/forgot-password")}
                className="bg-gradient-to-r mt-8 border border-customGray hover:bg-mblue  to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out border border-blue-300 hover:border-mblue text-shadow"
              >
                {t("AccountManagement:ResetPassword")}
              </button>
            ) : (
              <button
                onClick={() => navigate("/set-password")}
                className="bg-gradient-to-r mt-8 border border-customGray hover:bg-mblue  to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out border border-blue-300 hover:border-mblue text-shadow"
              >
                {t("AccountManagement:SetPassword")}
              </button>
            )}
          </motion.div>
        </div>
        <style>{AccountManagementStyles}</style>
      </div>
      <Footer />
    </div>
  );
};

export default AccountInfoPage;
