// 导入初始化函数和所需服务
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FcGoogle } from "react-icons/fc";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA8kEsZieuVWa16Z7QUZ3ATAaV3RVcGPww",
  //authDomain: "newhorizon-1064f.firebaseapp.com",
  authDomain: "auth.nhwt.tech",
  projectId: "newhorizon-1064f",
  storageBucket: "newhorizon-1064f.appspot.com",
  messagingSenderId: "533848586039",
  appId: "1:533848586039:web:0bdd171d9fef0d39444cb2",
  // measurementId: "G-FCB3KWWF0N",
};
//authDomain: "nhwt.tech",
initializeApp(firebaseConfig);

const GoogleSignInButton = ({ onGoogleLoginSuccess }) => {
  const { t } = useTranslation(); // 使用 useTranslation
  const navigate = useNavigate();
  const auth = getAuth();

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      const result = await signInWithPopup(auth, provider);
      console.log("Google login successful:", result);
      const email = result.user.email;
      const fullName = result.user.displayName;

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/google-login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, fullName }),
        }
      );

      const data = await response.json();
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.userId);
      console.log("JWT received:", data.token);
      console.log("User ID received:", data.userId);

      // 显示登录成功的提示信息
      toast.success("Login successful!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
      // 触发庆祝动画
      onGoogleLoginSuccess();
      // 跳转到主页或其他页面

      // 重新加载页面
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 1500);
      //
    } catch (error) {
      console.error("Google login error:", error);
    }
  };

  return (
    <button
      onClick={signInWithGoogle}
      className="my-[10px] py-[10px] px-[15px] rounded-[4px] border border-c w-full bg-[rgba(255,255,255,0.1)] text-f flex items-center justify-center relative"
    >
      <FcGoogle className="absolute left-[20px]" />
      <div className="text-center w-full">{t("Register:signInWithGoogle")}</div>
    </button>
  );
};

export default GoogleSignInButton;
