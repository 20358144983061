import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const AcademicCitations = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>Academic Citations - 学术引用 | New Horizon</title>
        <meta
          name="description"
          content="Explore a list of academic citations and references from renowned sources curated by New Horizon."
        />
        <meta
          name="keywords"
          content="Academic Citations, 学术引用, Research, References, New Horizon"
        />
        <link rel="canonical" href="https://nhwt.tech/academic-citations" />
      </Helmet>
      <Header />
      <div className="max-w-[800px] min-h-[800px] text-[#ffffff] p-10 md:p-20 leading-8 text-base md:text-lg mx-auto pb-[80px]">
        <h1 className="text-[24px] mb-[40px]">
          {t("Footer:AcademicCitations")}
        </h1>
        <ul>
          <li className=" mb-[10px]">
            <a href="https://www.bridgewater.com/">
              <strong>Bridgewater Associates</strong> (U.S.)&nbsp;🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.man.com/">
              <strong>Man Group</strong>(UK)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.rentec.com/">
              <strong>Renaissance Technologies</strong>(U.S.)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.mlp.com/">
              <strong>Millennium Management</strong>(U.S.)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.citadel.com/">
              <strong>Citadel</strong>(U.S.)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.deshaw.com/">
              <strong>D.E. Shaw Group</strong>(U.S.)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.twosigma.com/">
              <strong>Two Sigma Investments/Advisers</strong>(U.S.)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.dkpartners.com/">
              <strong>Davidson Kempner Capital Management</strong>(U.S.)&nbsp;🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.faralloncapital.com/">
              <strong>Farallon Capital Management</strong> (U.S.)&nbsp;🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.tcifund.com/">
              <strong>TCI Fund Management</strong>(UK)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://deepmind.google/discover/blog/alphadev-discovers-faster-sorting-algorithms/">
              <strong>
                AlphaDev discovers faster sorting algorithms - DeepMind
              </strong>
              (UK)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://alphagoteach.deepmind.com/index.html">
              <strong>
                Let the AlphaGo Teaching Tool help you find new and creative
                ways of playing Go. - DeepMind
              </strong>
              (UK)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.nature.com/articles/nature16961.epdf?shared_access_token=eKK0163L_QWhJOnSS4Wet9RgN0jAjWel9jnR3ZoTv0OivKk3lXs6SxMz535byYwHqIVouJ6qKrtxfOrBqgMI8z2GAxKloOrZJt2uhpOg7ZJrYyYlxydsd7C2FJN6_Oeui0xLpQ4rv5dW4j8qo7PZCEpVRl6vsFBUeBY00_dlKUE%3D">
              <strong>
                Mastering the game of Go with deep neural networks and tree
                search
              </strong>
              (UK)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="/NIPS-2017-attention-is-all-you-need-Paper.pdf">
              <strong>Attention Is All You Need - Ashish Vaswani et</strong>
              (US/Germany)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="/bitter_lesson.pdf">
              <strong>The Bitter Lesson - Rich Sutton</strong>
              (US)&nbsp; 🔗
            </a>
          </li>
          <li className=" mb-[10px]">
            <a href="https://www.fractalflowpro.com">
              <strong>Fractal Flow</strong>
              (US)&nbsp; 🔗
            </a>
          </li>
        </ul>
      </div>

      <Footer />
    </div>
  );
};

export default AcademicCitations;
