import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js"; // 或者 .jsx，具体取决于文件类型
import reportWebVitals from "./reportWebVitals.js";
import "./locales/i18n.js";
import axios from "axios";

//import ReactGA from 'react-ga';

// 使用环境变量代替硬编码的 URL
axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:4000";
// 初始化 Google Analytics，使用您的实际跟踪ID
//ReactGA.initialize('G-LXET4SELQY');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
