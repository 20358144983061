// InvestorSupport.js
/* "投资者支持"页面应包含以下模块来提供全面的支持和资源：

1. **常见问题解答（FAQs）** - 提供常见疑问的即时回答。
2. **在线聊天支持** - 实时帮助用户解决问题的聊天窗口。
3. **联系信息** - 公司的联系电话、电子邮件地址以及其他联系方式。
4. **教育资源** - 投资教程、指南和最佳实践。
5. **提交请求表单** - 用户可以提交具体问题或服务请求的表单。
6. **市场动态** - 提供影响投资决策的最新市场新闻和分析。
7. **视频教学** - 视频格式的教育内容，帮助用户了解不同的投资工具和策略。
8. **下载中心** - 提供有关投资产品的白皮书、研究报告和其他相关文档的下载。
9. **事件和研讨会信息** - 信息和注册即将来临的相关事件和研讨会。
10. **投资工具和计算器** - 帮助用户计划投资和评估潜在回报的在线工具。 */
import React from "react";
import Header from "./common/Header";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const InvestorSupportStyles = `
  /* 样式优化: 使用现代化UI元素 */
  
  /* Style Enhancements: Inspired by Bloomberg Terminal UI Elements */
  .InvestorSupport-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #121212; /* Bloomberg-style dark background */
    color: #d1d1d1; /* Soft white text for better readability */
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Bloomberg Terminal font or similar */
  }
  

  .InvestorSupport-content {
    width: 85%;
    max-width: 1200px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default layout for wider screens */
    gap: 20px;
    /* Other styles... */
  }

  /* Media query for screens with a max-width of 768px */
  @media screen and (max-width: 768px) {
    .InvestorSupport-content {
      grid-template-columns: repeat(1, 1fr); /* Single column layout for narrower screens */
    }
  }


  .InvestorSupport-card {    background: #111; /* Card background color similar to Bloomberg's palette */
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px; /* Rounded borders for cards */
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadows for depth */
    display: flex;
    flex-direction: column;
  }
  
  .content-title {
    font-size: 24px; /* Title font size */
    color: #2962ff; /* Bloomberg-like highlight color */
    margin-bottom: 15px; /* Space below title */
  }
  
  .content-text {
    font-size: 16px; /* Text font size */
    margin-bottom: 10px; /* Space below text */
    line-height: 1.6; /* Line height for readability */
  }
`;

function InvestorSupport() {
  const { t } = useTranslation(); // 初始化useTranslation钩子

  return (
    <div>
      <Header />
      <div className="InvestorSupport-container">
        <div className="InvestorSupport-content">
          {/* 在此添加motion.div元素与提供的JSON内容 */}
          {/* 常见问题解答 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">{t("InvestorSupport:FAQsTitle")}</h1>
            <p className="content-text">{t("InvestorSupport:FAQsContent")}</p>
          </motion.div>

          {/* 在线聊天支持 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:ChatSupportTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:ChatSupportContent")}
            </p>
          </motion.div>

          {/* 联系信息 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:ContactInfoTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:ContactInfoContent")}
            </p>
          </motion.div>

          {/* 教育资源 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:EducationalResourcesTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:EducationalResourcesContent")}
            </p>
          </motion.div>

          {/* 提交请求表单 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:RequestFormTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:RequestFormContent")}
            </p>
          </motion.div>

          {/* 市场动态 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:MarketUpdatesTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:MarketUpdatesContent")}
            </p>
          </motion.div>

          {/* 视频教学 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:VideoTutorialsTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:VideoTutorialsContent")}
            </p>
          </motion.div>

          {/* 下载中心 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:DownloadCenterTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:DownloadCenterContent")}
            </p>
          </motion.div>

          {/* 事件和研讨会信息 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:EventsInfoTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:EventsInfoContent")}
            </p>
          </motion.div>

          {/* 投资工具和计算器 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="InvestorSupport-card"
          >
            <h1 className="content-title">
              {t("InvestorSupport:InvestmentToolsTitle")}
            </h1>
            <p className="content-text">
              {t("InvestorSupport:InvestmentToolsContent")}
            </p>
          </motion.div>

          {/* 可以在此添加其他卡片布局模块 */}
        </div>
        <style>{InvestorSupportStyles}</style>
      </div>
    </div>
  );
}

export default InvestorSupport;
