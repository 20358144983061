import React, { useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet";

const MediaKitStyles = `

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    25% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    75% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .title {
    font-family:EuclidCircularSemibold,sans-serif;
    font-size: 100px;  
    background:#ffffff; 
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.02em;
      line-height: var(--ui-lib-typography-line-height);
      z-index: 2;
      margin-bottom: 120px;
      font-size: 5vw;
    background-size: 300% 300%;
    border: 1px solid #0000;
    border-radius: 24px;
    outline: none;
    overflow: visible;
    position: relative;
  }
  

`;

const MediaKit = () => {
  const [isHovered, setIsHovered] = useState(false);

  // 直接下载链接
  const handleDownload = () => {
    window.location.href = "/NewHorizonMediaKit.zip";
  };
  return (
    <div>
      <Helmet>
        <title>Media Kit | New Horizon</title>
        <meta
          name="description"
          content="Explore the Media Kit of New Horizon WisdomTech Group Limited. Get detailed information on our innovative fintech solutions, company profile, and media resources."
        />
        <meta
          name="keywords"
          content="Media Kit, New Horizon WisdomTech, Fintech Innovation, Financial Technology, AI, Cloud Computing, Media Resources, 媒体工具, 新长慧和"
        />
        <meta property="og:title" content="Media Kit | New Horizon" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nhwt.tech/media-kit/" />
        <link rel="canonical" href="https://nhwt.tech/media-kit/" />
      </Helmet>
      <style>{MediaKitStyles}</style>
      <Header />
      <div className="bg-black min-h-[800px] w-full flex flex-col text-center items-center">
        <h1
          className="bg-GBlue font-NH1 font-semibold bg-clip-text text-transparent text-[10vw] mt-10"
          style={{
            backgroundSize: "200% 200%",
            animation: "gradientAnimation 20s ease infinite",
          }}
        >
          Media Kit
        </h1>
        <h2 className="text-[#999] leading-relaxed text-[16px] w-[80vw] max-w-[800px]">
          The provided media kit assets are for creators, community members, and
          stakeholders to align their efforts and presentations. It contains key
          elements shaping NewHorizon's brand identity and guidelines for
          maintaining visual consistency across regions and platforms. Let's
          build NewHorizon's brand together.
        </h2>
        <div
          className="w-[80vw] max-w-[800px] title px-[24px] py-[60px] text-[#fff] mt-[60px] hover:scale-[1.02] active:scale-[0.98] transition duration-300 ease-in-out"
          style={{
            background: isHovered
              ? `linear-gradient(#111, #111) padding-box, linear-gradient(90deg,#006fde,#3a99ce 25%,#00e7e7 54.17%,#3a99ce 86%,#1238ff) border-box`
              : `linear-gradient(#000, #000) padding-box, linear-gradient(90deg,#006fde,#3a99ce 25%,#00e7e7 54.17%,#3a99ce 86%,#1238ff) border-box`,
            animation: "gradientAnimation 20s ease infinite",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleDownload}
        >
          Download materials
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MediaKit;
