// DonationBox.js
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  width: 600px;
  margin: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: #1c1c1c;
`;

const DonationBox = () => {
  const { t } = useTranslation(); // 初始化useTranslation钩子
  const [donationAmount, setDonationAmount] = useState("20");
  const [paypalClientId, setPaypalClientId] = useState("");
  //  const [buttonKey, setButtonKey] = useState(0);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/config")
      .then((response) => {
        setPaypalClientId(response.data.paypalClientId);
      })
      .catch((error) => {
        console.error("Error fetching PayPal config:", error);
      });
  }, []);

  const handleDonation = async () => {
    const updatedOrderData = {
      outTradeNo: "", // 从后端生成的订单号
      amount: donationAmount, // 使用用户输入的捐赠金额
      subject: "Support",
      body: "Thank you for your generous donation",
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/create-payment-anonymous",
        updatedOrderData
      );
      const newPaymentUrl = response.data;

      // 如果有 URL，则进行跳转
      if (newPaymentUrl) {
        window.open(newPaymentUrl, "_blank");
      }
    } catch (error) {
      console.error("Donation request failed:", error);
    }
  };

  const handleIncrement = () => {
    setDonationAmount((prevAmount) =>
      Math.max(0, Number(prevAmount) + 1).toString()
    );
  };

  const handleDecrement = () => {
    setDonationAmount((prevAmount) =>
      Math.max(0, Number(prevAmount) - 1).toString()
    );
  };

  // 在输入框中直接设置金额时也更新键值
  const handleAmountChange = (e) => {
    setDonationAmount(e.target.value);
  };
  const paypalButtonsStyle = {
    layout: "vertical",
    color: "silver",
    label: "pay",
  };
  const createOrder = useCallback(() => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/paypal/create-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: donationAmount,
        items: [
          {
            name: "Support",
            category: "DIGITAL_GOODS",
            quantity: "1",
            unit_amount: {
              currency_code: "USD",
              value: donationAmount,
            },
          },
        ],
        description: "Support",
      }),
    })
      .then((response) => response.json())
      .then((order) => {
        console.log("Order ID received:", order.id);
        // 在开始支付流程时保存订单号
        sessionStorage.setItem("orderId", order.id);
        return order.id;
      });
  }, [donationAmount]); // 依赖项数组中包含donationAmount

  function onApprove(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/paypal/capture-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderId: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((orderData) => {
        const savedOrderId = sessionStorage.getItem("orderId");
        if (orderData.id === savedOrderId) {
          // 订单号匹配，支付成功
          console.log("Order captured successfully:", orderData);
          toast.success("Payment successful! Thank you for your donation.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }

  const ButtonWrapper = ({ showSpinner }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <>
        {showSpinner && isPending && <div className="spinner" />}
        <PayPalButtons
          disabled={false}
          style={paypalButtonsStyle}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </>
    );
  };

  return (
    <div className="sm:w-[96%]">
      <ToastContainer />
      <style>
        {`
          input[type="number"]::-webkit-inner-spin-button, 
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
      </style>

      <PageContainer>
        <Container className="border border-mborder text-f">
          <h1 className="text-center text-24 mb-[20px]">
            {t("DonationBox:support")}
          </h1>
          <h3 className="text-center text-[18px] mb-4 mt-8">
            {t("DonationBox:motivation")}
          </h3>
          <div>
            <div className="flex justify-center w-100">
              <input
                type="number"
                value={donationAmount}
                onChange={handleAmountChange} // 使用更新后的处理函数
                //     onChange={(e) => setDonationAmount(e.target.value)}
                className="!m-0 h-10 w-30 bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50 rounded-l-md shadow-sm p-2 appearance-none"
              />
              <div className="flex flex-col">
                <button
                  className="flex items-center justify-center h-5 bg-gray-700 text-white border border-gray-600 hover:border-blue-500 p-1 rounded-tr-md"
                  onClick={handleIncrement}
                >
                  +
                </button>
                <button
                  className=" flex items-center justify-center h-5 bg-gray-700 text-white border border-gray-600 hover:border-blue-500 p-1 rounded-br-md"
                  onClick={handleDecrement}
                >
                  -
                </button>
              </div>
            </div>
            <div>
              <button
                onClick={handleDonation}
                className="mt-10 bg-mblue  text-white py-3 px-4 my-4 w-full rounded border border-transparent cursor-pointer text-center text-lg font-medium"
              >
                {t("DonationBox:now")}
              </button>
            </div>
            <div>
              {paypalClientId && (
                <PayPalScriptProvider
                  options={{
                    clientId: paypalClientId,
                    components: "buttons",
                    currency: "USD",
                  }}
                >
                  <ButtonWrapper showSpinner={true} />
                </PayPalScriptProvider>
              )}
            </div>
          </div>
        </Container>
      </PageContainer>
    </div>
  );
};

export default DonationBox;
