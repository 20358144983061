import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";

const buttonStyle = {
  minWidth: "84px",
  padding: "8px 30px",
  fontSize: "12px",
  fontWeight: "bold",
  color: "white",
  backgroundColor: "#222",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  margin: "10px",
  display: "inline-block",
  textAlign: "center",
};

const buttonVariants = {
  hover: {
    scale: 1.05,
    backgroundColor: "#333",
  },
  tap: {
    scale: 0.95,
  },
};

const LogoutButton = ({ onLogout }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // React 前端 - 处理用户点击注销按钮的函数
  const handleLogoutClick = async () => {
    if (window.confirm(t("Header:logoutSure"))) {
      try {
        const userId = localStorage.getItem("userId"); // 假设用户ID存储在本地存储中
        await axios.post(process.env.REACT_APP_API_URL + "/logout", { userId });

        // 清除本地存储中的相关信息
        localStorage.removeItem("VIPtoken");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");

        // 调用从父组件传下来的onLogout函数
        onLogout();

        // 导航到登录页
        navigate("/login");
      } catch (error) {
        console.error("Error during logout:", error);
        // 可以添加一些错误处理逻辑
      }
    }
  };

  return (
    <motion.button
      variants={buttonVariants}
      whileHover="hover"
      whileTap="tap"
      className="logout-button"
      onClick={handleLogoutClick}
      style={buttonStyle}
    >
      {t("Header:Logout")}
    </motion.button>
  );
};

const LogoutRouteHandler = ({ onLogout }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const shouldLogout = new URLSearchParams(window.location.search).has(
      "confirm"
    );
    if (shouldLogout && window.confirm(t("Header:logoutSure"))) {
      localStorage.removeItem("VIPtoken");
      onLogout();
      navigate("/login");
    } else {
      navigate("/");
    }
  }, [navigate, onLogout, t]);

  return null;
};

export { LogoutButton, LogoutRouteHandler };
