import React, { useState, useRef, useMemo, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import html2canvas from "html2canvas";

const getRandomMorandiColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = Math.floor(Math.random() * 20) + 20;
  const lightness = Math.floor(Math.random() * 20) + 40;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%", // 适应移动端屏幕宽度
    width: "90%",
    maxHeight: "90vh", // 设置最大高度为视口高度的90%
    overflow: "auto", // 如果内容超出高度，添加滚动条
    backgroundColor: "#1a202c",
    color: "#fff",
    borderRadius: "10px",
    zIndex: 1001,
    border: "none",
  },
};

// 确保在应用程序的根元素中设置 Modal 的 app 元素
Modal.setAppElement("#root");

const Chart = () => {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 新增状态来控制进度条的显示
  const [selectedParam, setSelectedParam] = useState("All Params");
  const [yAxisKey, setYAxisKey] = useState("NETPROFITPERCENT");
  const [rawData, setRawData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false); // 控制 Modal 的开关
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const processFileData = () => {
    console.log("rawData:", rawData); // 调试信息
    if (!rawData || rawData.length === 0) {
      // 如果rawData为空或未定义，则不执行后续处理
      return;
    }

    setIsLoading(true); // 开始处理数据时显示进度条

    let dataSets = [];
    let pointsMap = {}; // 新增一个对象来存储已经处理过的点

    rawData.forEach((item) => {
      const parameters = item.PARAMETERS.split(", ");
      parameters.forEach((param, index) => {
        if (!dataSets[index]) {
          dataSets[index] = [];
        }

        const point = {
          x: parseFloat(param),
          y: parseFloat(item[yAxisKey]),
        };

        // 使用x和y坐标生成一个唯一的键
        const key = `${point.x},${point.y}`;

        // 如果这个点还没有被处理过，就添加到数据集中
        if (!pointsMap[key]) {
          dataSets[index].push(point);
          pointsMap[key] = true;
        }
      });
    });

    setDataSets(dataSets);
    setIsLoading(false); // 数据处理完成后隐藏进度条
  };

  useEffect(() => {
    if (rawData.length > 0) {
      processFileData();
    }
  }, [yAxisKey, rawData]);

  const handleFileUpload = (file) => {
    setIsLoading(true); // 开始上传文件时显示进度条

    const formData = new FormData();
    formData.append("file", file);

    fetch(`${process.env.REACT_APP_API_URL}/process-csv`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // 检查返回的数据是否是数组
        if (Array.isArray(data.data)) {
          setRawData(data.data);
        } else {
          //    console.error("返回的数据不是数组");
          setRawData([]); // 设置 rawData 为空数组以避免错误
        }
        setIsLoading(false); // 数据处理完成后隐藏进度条
      })
      .catch((error) => {
        //   console.error("Error:", error);
        alert("File processing error, please try again later.");
        setIsLoading(false); // 隐藏进度条
        setRawData([]); // 设置 rawData 为空数组以避免错误
      });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert("File size cannot exceed 20MB");
        return;
      }
      handleFileUpload(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleParamChange = (event) => {
    setSelectedParam(event.target.value);
  };

  const handleYAxisChange = (event) => {
    setYAxisKey(event.target.value);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const allData = useMemo(() => dataSets.flat(), [dataSets]);
  const xValues = allData.map((d) => d.x);
  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);

  const chartRef = useRef(null);

  const handleDownloadChart = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = "NewHorizonChartAnalysis.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };
  return (
    <div className="flex flex-col w-full justify-center text-center">
      <h1 className="text-4xl text-[24px] sm:text-[16px] font-bold mb-4 pt-6 pb-4">
        {t("MarketDataFundInfo:ChartAnalysisTitle")} 🚀
        <button onClick={openModal} className="my-4">
          <FaInfoCircle className="w-4 h-4 mr-2 text-gray-400 hover:text-gray-500" />
        </button>
      </h1>

      {/* Modal 弹窗 */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Chart Info Modal"
      >
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="mt-2">
          <p>
            <div className="flex flex-col w-full text-center">
              <strong className="block mx-auto">
                TradingView Analysis Tool
              </strong>
              <span className="block mx-auto">
                Inspired by the revolutionary thinking of AlphaGo's value
                network
              </span>
            </div>
            <br />
            In the financial markets, factor analysis is key to successful
            investment. However, the complex relationships between numerous
            factors often make it difficult for investors to grasp. The
            TradingView Analysis Tool draws on the value network concept of
            AlphaGo to reveal the deep connections between factors and important
            financial indicators such as returns and maximum drawdowns.
            <br />
            <br />
            <strong>Main Features and Advantages:</strong>
            <br />
            📊 <strong>Highly Visual Analysis:</strong> Through intuitive charts
            and data displays, help users quickly understand complex data
            relationships and easily identify potential investment
            opportunities.
            <br /> <br />
            🎨 <strong>Visual Charts:</strong> Provide intuitive charts and data
            displays, helping users quickly grasp complex data relationships and
            identify potential investment opportunities.
            <br /> <br />
            🛠️ <strong>Factor Screening Tools:</strong> Provide powerful factor
            screening capabilities to help users quickly identify the most
            influential investment factors from vast amounts of data and
            optimize investment portfolios.
            <br /> <br />
            ⚖️ <strong>Risk Management:</strong> Analyze the relationship
            between factors and maximum drawdowns to help users assess potential
            risks and make more robust investment decisions.
            <br />
            <br />
            Whether you are an experienced trader or a novice in the financial
            markets, the TradingView Analysis Tool can help you gain insights
            into market dynamics, uncover key factors, and seize opportunities
            in the complex and ever-changing financial markets.
          </p>
        </div>
      </Modal>
      <input
        type="file"
        ref={fileInputRef}
        accept=".csv"
        onChange={handleFileInputChange}
        className="hidden"
      />
      <div className="flex justify-end lg:mr-6 md:mr-6 sm:flex-col mb-3">
        <select
          className="bg-[#111] text-gray-300 border border-[#222] rounded-md p-2 lg:mr-6 md:mr-6 sm:mb-3"
          value={selectedParam}
          onChange={handleParamChange}
        >
          <option value="All Params">All Params</option>
          {dataSets.map((_, index) => (
            <option key={index} value={`Params ${index + 1}`}>
              Params {index + 1}
            </option>
          ))}
        </select>
        <select
          className="bg-[#111] text-gray-300 border border-[#222] rounded-md p-2 lg:mr-6 md:mr-6 sm:mb-3"
          value={yAxisKey}
          onChange={handleYAxisChange}
        >
          <option value="NETPROFITPERCENT">NETPROFITPERCENT</option>
          <option value="MAXDRAWDOWNPERCENT">MAXDRAWDOWNPERCENT</option>
        </select>
        <button
          onClick={handleDownloadChart}
          className="bg-[#111] text-gray-300 border border-[#222] rounded-md p-2"
        >
          Download Chart
        </button>
      </div>

      <div ref={chartRef}>
        <ResponsiveContainer
          className="bg-[#101010] mb-3 rounded-lg"
          width="100%"
          height={400}
        >
          {dataSets.length === 0 ? (
            // 如果没有上传文件，显示这个提示信息
            <div className="flex flex-col items-center justify-center h-full p-10">
              <p className="text-center text-gray-300 text-[24px] sm:text-[18px] leading-[50px]">
                Please upload the optimization results report in CSV format from
                the&nbsp;
                <a
                  href="https://chromewebstore.google.com/detail/newhorizon-optimizer/icjbokeihkhdilfokigedgpoifnagkjo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500  hover:text-blue-400"
                >
                  NewHorizon Optimizer plugin
                </a>
                &nbsp;🚀. <br />
                Use the "Drag or Click to Choose File" window below. 📁👇
              </p>
            </div>
          ) : (
            <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid stroke="#222" />
              <XAxis
                type="number"
                dataKey="x"
                name="PARAMETERS"
                domain={[minX, maxX]}
              />
              <YAxis type="number" dataKey="y" name={yAxisKey} />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Legend />
              {dataSets.map((data, index) => {
                const paramName = `Params ${index + 1}`;
                return (
                  (selectedParam === "All Params" ||
                    selectedParam === paramName) && (
                    <Scatter
                      key={`dataSet-${index}`}
                      name={paramName}
                      data={data}
                      fill={getRandomMorandiColor()}
                    />
                  )
                );
              })}
            </ScatterChart>
          )}
        </ResponsiveContainer>
      </div>

      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`flex justify-center items-center bg-[#111] text-gray-300 h-[200px] mb-[20px]  border-2 ${
          isDragging ? "border-blue-400 bg-gray-900" : "border-[#222]"
        } rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={handleButtonClick}
      >
        Drag or Click to Choose File
        {isLoading && (
          <div className="absolute top-0 left-0 h-full w-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div className="w-40 h-1 bg-gray-300 rounded-full overflow-hidden">
              <div className="h-full bg-blue-400 animate-pulse"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
