import React, { useState, useEffect } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useTranslation } from "react-i18next";
//import { motion } from 'framer-motion';

const TradingSignalStyles = `
  .TradingSignal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height:800px;
    background-color: #121212;
    color: #d1d1d1;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .TradingSignal-content {
    width: 100%;
    max-width: 1200px;
  
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  @media screen and (max-width: 768px) {
    .TradingSignal-content {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .TradingSignal-card {
    background: #111;
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
  }

  .content-title {
    font-size: 24px;
    color: #2962ff;
    margin-bottom: 15px;
  }

  .content-text {
    font-size: 16px;
    margin: 0px;
    line-height: 1.6;
  }

/*   .webhook-data-block {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #434651;
    border-radius: 8px;
  } */
  
  .content-text {
    margin-bottom: 5px;
  }
`;

function TradingSignal() {
  const { t } = useTranslation();
  const [webhookData, setWebhookData] = useState([]); // 存储 webhook 数据的状态
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/api/webhookdata"
        ); // 更新 API 地址
        const data = await response.json();
        setWebhookData(data);
      } catch (error) {
        console.error("Error fetching webhook data:", error);
      }
    };

    fetchData();
  }, []);

  // 格式化时间戳的函数

  const formatTimestamp = (isoString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const localTime = new Intl.DateTimeFormat("en-US", options)
      .format(new Date(isoString))
      .toUpperCase(); // 转换为大写

    const easternTime = new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: "America/New_York",
    })
      .format(new Date(isoString))
      .toUpperCase(); // 转换为大写

    const beijingTime = new Intl.DateTimeFormat("en-CN", {
      ...options,
      timeZone: "Asia/Shanghai",
    })
      .format(new Date(isoString))
      .toUpperCase(); // 转换为大写

    const greenwichTime = new Intl.DateTimeFormat("en-GB", {
      ...options,
      timeZone: "GMT",
    })
      .format(new Date(isoString))
      .toUpperCase(); // 转换为大写

    return { localTime, easternTime, beijingTime, greenwichTime };
  };

  return (
    <div>
      <Header />
      <div className="TradingSignal-container ">
        <div className="TradingSignal-content mt-10 mb-4">
          <h1 className="text-[24px] text-mblue mb-[15px] w-[100%] flex justify-center items-center">
            {t("TradingSignal:SignalMarketplace")}
          </h1>
          {/* 显示 webhook 数据 */}

          <div className="TradingSignal-card  mx-4">
            <h1 className="content-title">
              {t("TradingSignal:realtimeTradingSignals")}
            </h1>
            {webhookData
              .slice()
              .reverse()
              .map((data, index) => {
                if (!data.ticker) {
                  // 如果 data.ticker 不存在，就跳过这个元素的渲染
                  return null;
                }

                return (
                  <div className="TradingSignal-card mb-[20px]" key={index}>
                    <table className="webhook-data-table">
                      <tbody>
                        <tr>
                          <td className="content-text">
                            <strong>{t("TradingSignal:stockCode")}:</strong>
                            {data.ticker}
                          </td>
                          <td className="content-text">
                            <strong>{t("TradingSignal:tradingAction")}:</strong>

                            <span
                              style={{
                                color:
                                  data.action === "buy" ? "#049981" : "#F85260",
                              }}
                            >
                              &nbsp;{data.action}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text">
                            <strong>{t("TradingSignal:tradeQuantity")}:</strong>{" "}
                            {data.quantity}
                          </td>
                          <td className="content-text">
                            <strong>{t("TradingSignal:signalPrice")}:</strong>{" "}
                            {data.price}
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text">
                            <strong>{t("TradingSignal:positionSize")}:</strong>{" "}
                            {data.position_size}
                          </td>
                          <td className="content-text">
                            <strong>{t("TradingSignal:fundName")}:</strong>{" "}
                            {data.message}
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text">
                            <strong>{t("TradingSignal:localTime")}:</strong>{" "}
                            {formatTimestamp(data.timestamp).localTime}
                          </td>
                          <td className="content-text">
                            <strong>{t("TradingSignal:easternTime")}:</strong>{" "}
                            {formatTimestamp(data.timestamp).easternTime}
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text">
                            <strong>{t("TradingSignal:beijingTime")}:</strong>
                            {formatTimestamp(data.timestamp).beijingTime}
                          </td>
                          <td className="content-text">
                            <strong>{t("TradingSignal:greenwichTime")}:</strong>{" "}
                            {formatTimestamp(data.timestamp).greenwichTime}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
          </div>

          {/*           <div className="TradingSignal-card">
              <h1 className="content-title">Trading Signals History</h1>
              {webhookData.map((data, index) => (
                <div key={index}>
                  <div
                    style={{
                      margin: "10px 0px",
                    }}
                    className="content-text"
                  >
                    {JSON.stringify(data, null, 2)}
                  </div>
                </div>
              ))}
            </div> */}
        </div>

        <style>{TradingSignalStyles}</style>
      </div>
      <Footer />
    </div>
  );
}

export default TradingSignal;
