import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./common/Header";
import { useTranslation } from "react-i18next";
import Footer from "./common/Footer";

function formatDate(dateString) {
  // 将 UTC 时间字符串解析为 Date 对象
  const date = new Date(dateString);

  const optionsDate = { year: "numeric", month: "long", day: "numeric" };
  const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: false };

  // 格式化日期和时间为本地时区
  const formattedDate = date.toLocaleDateString(undefined, optionsDate);
  const formattedTime = date.toLocaleTimeString(undefined, optionsTime);

  return `${formattedDate}, ${formattedTime}`;
}
function OrderDetails() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/order-details", {
          headers: {
            Authorization: `${token}`,
          },
        });
        //  console.log("API '/api/order-details' 的返回结果:", response.data); // Add a description in Chinese

        if (Array.isArray(response.data)) {
          const formattedOrders = response.data
            .map((order) => ({
              ...order,
              createdTime: formatDate(order.subscriptionPeriod),
            }))
            .sort(
              (a, b) =>
                new Date(b.subscriptionPeriod) - new Date(a.subscriptionPeriod)
            ); // 降序排序

          setOrders(formattedOrders);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, []);

  // 在渲染逻辑之前添加 console.log
  //console.log("Rendering orders:", orders);

  return (
    <div className="pb-[80px] ">
      <div className="min-h-[800px] bg-black text-white flex flex-col items-center  ">
        <Header />
        {orders.length > 0 ? (
          orders.map((order, index) => (
            <div className="mt-10 p-4 md:w-2/3 lg:w-1/2 xl:w-1/3 bg-[#111] rounded-lg border border-[#434651] shadow-lg">
              <h1 className="text-xl font-bold mb-4">
                {t("OrderDetails:title")}
              </h1>
              <div className="grid grid-cols-2 gap-2">
                <div className="text-base mb-2">
                  <strong>{t("OrderDetails:orderNumber")}</strong>{" "}
                  {order.orderNumber}
                </div>
                <div className="text-base mb-2">
                  <strong>{t("OrderDetails:subscriptionType")}</strong>{" "}
                  {order.subscriptionType}
                </div>
                <div className="text-base mb-2">
                  <strong>{t("OrderDetails:amount")}</strong> {order.amount}
                </div>
                <div className="text-base mb-2">
                  <strong>{t("OrderDetails:createdTime")}</strong>{" "}
                  {order.createdTime}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="h-[150px] mt-[300px] p-4 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 bg-[#111] rounded-lg border border-[#434651] shadow-lg flex justify-center items-center">
            <h1 className="text-xl font-bold mb-4">
              {t("OrderDetails:noOrder")}
            </h1>
          </div>
        )}
      </div>{" "}
      <Footer />
    </div>
  );
}

export default OrderDetails;
