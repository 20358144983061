import React from "react";
import { useTranslation } from "react-i18next";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Privacy Policy - 隐私政策 | New Horizon</title>
          <meta
            name="description"
            content="了解New Horizon金融科技公司的隐私政策。我们致力于保护您的隐私和个人信息。Learn about the privacy policy of New Horizon Fintech Company. We are committed to protecting your privacy and personal information."
          />
          <meta
            name="keywords"
            content="隐私政策, 个人信息保护, 金融科技, Privacy Policy, Personal Information Protection, Fintech, New Horizon"
          />
          <link rel="canonical" href="https://nhwt.tech/privacy-policy" />
        </Helmet>
        <Header />
        <div className="py-10 px-5 max-w-[800px] mx-auto leading-8 bg-black text-white pb-[80px]">
          <h1>{t("PrivacyPolicy:Header")}</h1>
          <p>{t("PrivacyPolicy:Introduction")}</p> <br />
          <h2 className="mt-8 mb-4 text-xl font-semibold text-indigo-300">
            📄 {t("PrivacyPolicy:InformationCollection")}
          </h2>
          <p>{t("PrivacyPolicy:InformationCollectionDetails")}</p> <br />
          <h2 className="mt-8 mb-4 text-xl font-semibold text-indigo-300">
            🔍 {t("PrivacyPolicy:UseOfInformation")}
          </h2>
          <p>{t("PrivacyPolicy:UseOfInformationDetails")}</p> <br />
          <h2 className="mt-8 mb-4 text-xl font-semibold text-indigo-300">
            🤝 {t("PrivacyPolicy:SharingOfInformation")}
          </h2>
          <p>{t("PrivacyPolicy:SharingOfInformationDetails")}</p> <br />
          <h2 className="mt-8 mb-4 text-xl font-semibold text-indigo-300">
            🔐 {t("PrivacyPolicy:DataProtection")}
          </h2>
          <p>{t("PrivacyPolicy:DataProtectionDetails")}</p> <br />
          <h2 className="mt-8 mb-4 text-xl font-semibold text-indigo-300">
            👤 {t("PrivacyPolicy:YourRights")}
          </h2>
          <p>{t("PrivacyPolicy:YourRightsDetails")}</p> <br />
          <h2 className="mt-8 mb-4 text-xl font-semibold text-indigo-300">
            🔄 {t("PrivacyPolicy:PolicyUpdates")}
          </h2>
          <p>{t("PrivacyPolicy:PolicyUpdatesDetails")}</p>
          {/* Add more sections as needed */}
        </div>

        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PrivacyPolicy;
