//PaypalButton.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const ButtonWrapper = ({ type, selectedPlan, paypalConfig }) => {
  //console.log("PaypalBtn接收到的 selectedPlan: ", selectedPlan);
  const [keyIndex, setKeyIndex] = useState(0); // 新增状态来控制重渲染

  useEffect(() => {
    // 当selectedPlan变化时，更新keyIndex以重新渲染PayPal按钮
    setKeyIndex((keyIndex) => keyIndex + 1);
  }, [selectedPlan]);

  // 在组件加载时从后台获取用户的邮箱地址
  const [userEmail, setUserEmail] = useState(""); // 用于存储用户的邮箱地址
  // 根据不同的selectedPlan值来确定不同的PayPal计划ID
  let planId = "";
  switch (selectedPlan) {
    case "basic":
      planId = paypalConfig.paypalPlanIdBasic;
      break;
    case "premium":
      planId = paypalConfig.paypalPlanIdPremium;
      break;
    case "basicYear":
      planId = paypalConfig.paypalPlanIdBasicYear;
      break;
    case "premiumYear":
      planId = paypalConfig.paypalPlanIdPremiumYear;
      break;
    case "free":
    default:
      planId = paypalConfig.paypalPlanIdBasic;
      break;
  }
  // console.log("PaypalBtn接收到的 selectedPlan: ", planId);
  useEffect(() => {
    const token = localStorage.getItem("token"); // 从本地存储中获取 token

    if (token) {
      if (!userEmail) {
        // 执行获取用户邮箱的逻辑
        axios
          .get("/getUserEmail", {
            headers: {
              Authorization: `${token}`,
            },
          })
          .then((response) => {
            setUserEmail(response.data.email);
            console.log("User Email:", response.data.email);
          })
          .catch((error) => {
            console.error("Error fetching user email:", error);
          });
      } else {
        console.log("User email already set");
      }
    } else {
      console.log("未登录");
    }
  }, [userEmail]); // 依赖数组是空的，所以这个效果只会在组件装载时运行一次

  const [{ options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, dispatch]); // 明确排除 options

  // 为 PayPalButtons 创建一个封装函数
  const createSubscription = useCallback(
    (data, actions) => {
      return actions.subscription
        .create({
          plan_id: planId,
          custom_id: userEmail, // 在这里添加 custom_id 字段
        })
        .then((orderId) => {
          // 其他处理逻辑
          return orderId;
        });
    },
    [planId, userEmail]
  ); // 确保添加 userEmail 作为依赖项

  return (
    <PayPalButtons
      key={keyIndex} // 使用keyIndex作为key
      createSubscription={createSubscription}
      style={{
        color: "silver",
        label: "subscribe",
      }}
    />
  );
};

export default function PaypalButton({ selectedPlan }) {
  const [paypalConfig, setPaypalConfig] = useState({
    paypalClientId: "",
    paypalPlanIdBasic: "",
    paypalPlanIdPremium: "",
    paypalPlanIdBasicYear: "",
    paypalPlanIdPremiumYear: "",
    paypalPlanIdFree: "",
  });

  const [isLoading, setIsLoading] = useState(true); // 新增加载状态

  useEffect(() => {
    axios
      .get("/api/config")
      .then((response) => {
        console.log("PayPal configuration data success"); // 打印响应数据
        setPaypalConfig(response.data);
        setIsLoading(false); // 更新加载状态
      })
      .catch((error) => {
        console.error("Error fetching PayPal configuration:", error);
        // 这里可以添加其他错误处理逻辑，例如更新状态或显示错误消息
      });
  }, []);

  // 确保所有必要的配置都已经设置好
  if (isLoading) {
    return (
      <button className="p-3 my-2.5 rounded border border-transparent w-full h-14 bg-[#2C2E2F] text-lg text-white cursor-pointer">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white inline-block"></div>
          <span className="ml-2">Loading Paypal...</span>
        </div>
      </button>
    ); // 显示加载状态
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: paypalConfig.paypalClientId, // 使用从后端获取的 PayPal 客户端 ID
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      <ButtonWrapper
        type="subscription"
        selectedPlan={selectedPlan}
        paypalConfig={paypalConfig}
      />
    </PayPalScriptProvider>
  );
}
