//Login.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Header from "./common/Header";
import backgroundImage from "../assets/loginSignin.mp4";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import GoogleSignInButton from "./common/firebase.js";
import ConfettiPop from "./common/ConfettiPop";
const VideoWithSkeleton = () => {
  // 假设我们使用一个状态来控制Skeleton的显示
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 假定视频在5秒后已足够加载，这是一个简化的假设
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative lg:block hidden  m-[10px] w-1/2 max-w-1/2 h-[calc(100vh-80px)]">
      {loading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 animate-pulse bg-[#111]  rounded-[24px]"></div>
      )}
      <video
        className="lg:block hidden rounded-[24px] h-[calc(100vh-80px)] object-cover"
        autoPlay
        loop
        muted
        onLoadedMetadata={() => setLoading(false)}
        alt="NewHorizon Tech Background Video"
        title="NewHorizon Tech Background Video"
      >
        <source src={backgroundImage} type="video/mp4" />
      </video>
    </div>
  );
};

function Login() {
  const { t } = useTranslation();
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [resendTimeout, setResendTimeout] = useState(0);
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const triggerConfetti = () => {
    setShowConfetti(true);
  };
  const navigate = useNavigate();
  const handleResendVerificationEmail = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/resend-verification-email",
        { email: loginInfo.email }
      );
      toast.success("Verification email resent. Please check your inbox.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setResendTimeout(20); // 设置倒计时为20秒
      const interval = setInterval(() => {
        setResendTimeout((prev) => prev - 1);
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
      }, 20000); // 20秒后清除计时器
    } catch (error) {
      toast.error("Failed to resend verification email.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // 开始加载
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/login",
        loginInfo
      );
      const { token, userId } = response.data; // 假设后端返回了用户ID
      // 存储 JWT 到 localStorage
      localStorage.setItem("jwt", token);
      localStorage.setItem("userId", userId); // 存储用户ID

      console.log("登录响应数据:", response.data);
      console.log("Token:", token);
      console.log("User ID:", userId); // 输出用户ID

      // 获取VIPtoken的值
      const vipTokenResponse = await axios.get(
        process.env.REACT_APP_API_URL + "/get-viptoken",
        {
          params: { email: loginInfo.email }, // 将用户的电子邮件地址作为查询参数包含在请求中
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const { VIPtoken } = vipTokenResponse.data;

      if (VIPtoken !== null) {
        // 存储VIPtoken在localStorage中
        localStorage.setItem("VIPtoken", VIPtoken);
      }

      const protectedResponse = await axios.get(
        process.env.REACT_APP_API_URL + "/some-protected-endpoint",
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      console.log("需要身份验证的请求响应:", protectedResponse.data);
      // 将令牌存储在本地存储中
      localStorage.setItem("token", token);
      // 登录成功后显示成功的弹窗提示
      toast.success("Login successful!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });

      setShowConfetti(true); // 显示庆祝动画
      setIsLoading(false); // 结束加载
      // 设置延时重新加载页面
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("登录错误：", error);

      if (error.response) {
        switch (error.response.status) {
          case 402:
            toast.warning("Email incorrect.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000, // 3秒后自动关闭
            });
            break;
          case 403:
            toast.error("Password incorrect.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000, // 3秒后自动关闭
            });
            break;
          case 409:
            setShowResendVerification(true); // 设置显示重新发送验证邮件的链接
            toast.error(
              "Your email is not verified. Please check your inbox.",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000, // 3秒后自动关闭
              }
            );
            break;
          case 429:
            toast.error(
              "Maximum number of 3 active sessions reached. Please log out from other devices.",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              }
            );
            break;
          default:
            console.error("错误状态：", error.response.status);
            console.error("错误数据：", error.response.data);
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>User Login - 用户登录 | New Horizon</title>
          <meta
            name="description"
            content="登录新长慧和科技，探索量化金融的前沿技术。Log in to New Horizon to explore the forefront of quantitative finance."
          />
          <meta
            name="keywords"
            content="用户登录, 量化金融, 新长慧和科技, User Login, Quantitative Finance, New Horizon"
          />
          <link rel="canonical" href="https://nhwt.tech/login" />
        </Helmet>

        <Header />
        <ConfettiPop shouldPop={showConfetti} />
        <div className="flex overflow-hidden h-screen">
          <VideoWithSkeleton />
          <div className="flex flex-col items-center justify-center p-5 w-[400px] max-w-[80%] mx-auto h-screen">
            <ToastContainer />
            <div className="w-full relative sm:top-[-90px] md:top-[-90px] lg:top-[-80px] ">
              <h1 className="text-center" style={{ color: "white" }}>
                {t("Login:login")}
              </h1>
              <form
                onSubmit={handleLoginSubmit}
                className="flex flex-col w-full"
              >
                <input
                  type="email"
                  name="email"
                  value={loginInfo.email}
                  onChange={handleLoginChange}
                  placeholder={t("Login:email")}
                  required
                  className="my-2.5 mx-0 p-2.5 rounded-md border border-gray-300 w-full box-border bg-[rgba(255,255,255,0.1)] text-white"
                />
                <input
                  type="password"
                  name="password"
                  value={loginInfo.password}
                  onChange={handleLoginChange}
                  placeholder={t("Login:password")}
                  required
                  className="my-2.5 mx-0 p-2.5 rounded-md border border-gray-300 w-full box-border bg-[rgba(255,255,255,0.1)] text-white"
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* Adjusted line */}
                  <Link to="/forgot-password" className="text-mblue underline">
                    {t("Login:forgotPassword")}
                  </Link>
                  <Link to="/register" className="text-mblue underline">
                    {t("Login:registerHere")}
                  </Link>
                </div>

                <button
                  type="submit"
                  className="p-2.5 my-2.5 rounded border border-transparent w-full box-border bg-mblue text-white cursor-pointer"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex justify-center">
                      <div className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white inline-block"></div>
                        <span className="ml-2 text-white">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    t("Login:login")
                  )}
                </button>

                {showResendVerification && (
                  <div className="text-center mt-2">
                    <button
                      type="button" // 明确指定按钮类型为 button
                      className="p-2.5  rounded border border-transparent w-full box-border text-white cursor-pointer"
                      onClick={handleResendVerificationEmail}
                      style={{
                        backgroundColor: resendTimeout > 0 ? "grey" : "#2962ff", // 根据倒计时状态改变按钮颜色
                      }}
                    >
                      {resendTimeout > 0
                        ? `${resendTimeout} seconds`
                        : "Resend Verification Email"}
                    </button>
                  </div>
                )}
              </form>
              <div className="flex items-center justify-center">
                <div className="flex-grow border-t border-gray-500"></div>
                <span className="flex-shrink mx-4 text-gray-500 text-lg">
                  or
                </span>
                <div className="flex-grow border-t border-gray-500"></div>
              </div>

              <GoogleSignInButton onGoogleLoginSuccess={triggerConfetti} />
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Login;
