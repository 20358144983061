import React from "react";
import { useTranslation } from "react-i18next"; // For internationalization
import { FaYoutube, FaDiscord, FaTelegramPlane } from "react-icons/fa"; // 引入FaDiscord
import MapModal from "./MapModal";
import tradingViewIcon from "../../assets/logo-square-light.png";
const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-black text-gray-300 p-5 flex sm:flex-col md:flex-col lg:flex-row lg:items-start justify-between items-center border-t border-customGray">
      <div className="order-2 lg:order-1 text-center lg:text-left lg:w-1/2 lg:ml-5 mb-5 lg:mb-0">
        <span>{t("Footer:CopyRight", { year: new Date().getFullYear() })}</span>
        <br />
        <a href="https://beian.miit.gov.cn/" target="blank">
          粤ICP备2024164727号
        </a>
        &nbsp; &nbsp;
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002002281"
          rel="noreferrer"
          target="_blank"
        >
          粤公网安备44030002002281
        </a>
        <br />
        <div className="flex items-center justify-center lg:justify-start space-x-4 lg:mt-[50px] sm:my-5 md:my-5">
          <a
            href="https://www.youtube.com/channel/UC754r6gJ86H6SU54gMmKznw"
            target="_blank"
            aria-label="Youtube_NewHorizon"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <FaYoutube className="mr-2 h-[30px] w-[30px]" />
          </a>
          <a
            href="https://discord.gg/RvNCUFY4tz"
            target="_blank"
            aria-label="Discord_NewHorizon"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <FaDiscord className="mr-2 h-[30px] w-[30px]" />
          </a>
          <a
            href="https://t.me/+c0Y9bvAURa5hNjdl"
            target="_blank"
            aria-label="Telegram_NewHorizon"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <FaTelegramPlane className="mr-2 h-[30px] w-[30px]" />
          </a>
          <a
            href="https://www.tradingview.com/u/NewHorizonWisdomTech/#published-scripts"
            target="_blank"
            aria-label="TradingView_NewHorizon"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <img
              src={tradingViewIcon}
              alt="TradingView"
              className="mr-2 h-[30px] w-[30px]"
            />
          </a>
        </div>
        <div className="lg:mt-[10px] ">
          <MapModal />
        </div>
      </div>
      <div className="order-1 lg:order-2 flex flex-col items-center lg:items-start lg:flex-col lg:w-1/6 lg:gap-[12px]  lg:justify-evenly mb-5 lg:mb-0">
        <p className="font-bold">{t("Footer:RESOURCES")}</p>

        <a
          href="/academic-citations"
          className="text-9  no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:AcademicCitations")}
        </a>
        <a href="/about-us" className="text-9  no-underline mx-0 my-2 lg:my-0 ">
          {t("Footer:AboutUs")}
        </a>
        <a
          href="/media-kit"
          className="text-9  no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:MediaKit")}
        </a>
      </div>
      <div className=" order-1 lg:order-2 flex flex-col items-center lg:items-start lg:flex-col lg:w-1/6 lg:gap-[12px] lg:justify-evenly  mb-5  lg:mb-0">
        <p className="font-bold">{t("Footer:LEGAL")}</p>
        <a
          href="/privacy-policy"
          className="text-9 no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:PrivacyPolicy")}
        </a>
        <a
          href="/terms-of-service"
          className="text-9  no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:TermsOfService")}
        </a>
      </div>

      <div className="order-1 lg:order-2 flex flex-col items-center lg:items-start lg:flex-col lg:w-1/6 lg:gap-[12px]  lg:justify-evenly mb-5 lg:mb-0">
        <p className="font-bold">{t("Footer:ContactSupport")}</p>
        <a
          href="/contact-us"
          className="text-9  no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:ContactUs")}
        </a>
        <a
          href="https://discord.gg/RvNCUFY4tz"
          className="text-9  no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:Support@Discord")}
        </a>
        <a
          href="https://t.me/+c0Y9bvAURa5hNjdl"
          className="text-9  no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:Support@Telegram")}
        </a>
        <a
          href="https://www.tradingview.com/u/NewHorizonWisdomTech/#published-scripts"
          className="text-9 no-underline mx-0 my-2 lg:my-0 "
        >
          {t("Footer:TradingView")}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
