import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Header from "./common/Header";

function ResetPasswordForm() {
  const { token } = useParams();

  const [resetInfo, setResetInfo] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setResetInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();

    // 检查新密码和确认密码是否一致
    if (resetInfo.newPassword !== resetInfo.confirmPassword) {
      // 显示密码不一致的错误提示
      toast.error(
        "Passwords do not match. Please make sure the passwords match.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // 3秒后自动关闭
        }
      );
      return; // 中止提交操作
    }

    try {
      // 将token添加到resetInfo对象中
      const dataToSend = {
        ...resetInfo,
        token: token,
      };

      // 发送POST请求以重置密码
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/reset-password",
        dataToSend
      );

      console.log("密码重置响应数据:", response.data);

      // 显示密码重置成功的消息
      toast.success("Password reset successful!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, // 3秒后自动关闭
      });

      // 可以在密码重置成功后执行其他操作，例如重定向到登录页面
    } catch (error) {
      console.error("密码重置错误：", error);

      if (error.response) {
        // 处理特定的错误状态码（根据你的后端定义）
        if (error.response.status === 401) {
          toast.error("Password reset failed. Unauthorized.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000, // 3秒后自动关闭
          });
        } else if (error.response.status === 422) {
          toast.warning("Password reset failed. Validation error.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000, // 3秒后自动关闭
          });
        } else {
          // 其他错误状态码
          console.error("错误状态：", error.response.status);
          console.error("错误数据：", error.response.data);
        }
      } else {
        // 处理其他错误（例如，网络问题）
      }
    }
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };

  const inputStyle = {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "white",
  };

  const buttonStyle = {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid transparent",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#2962ff",
    color: "white",
    cursor: "pointer",
  };

  const formContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
    width: "400px",
    maxWidth: "80%",
    margin: "0 auto",
    height: "100vh",
  };

  return (
    <div>
      <Header />
      <div style={formContainerStyle}>
        <ToastContainer />
        <h1 style={{ color: "white" }}>Reset Password</h1>
        <form onSubmit={handleResetSubmit} style={formStyle}>
          <input
            type="password"
            name="newPassword"
            value={resetInfo.newPassword}
            onChange={handleResetChange}
            placeholder="New Password"
            required
            style={inputStyle}
          />
          <input
            type="password"
            name="confirmPassword"
            value={resetInfo.confirmPassword}
            onChange={handleResetChange}
            placeholder="Confirm Password"
            required
            style={inputStyle}
          />
          <button type="submit" style={buttonStyle}>
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
