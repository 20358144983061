// Import necessary dependencies
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import language resources
import enDashbroad from "./en/Dashbroad.json";
import zhDashbroad from "./zh/Dashbroad.json";
import enMarketDataFundInfo from "./en/MarketDataFundInfo.json";
import zhMarketDataFundInfo from "./zh/MarketDataFundInfo.json";
import enAccountManagement from "./en/AccountManagement.json";
import zhAccountManagement from "./zh/AccountManagement.json";
import enQuantResearch from "./en/QuantResearch.json";
import zhQuantResearch from "./zh/QuantResearch.json";
import enAnalytics from "./en/Analytics.json";
import zhAnalytics from "./zh/Analytics.json";
import enNewsEducation from "./en/NewsEducation.json";
import zhNewsEducation from "./zh/NewsEducation.json";
import enInvestorSupport from "./en/InvestorSupport.json";
import zhInvestorSupport from "./zh/InvestorSupport.json";
import enTermsOfService from "./en/TermsOfService.json";
import zhTermsOfService from "./zh/TermsOfService.json";
import enPrivacyPolicy from "./en/PrivacyPolicy.json";
import zhPrivacyPolicy from "./zh/PrivacyPolicy.json";
import enHome from "./en/Home.json";
import zhHome from "./zh/Home.json";
import enRegister from "./en/Register.json";
import zhRegister from "./zh/Register.json";
import enHeader from "./en/Header.json";
import zhHeader from "./zh/Header.json";
import enFund from "./en/Fund.json";
import zhFund from "./zh/Fund.json";
import enTradingSignal from "./en/TradingSignal.json";
import zhTradingSignal from "./zh/TradingSignal.json";
import enFooter from "./en/Footer.json";
import zhFooter from "./zh/Footer.json";
import enLogin from "./en/Login.json";
import zhLogin from "./zh/Login.json";
import enDonationBox from "./en/DonationBox.json";
import zhDonationBox from "./zh/DonationBox.json";
import enContactUs from "./en/ContactUs.json";
import zhContactUs from "./zh/ContactUs.json";
import enSubscriptionPage from "./en/SubscriptionPage.json";
import zhSubscriptionPage from "./zh/SubscriptionPage.json";
import enFactorDetail from "./en/FactorDetail.json";
import zhFactorDetail from "./zh/FactorDetail.json";
import enOrderDetails from "./en/OrderDetails.json";
import zhOrderDetails from "./zh/OrderDetails.json";
import factorLibraryEn from "./en/FactorLibrary.json";
import factorLibraryZh from "./zh/FactorLibrary.json";
import PaymentSuccessEn from "./en/PaymentSuccessPage.json";
import PaymentSuccessZh from "./zh/PaymentSuccessPage.json";
import SportsFundPageEn from "./en/SportsFundPage.json";
import SportsFundPageZh from "./zh/SportsFundPage.json";
import ScienceEducationFundPageEn from "./en/ScienceEducationFundPage.json";
import ScienceEducationFundPageZh from "./zh/ScienceEducationFundPage.json";

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      dashbroad: enDashbroad,
      MarketDataFundInfo: enMarketDataFundInfo,
      AccountManagement: enAccountManagement,
      QuantResearch: enQuantResearch,
      Analytics: enAnalytics,
      NewsEducation: enNewsEducation,
      InvestorSupport: enInvestorSupport,
      OrderDetails: enOrderDetails,
      FactorDetail: enFactorDetail,
      SubscriptionPage: enSubscriptionPage,
      ContactUs: enContactUs,
      DonationBox: enDonationBox,
      Login: enLogin,
      Footer: enFooter,
      TradingSignal: enTradingSignal,
      Fund: enFund,
      Header: enHeader,
      Register: enRegister,
      TermsOfService: enTermsOfService,
      PrivacyPolicy: enPrivacyPolicy,
      Home: enHome,
      factorLibrary: factorLibraryEn,
      PaymentSuccess: PaymentSuccessEn,
      SportsFundPage: SportsFundPageEn,
      ScienceEducationFundPage: ScienceEducationFundPageEn,
    },

    zh: {
      dashbroad: zhDashbroad,
      MarketDataFundInfo: zhMarketDataFundInfo,
      AccountManagement: zhAccountManagement,
      QuantResearch: zhQuantResearch,
      Analytics: zhAnalytics,
      NewsEducation: zhNewsEducation,
      InvestorSupport: zhInvestorSupport,
      OrderDetails: zhOrderDetails,
      FactorDetail: zhFactorDetail,
      SubscriptionPage: zhSubscriptionPage,
      ContactUs: zhContactUs,
      DonationBox: zhDonationBox,
      Login: zhLogin,
      Register: zhRegister,
      Footer: zhFooter,
      TradingSignal: zhTradingSignal,
      Fund: zhFund,
      Header: zhHeader,
      TermsOfService: zhTermsOfService,
      PrivacyPolicy: zhPrivacyPolicy,
      Home: zhHome,
      factorLibrary: factorLibraryZh,
      PaymentSuccess: PaymentSuccessZh,
      SportsFundPage: SportsFundPageZh,
      ScienceEducationFundPage: ScienceEducationFundPageZh,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
