// Home.js
//We are pioneers and trailblazers. We fight for happiness. We transform our dreams into reality. Our dreams will become a company.
import React, { useState, useEffect, useRef } from "react";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  FaNetworkWired,
  FaChartBar,
  FaChrome,
  FaSignal,
  FaCreditCard,
  FaChartPie,
} from "react-icons/fa";
import chromeIcon from "../assets/logo/chrome.svg";
import NewHorizonImage from "../assets/newhorizon.jpeg";
import logo1 from "../assets/logo1.png";
import logo2 from "../assets/logo2.png";
import logo3 from "../assets/logo3.png";
import logo4 from "../assets/logo4.png";
import logo5 from "../assets/logo5.png";
import logo6 from "../assets/logo6.png";
import calculator from "../assets/calculator.png";
import file from "../assets/file.png";
import creditCard from "../assets/creditCard.png";
import discord from "../assets/discord.png";
import tag from "../assets/tag.png";
import telegram from "../assets/telegram.png";
import logo7 from "../assets/logo-square-light.png";
import GradientBg from "../assets/gradient.jpg";
import GradientMd from "../assets/gradientMd.jpg";
import Papa from "papaparse";
import Footer from "./common/Footer";
import Earth from "./common/Earth";
// 引入轮播图库
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from "react-intersection-observer";
import { Helmet, HelmetProvider } from "react-helmet-async";
//import { Helmet } from "react-helmet";
import ContentLoader from "react-content-loader";
import SetPasswordModal from "./common/SetPasswordModal";
import { AiOutlineClose } from "react-icons/ai";

const QuantResearchStyles = `

  .QuantResearch-container {
    font-family: 'Bloomberg Terminal', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  @media screen and (max-width: 768px) {
    .QuantResearch-content {
      grid-template-columns: repeat(1, 1fr); 
    }
  }

  .MarketDataFundInfo-card { 
    background: #111111; 
    border: 1px solid #434651;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    25% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    75% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .gradient-title {
    font-family: EuclidCircularSemibold, sans-serif;
    background: linear-gradient(90deg, #00bce6, #2962ff 50.31%, #d500f9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10vw;
    letter-spacing: -.02em;
    line-height: var(--ui-lib-typography-line-height);
    background-size: 200% 200%;
    animation: gradientAnimation 10s ease infinite;
  }
  
.title {
  font-family:EuclidCircularSemibold,sans-serif; 
  font-size: 100px;
  color: #000000; 
  background:#ffffff; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -.02em;
    line-height: var(--ui-lib-typography-line-height);
    margin-top: 100px;
    font-size: 10vw;
}
.title2 {
  font-family:EuclidCircularSemibold,sans-serif; 
  font-size: 100px;
  color: #000000;
  background:#ffffff; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -.02em;
    line-height: var(--ui-lib-typography-line-height);
    margin-top: 180px;
    z-index: 2;
    font-size: 7vw;
}

.title3 {
  font-family:EuclidCircularSemibold,sans-serif; 
  font-size: 100px; 
  color: #000000; 
  background:#ffffff; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -.02em;
    line-height: var(--ui-lib-typography-line-height);
    margin-top: 0px;
    z-index: 2;
    font-size: 7vw;
}

.title4 {
  font-family:EuclidCircularSemibold,sans-serif;
  font-size: 100px; 
  color: #000000; 
  background:#ffffff; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -.02em;
    line-height: var(--ui-lib-typography-line-height);
    margin-top: 100px;
    z-index: 2;
    margin-bottom: 120px;
    font-size: 7vw;
}

.noise-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  mix-blend-mode: soft-light;
  background: url("data:image/svg+xml,%3Csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter' color-interpolation-filters='sRGB'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='1' numOctaves='10' stitchTiles='stitch' result='noise' /%3E%3CfeColorMatrix type='matrix' values='1 0.3333 0.3333  0 0 0 1 0.3333 0.3333  0 0 0 1 0.3333 0.3333  0 0 0 1 0' in='noise' result='coloredNoise' /%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'  /%3E%3C/svg%3E") center center / cover;
  opacity: 100%;
}

`;

const ImageWithSkeleton = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <div className="relative top-[-60px] w-full h-auto">
      {!imageLoaded && (
        <ContentLoader
          speed={2}
          width="100%"
          height="100%"
          viewBox="0 0 700 400"
          backgroundColor="#000000"
          foregroundColor="#111111"
          preserveAspectRatio="xMidYMid slice"
        >
          <rect x="2%" y="0" rx="10" ry="10" width="96%" height="100%" />
        </ContentLoader>
      )}

      <img
        src={src}
        alt={alt}
        className={`w-full ${
          imageLoaded ? "block" : "hidden"
        } object-contain max-w-none`}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

// AnnouncementBanner 定义
const announcementVersion = "20240424"; // 例如使用日期或递增的版本号
const AnnouncementBanner = () => {
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const storedVersion = localStorage.getItem("announcementVersion");
    const isAnnouncementClosed = storedVersion === announcementVersion;
    console.log(
      "Stored version:",
      storedVersion,
      "Current version:",
      announcementVersion
    );
    console.log("Is announcement already closed?", isAnnouncementClosed);
    setShowAnnouncement(!isAnnouncementClosed);
    setIsLoaded(true);
  }, []);

  console.log("Is Loaded:", isLoaded, "Show Announcement:", showAnnouncement);

  if (!isLoaded || !showAnnouncement) {
    console.log(
      "Component will not render because it is not loaded or should not show."
    );
    return null;
  }

  const handleCloseAnnouncement = () => {
    console.log("Closing announcement");
    localStorage.setItem("announcementVersion", announcementVersion);
    setShowAnnouncement(false);
  };

  return (
    <div className="flex justify-center">
      <div className="w-[80vw] bg-blue-200 mt-[20px] text-mblue border-mblue border-solid border text-center py-3 px-4 relative rounded-lg shadow-lg">
        <p className="mr-[10px] text-left">
          We've released version 2.3.6 which fixes an issue where the optimizer
          could not run if decimals were used as default parameters for
          skipping. Thanks for your support! Please give us a 5-star 🌟 review
          on the{" "}
          <a
            className="underline"
            href="https://chromewebstore.google.com/detail/newhorizon-optimizer/icjbokeihkhdilfokigedgpoifnagkjo"
            rel="noreferrer"
            target="_blank"
          >
            Chrome store!
          </a>
        </p>
        <button
          onClick={handleCloseAnnouncement}
          className="absolute top-2 right-2 p-1"
        >
          <AiOutlineClose />
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const [profit, setProfit] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [lightHaloPosition, setLightHaloPosition] = useState({
    x: 0,
    y: 0,
    visible: false,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("./data/2024TQQQ.csv");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const csvString = await response.text();
        const parsedData = Papa.parse(csvString, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
        //  console.log("Parsed Data:", parsedData);
        if (parsedData.errors.length > 0) {
          console.error("Parsing Errors:", parsedData.errors);
          return;
        }

        if (parsedData.data && parsedData.data.length > 0) {
          parsedData.data.sort(
            (a, b) => new Date(b["Date/Time"]) - new Date(a["Date/Time"])
          );
          const latestData = parsedData.data[0];
          const latestProfitPercent = latestData["Cum. Profit %"];
          setProfit(latestProfitPercent);
          const latestProfitUSD = latestData["Cum. Profit USD"];
          //  console.log("最新latestProfitUSD:", latestProfitUSD); // 在这里打印latestProfitUSD
          // 计算最新'Cum. Profit USD'值与'2023-01-09T20:30:00'值之间的差值
          const cutoffDate = new Date("2024-01-16T17:30:00");
          const cutoffData = parsedData.data.find(
            (row) => new Date(row["Date/Time"]) <= cutoffDate
          );
          const cutoffProfitUSD = cutoffData
            ? cutoffData["Cum. Profit USD"]
            : 0;
          // console.log("起始点USD", cutoffProfitUSD);
          //  console.log("最新latestProfitUSD:", latestProfitUSD);
          const sumProfit = latestProfitUSD - cutoffProfitUSD;
          // console.log("基金增长USD", sumProfit);
          const adjustedTotalProfit = (sumProfit / cutoffProfitUSD + 1).toFixed(
            2
          );
          setTotalProfit(adjustedTotalProfit);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching or parsing data:", error);
      }
    }
    fetchData();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      // 如果没有token，直接返回，不执行后续的检查密码操作
      return;
    }

    const checkPassword = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/api/check-password",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (!data.hasPassword) {
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error("请求出错:", error);
      }
    };

    checkPassword();
  }, []);

  const { t } = useTranslation();
  const partners = [
    { name: t("Home:corosHighSpeed"), logo: logo1 },
    { name: t("Home:interactiveBrokers"), logo: logo2 },
    { name: t("Home:standardCharteredHK"), logo: logo3 },
    { name: t("Home:hongKongSFC"), logo: logo4 },
    { name: t("Home:ultimaMarkets"), logo: logo5 },
    { name: t("Home:google"), logo: logo6 },
    { name: t("Home:TradingView"), logo: logo7 },
  ];
  // 轮播图设置
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  let navigate = useNavigate();
  function goToFactorLibrary() {
    navigate("/FactorLibrary");
  }
  function goToChart() {
    navigate("/fund");
  }
  function goToChartAnalysis() {
    navigate("/chart");
  }

  function goDownload() {
    window.open(
      "https://chromewebstore.google.com/detail/newhorizon-optimizer/icjbokeihkhdilfokigedgpoifnagkjo",
      "_blank"
    );
  }
  const percentageTextStyle = {
    color: profit.toString().includes("-") ? "#f23645" : "#089981",
  };
  const fadeIn = {
    initial: { opacity: 0, y: 30 }, // 初始状态：完全透明且稍微向下移动
    animate: { opacity: 1, y: 0 }, // 最终状态：完全不透明且在原位
    transition: { duration: 0.8 },
  };
  const buttonFadeInUp = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: 1, y: 0 },
    transition: {
      duration: 1,
      delay: 0.3,
    },
  };
  const { ref: refElement1, inView: inViewElement1 } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: refElement2, inView: inViewElement2 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: refElement3, inView: inViewElement3 } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: refElement4, inView: inViewElement4 } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  function openLink(url) {
    window.open(url, "_blank");
  }

  const containerRef = useRef(null);
  const handleMouseMove = (event) => {
    if (containerRef.current) {
      const bounds = containerRef.current.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      if (y >= 100 && y <= 1450) {
        setLightHaloPosition({ x, y, visible: true });
      } else {
        setLightHaloPosition({ ...lightHaloPosition, visible: false });
      }
    }
  };
  // 动态计算光晕的背景渐变位置
  const lightHaloBackgroundPosition = lightHaloPosition.visible
    ? `${(lightHaloPosition.x / containerRef.current.offsetWidth) * 100}% 50%`
    : "0% 50%";
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
  const safariClass = isSafari() ? "hidden" : "";

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>
            New Horizon - AI-Driven Quantitative Finance | AI驱动的量化金融
          </title>
          <meta
            name="description"
            content="新长慧和科技AI提供先进的AI驱动量震荡化研究工具，帮助投资者制定明智的投资决策和策略，探索金融市场的新机遇。 | New Horizon AI offers advanced AI-driven quantitative research tools to help investors make informed investment decisions and strategies, exploring new opportunities in the financial markets."
          />
          <meta
            name="keywords"
            content="量化研究, AI投资, 金融技术, 市场分析, 新长慧和科技AI, Quantitative Research, AI Investment, Financial Technology, Market Analysis, New Horizon AI"
          />
          {/* Open Graph meta tags */}
          <meta
            property="og:title"
            content="New Horizon | AI-Driven Quantitative Finance"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://nhwt.tech/" />
          <link rel="canonical" href="https://nhwt.tech/" />
        </Helmet>
        <Header />
        <AnnouncementBanner />
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative w-[420px] px-[20px] py-[60px] rounded-lg bg-[#111111] shadow-lg">
              <button
                onClick={() => {
                  const isConfirmed = window.confirm(
                    "You can set your password later. Are you sure you want to close this window?"
                  );
                  if (isConfirmed) {
                    setIsModalOpen(false);
                  }
                }}
                className="absolute top-3 right-3 text-white"
              >
                <AiOutlineClose className="text-gray-400" size={24} />
              </button>
              {isModalOpen && (
                <SetPasswordModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  isHomePage={true}
                />
              )}
            </div>
          </div>
        )}
        <div
          ref={containerRef}
          className="QuantResearch-container flex flex-col items-center h-full bg-black text-[#d1d1d1]"
          onMouseMove={handleMouseMove}
          onMouseLeave={() =>
            setLightHaloPosition({ ...lightHaloPosition, visible: false })
          }
          style={{ position: "relative" }}
        >
          <div
            className={`light-halo ${safariClass}`}
            style={{
              position: "absolute",
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background:
                "linear-gradient(90deg, #00bce6, #2962ff 50.31%, #d500f9)",
              backgroundSize: "200% 200%",
              backgroundPosition: lightHaloBackgroundPosition,
              filter: "blur(50px)",
              pointerEvents: "none",
              transform: "translate(-50%, -50%)",
              transition: "opacity 0.5s, visibility 0.5s",
              opacity: lightHaloPosition.visible ? 0.5 : 0,
              visibility: lightHaloPosition.visible ? "visible" : "hidden",
              left: `${lightHaloPosition.x}px`,
              top: `${lightHaloPosition.y}px`,
              zIndex: 1,
              animation: "gradientAnimation 10s ease infinite",
            }}
          />
          <div>
            <motion.div
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
              className="flex flex-col items-center gap-0 "
            >
              <div className="title z-[3]">A New Era of </div>
              <div className="gradient-title mb-[80px] font-semibold text-white  z-[3]">
                AI-Driven Finance
              </div>
            </motion.div>
            <motion.div
              initial={buttonFadeInUp.initial}
              animate={buttonFadeInUp.animate}
              transition={buttonFadeInUp.transition}
              className=" flex  mb-[100px] sm:flex-col sm:justify-center md:justify-center lg:justify-start items-center gap-[20px]"
            >
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-G p-[2px] rounded-full"
              >
                <motion.button
                  className=" sm:py-[10px]  w-[280px] sm:px-[25px] py-[15px] pl-[30px] pr-[40px] bg-[#1e222d] text-white rounded-full border-none cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://chromewebstore.google.com/detail/newhorizon-optimizer/icjbokeihkhdilfokigedgpoifnagkjo"
                    );
                  }}
                >
                  <div className="flex items-center">
                    <img
                      src={chromeIcon}
                      alt="Chrome Icon"
                      className="w-[40px] h-[40px] mr-4"
                    />
                    <div className="text-left">
                      <div className="text-gray-400 whitespace-nowrap">
                        {t("Home:getItToday")}
                      </div>
                      <div className="whitespace-nowrap mt-[6px]">
                        {t("Header:TradingviewOptimizer")}
                      </div>
                    </div>
                  </div>
                </motion.button>
              </motion.div>
              <motion.button
                whileHover={{ scale: 1.02 }}
                className="flex flex-col items-center w-[280px] sm:py-[10px] py-[15px] bg-[#1e222d] text-white rounded-full border-none cursor-pointer"
                onClick={goToChart}
              >
                <div className="flex flex-col items-start">
                  <div className="flex items-center space-x-2">
                    <div className="whitespace-nowrap">{t("Home:NAV")}</div>
                    <div>{totalProfit}</div>
                  </div>
                  <div className="flex items-center space-x-2 mt-[6px]">
                    <div className="whitespace-nowrap">
                      {t("Home:LatestChange")}
                    </div>
                    <div style={percentageTextStyle}>{profit}%</div>
                  </div>
                </div>
              </motion.button>
            </motion.div>
          </div>
          <motion.div
            ref={refElement1}
            initial={fadeIn.initial}
            animate={inViewElement1 ? fadeIn.animate : fadeIn.initial}
            transition={fadeIn.transition}
            className="QuantResearch-content bg-cover bg-center w-full flex items-center justify-center"
            style={{
              backgroundImage:
                window.innerWidth <= 575
                  ? `url(${GradientMd})`
                  : `url(${GradientBg})`,
              paddingTop: `${
                window.innerWidth <= 575
                  ? (1200 / 540) * 12
                  : window.innerWidth <= 768
                  ? (860 / 2048) * 0
                  : (860 / 2048) * 25
              }%`,
              paddingBottom: `${
                window.innerWidth <= 575
                  ? (1200 / 540) * 12
                  : window.innerWidth <= 768
                  ? (860 / 2048) * 0
                  : (860 / 2048) * 25
              }%`,
            }}
          >
            <div className="w-[85%] max-w-1200 mx-auto grid md:grid-cols-3 lg:grid-cols-3 gap-[20px]">
              <motion.div
                whileHover={{ scale: 1.05, zIndex: 3 }}
                whileTap={{ scale: 0.95 }}
                onClick={goDownload}
                className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] cursor-pointer relative"
              >
                <div className="mb-[15px] flex items-center justify-start">
                  <FaChrome
                    className="mb-4 text-mblue"
                    size={24}
                    style={{ marginRight: "10px", paddingTop: "4px" }}
                  />
                  <h1 className="text-[22px] text-mblue mb-[15px]">
                    {t("QuantResearch:optiToolsTitle")}
                  </h1>
                </div>
                <p className="text-[16px] mb-[10px] leading-[1.6]">
                  {t("QuantResearch:optiToolsDesc")}
                </p>
                <div className="absolute top-[-20px] right-[-12px] transform rotate-[45]">
                  <svg
                    width="40"
                    height="40"
                    viewBox="-1 -1 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <linearGradient
                        id="gradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor="#00bce6" />
                        <stop offset="50.31%" stopColor="#2962ff" />
                        <stop offset="100%" stopColor="#d500f9" />
                      </linearGradient>
                    </defs>

                    <path
                      d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z"
                      fill="#222222"
                    />
                    <path
                      d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z"
                      fill="none"
                      stroke="url(#gradient)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </svg>
                </div>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05, zIndex: 3 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate("/subscribe")}
                className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] cursor-pointer"
              >
                <div className="mb-[15px] flex items-center justify-start">
                  <FaCreditCard
                    className="mb-4 mr-[10px] pt-[4px] text-mblue"
                    size={24}
                  />
                  <h1 className="text-[22px] text-mblue mb-[15px]">
                    {t("AccountManagement:Sub")}
                  </h1>
                </div>
                <p className="text-[16px] mb-[10px] leading-[1.6]">
                  {t("AccountManagement:SubDesc")}
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05, zIndex: 3 }}
                whileTap={{ scale: 0.95 }}
                onClick={goToFactorLibrary}
                className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] cursor-pointer"
              >
                <div
                  className="mb-[15px]"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <FaNetworkWired
                    className="mb-4 mr-[10px] pt-[4px] text-mblue"
                    size={24}
                  />
                  <h1 className="text-mh2 text-mblue mb-[15px]">
                    {t("QuantResearch:modLibTitle")}
                  </h1>
                </div>
                <p className="text-t mb-[10px] leading-[1.6]">
                  {t("QuantResearch:modLibDesc")}
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05, zIndex: 3 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate("/trading-signal")}
                className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] cursor-pointer "
              >
                <div
                  className="mb-[15px]"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <FaSignal
                    className="mb-4 text-mblue"
                    size={24}
                    style={{ marginRight: "10px", paddingTop: "4px" }}
                  />
                  <h1 className="text-[22px] text-mblue mb-[15px]">
                    {t("Analytics:signalsTitle")}
                  </h1>
                </div>
                <p className="text-[16px] mb-[10px] leading-[1.6]">
                  {t("Analytics:signalsDescription")}
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05, zIndex: 3 }}
                whileTap={{ scale: 0.95 }}
                className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] cursor-pointer"
                onClick={goToChart}
              >
                <div className="mb-[15px] flex items-center justify-start">
                  <FaChartBar
                    className="mb-4 text-mblue"
                    size={24}
                    style={{ marginRight: "10px", paddingTop: "4px" }}
                  />
                  <h1 className="text-mh2 text-mblue mb-[15px]">
                    {t("MarketDataFundInfo:fundOverviewTitle")}
                  </h1>
                </div>
                <p className="text-mt mb-[10px] leading-[1.6]">
                  {t("MarketDataFundInfo:fundOverviewText")}
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05, zIndex: 3 }}
                whileTap={{ scale: 0.95 }}
                className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] cursor-pointer"
                onClick={goToChartAnalysis}
              >
                <div className="mb-[15px] flex items-center justify-start">
                  <FaChartPie
                    className="mb-4 text-mblue"
                    size={24}
                    style={{ marginRight: "10px", paddingTop: "4px" }}
                  />
                  <h1 className="text-mh2 text-mblue mb-[15px]">
                    {t("MarketDataFundInfo:ChartAnalysisTitle")}
                  </h1>
                </div>
                <p className="text-mt mb-[10px] leading-[1.6]">
                  {t("MarketDataFundInfo:ChartAnalysisText")}
                </p>
              </motion.div>
            </div>
          </motion.div>
          <Earth />
          <motion.div
            ref={refElement2}
            initial={fadeIn.initial}
            animate={inViewElement2 ? fadeIn.animate : fadeIn.initial}
            transition={fadeIn.transition}
            className="xl:mt-[-700px] lg:mt-[-750px] md:mt-[-600px] sm:mt-[-800px] sm:w-full text-center z-[3]"
          >
            <div className="title2">Newhorizon AI</div>
            <div className="title3">Philanthropy Fund</div>
          </motion.div>
          <motion.div
            ref={refElement3}
            initial={fadeIn.initial}
            animate={inViewElement3 ? fadeIn.animate : fadeIn.initial}
            transition={fadeIn.transition}
            className="w-[85%] grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  sm:t-[0px] md:t-[80px] lg:t-[80px]  grid grid-cols-1 md:grid-cols-2 gap-10 mt-[100px] max-w-6xl mx-auto relative z-20"
          >
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => openLink("/sports-fund")}
              className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] bg-[##111111]  opacity-75 relative justify-between flex h-[auto] items-center"
            >
              <h1 className="text-[22px] text-[#2962ff] mb-[15px] text-white">
                {t("Home:sportsFundTitle")}
              </h1>
              <p className="text-[16px] mb-[10px] leading-[1.6]">
                {t("Home:sportsFundDescription")}
              </p>
              <button className="text-white w-[100%] hover:border hover:border-[#434651] py-2 rounded-md">
                {t("Home:Donation")}
              </button>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => openLink("/science-education-fund")}
              className="bg-[#111111]  border border-[#434651] p-[20px] rounded-[8px] shadow-md flex flex-col z-[0] bg-[##111111] opacity-75 relative justify-between flex h-[auto] items-center"
            >
              <h1 className="text-[22px] text-[#2962ff] mb-[15px] text-white">
                {t("Home:scienceEducationFundTitle")}
              </h1>
              <p className="text-[16px] mb-[10px] leading-[1.6]">
                {t("Home:scienceEducationFundDescription")}
              </p>
              <button className="text-white w-[100%] hover:border hover:border-[#434651] py-2 rounded-md">
                {t("Home:Donation")}
              </button>
            </motion.div>
          </motion.div>

          <ImageWithSkeleton
            src={NewHorizonImage}
            alt="新长慧和科技，阳光洒在地平线上，New Horizon, Sunrise over the Horizon"
          />
          <style>{QuantResearchStyles}</style>
        </div>
        <div className="mb-0 relative -top-[120px] h-auto overflow-hidden">
          <div className="flex justify-center items-center">
            <motion.div
              ref={refElement4}
              initial={fadeIn.initial}
              animate={inViewElement4 ? fadeIn.animate : fadeIn.initial}
              transition={fadeIn.transition}
              className="title4"
            >
              Global Strategic Partners
            </motion.div>
          </div>
          <Slider {...settings}>
            {partners.map((partner, index) => (
              <div
                key={index}
                className="text-[#d1d4dc] font-[600] text-[18px] p-[10px] items-center justify-center text-center flex flex-col items-center"
              >
                <img
                  src={partner.logo}
                  alt={`New Horizon ${partner.name}`}
                  className="w-[50px] h-[50px] block mx-auto my-2.5"
                />
                <div>{partner.name}</div>
              </div>
            ))}
          </Slider>
          {/*  参考网站 https://fffuel.co/ffflux/ */}
          <div className="flex flex-col-reverse justify-center inset-0 bg-gradient-to-br from-purple-500 via-blue-500 to-cyan-500 items-center mt-[160px] w-[85%] max-w-1200 sm:h-[320px] h-[400px] mx-auto rounded-[1.25rem] overflow-hidden relative">
            <div className="noise-layer" />
            <div className="font-EuclidCircularSemibold text-[90px] sm:text-[10vw] text-white font-normal font-semibold z-[1] absolute  lg:top-[18%] md:top-[20%] sm:top-[22%]  ">
              Join us Now
            </div>
            <div className="flex flex-row items-center space-x-4 absolute lg:top-[64%] md:top-[60%] sm:top-[56%] z-[3]">
              <a
                href="https://discord.gg/RvNCUFY4tz"
                className="text-9 font-bold text-white no-underline mx-0 my-2 lg:my-0  border-[3px] border-solid border-white rounded-full px-6 py-2"
              >
                Discord
              </a>
              <a
                href="https://t.me/+c0Y9bvAURa5hNjdl"
                className="text-9 font-bold text-white no-underline mx-0 my-2 lg:my-0  border-[3px] border-solid border-white rounded-full px-6 py-2"
              >
                Telegram
              </a>
            </div>
            <div className="flex justify-center items-center w-full h-full  relative ">
              <img
                src={calculator}
                alt="Calculator"
                className="w-[22vw] h-[22vw]  max-w-[80px] max-h-[80px] absolute  sm:right-[1%] sm:bottom-[1%] right-[12%] bottom-[10%]"
              />
              <img
                src={file}
                alt="File"
                className="w-[30vw] h-[30vw] max-w-[100px] max-h-[100px] absolute right-[2%] top-[-2%]"
              />
              <img
                src={telegram}
                alt="Telegram"
                className="w-[26vw] h-[26vw] sm:w-[16vw] sm:h-[16vw] max-w-[100px] max-h-[100px] absolute right-[16%]  sm:right-[6%]  top-[22%] sm:top-[36%]"
              />
              <img
                src={creditCard}
                alt="Credit Card"
                className="w-[28vw] h-[28vw] max-w-[120px] max-h-[120px] absolute left-[-2%] bottom-[-2%]"
              />
              <img
                src={discord}
                alt="Discord"
                className="w-[22vw] h-[22vw] sm:w-[20vw] sm:h-[20vw] max-w-[120px] max-h-[120px] absolute left-[16%] sm:left-[6%] top-[36%] "
              />
              <img
                src={tag}
                alt="Tag"
                className="w-[20vw] h-[20vw] max-w-[120px] max-h-[120px] absolute left-[6%] top-[-10%]"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </HelmetProvider>
  );
};
export default Home;
