// App.js
import React, { useState, useEffect } from "react";
import ResetPasswordForm from "./components/ResetPasswordForm";
import SetPasswordForm from "./components/SetPasswordForm";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Register from "./components/Register";
import Chart from "./components/Chart";
import Login from "./components/Login";
import Fund from "./components/Fund";
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import Dashboard from "./components/Dashboard";
import MarketDataFundInfo from "./components/MarketDataFundInfo";
import Analytics from "./components/Analytics";
import QuantResearch from "./components/QuantResearch";
import NewsEducation from "./components/NewsEducation";
import AccountManagement from "./components/AccountManagement";
import InvestorSupport from "./components/InvestorSupport";
import SubscriptionPage from "./components/SubscriptionPage";
import NotFound from "./components/NotFound";
import FactorLibrary from "./components/FactorLibrary";
import ForgotPassword from "./components/ForgotPassword";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import TradingSignal from "./components/TradingSignal";
import FactorDetail from "./components/FactorDetail";
import OrderDetails from "./components/OrderDetails";
import AccountInfoPage from "./components/AccountInfoPage";
import PaymentSuccessPage from "./components/PaymentSuccessPage";
import SportsFundPage from "./components/SportsFundPage";
import ScienceEducationFundPage from "./components/ScienceEducationFundPage";
import { LogoutRouteHandler } from "./components/common/LogoutButton";
import MaintenancePage from "./components/MaintenancePage";
import VerifyEmail from "./components/VerifyEmail";
import MediaKit from "./components/MediaKit";
import AboutUs from "./components/AboutUs";
import AcademicCitations from "./components/AcademicCitations";
import NotificationSender from "./components/NotificationSender";
import "react-toastify/dist/ReactToastify.css";

const isUnderMaintenance = false; // 根据需要设置这个标志,true是启动维护模式

function App() {
  const factorNumbers = Array.from({ length: 7 }, (_, index) => index + 1);

  const [isAuthenticated /* setIsAuthenticated */] = useState(
    !!localStorage.getItem("token")
  );

  const handleLogout = () => {
    // 清除本地存储的认证信息或执行其他注销逻辑
    localStorage.removeItem("token");
    // 可以添加更多的注销逻辑，如更新应用状态等
  };

  if (isUnderMaintenance) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      </Router>
    );
  }
  return (
    <Router>
      <div>
        <ToastContainer />
        <Routes>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/media-kit" element={<MediaKit />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/fund" element={<Fund />} />
          <Route
            path="/accountmanagement"
            element={
              isAuthenticated ? (
                <AccountManagement />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/trading-signal"
            element={
              isAuthenticated ? (
                <TradingSignal />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/order-details"
            element={
              isAuthenticated ? (
                <OrderDetails />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/chart"
            element={
              isAuthenticated ? <Chart /> : <Navigate to="/login" replace />
            }
          />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/MarketDataFundInfo" element={<MarketDataFundInfo />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/QuantResearch" element={<QuantResearch />} />
          <Route path="/NewsEducation" element={<NewsEducation />} />
          <Route path="/InvestorSupport" element={<InvestorSupport />} />
          <Route path="/subscribe" element={<SubscriptionPage />} />
          <Route path="/factorLibrary" element={<FactorLibrary />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/reset" element={<ResetPasswordForm />} />
          <Route path="/set-password" element={<SetPasswordForm />} />
          <Route path="/account-info" element={<AccountInfoPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          <Route path="/sports-fund" element={<SportsFundPage />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/academic-citations" element={<AcademicCitations />} />
          <Route
            path="/logout"
            element={<LogoutRouteHandler onLogout={handleLogout} />}
          />
          <Route
            path="/science-education-fund"
            element={<ScienceEducationFundPage />}
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordForm />}
          />
          <Route path="/notify" element={<NotificationSender />} />
          <Route path="*" element={<NotFound />} />
          {factorNumbers.map((factorNumber) => (
            <Route
              key={factorNumber}
              path={`/factor/${factorNumber}`}
              element={<FactorDetail factorNumber={factorNumber} />} // 将因子编号作为 props 传递给 FactorDetail 组件
            />
          ))}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
