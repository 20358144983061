import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet";
import TypewriterComponent from "typewriter-effect";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>About Us | New Horizon</title>
        <meta
          name="description"
          content="Discover NewHorizon WisdomTech Group Limited, a leader in fintech innovation. Learn about our vision, mission, and cutting-edge financial solutions powered by AI and cloud computing."
        />
        <meta
          name="keywords"
          content="NewHorizon WisdomTech, Fintech Innovation, Financial Technology, AI Integration, Cloud Computing, Quantitative Analysis, Fund Management, Trading Analytics, 关于我们, 新长慧和"
        />

        {/* Open Graph meta tags */}
        <meta property="og:title" content="About Us | New Horizon" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nhwt.tech/about-us" />
        <link rel="canonical" href="https://nhwt.tech/about-us" />
      </Helmet>
      <Header />
      <div className="bg-black text-white pt-10 pb-[80px] leading-8 text-lg max-w-4xl m-auto min-h-[800px]">
        <div className="text-white font-bold py-36 text-center space-y-5">
          <div className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl space-y-5 font-extrabold">
            <h1>Welcome to NewHorizon</h1>
            <div className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 min-h-[200px]">
              <TypewriterComponent
                options={{
                  strings: [
                    "A fintech pioneer",
                    "Blending expertise and innovation",
                    "Creating intelligent financial solutions",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </div>
        </div>
        <h1 className="text-2xl mb-3">About Us</h1>
        <blockquote className="pl-4 border-l  border-gray-300 italic text-lg mb-4 text-gray-300">
          “we hold these truths to be self-evident: all people are created equal
          and endowed with the inalienable right to pursue happiness.” At
          NewHorizon WisdomTech Group Limited, guided by this belief, we pursue
          not only financial success but also commit to upholding the principles
          of the rule of law and fairness in our accountability to our investors
          and shareholders.
        </blockquote>
        <br />
        <p>
          Welcome to <strong>NewHorizon WisdomTech Group Limited</strong>, a
          pioneering force in the fintech industry. At NewHorizon, we blend
          innovation with expertise to craft intelligent financial solutions for
          a digital world.
        </p>
        <h2 className="text-xl text-bold mt-6 mb-4">
          Global Presence for Strategic Advantage
        </h2>
        <p>
          NewHorizon WisdomTech Group Limited boasts a strategic global presence
          with its headquarters in Hong Kong, and key offices in Singapore and
          the United States. Each location is chosen for its unique position in
          the global financial landscape, serving not only as operational hubs
          but also as centers of innovation and gateways to diverse markets.
        </p>
        <ul>
          <li className="mt-6 mb-4">
            <strong>Hong Kong</strong>: Our global headquarters, situated in the
            dynamic heart of Asia, acts as a central hub for financial
            innovation, bridging East and West with its robust financial
            infrastructure.
          </li>
          <li className="mt-6 mb-4">
            <strong>Singapore</strong>: At the crossroads of global trade, our
            Singapore office leverages its strategic position to foster
            innovation and tap into the rich markets of Southeast Asia.
          </li>
          <li className="mt-6 mb-4">
            <strong>United States, New Jersey</strong>: Our expansion into the
            United States with an office in New Jersey signifies our commitment
            to global growth, tapping into the Americas' vibrant financial
            ecosystem.
          </li>
        </ul>
        <h2 className="text-xl mt-6 text-bold mb-4">Our Vision</h2>
        <p>
          We aim to redefine the landscape of financial technology by infusing
          traditional finance with cutting-edge AI and cloud computing
          technologies. Our vision is to be at the forefront of fintech
          innovation, creating sustainable and intelligent solutions for global
          financial challenges.
        </p>
        <h2 className="text-xl text-bold  mt-6 mb-4">Our Mission</h2>
        <p>
          Our mission is to empower investors and businesses by providing them
          with sophisticated, yet user-friendly financial tools and services. We
          strive to make advanced financial analysis and decision-making
          accessible to everyone, breaking down barriers in the fintech world.
        </p>
        <h2 className="text-xl mt-6 text-bold mb-4">Core Values</h2>
        <ul>
          <li>
            <strong>Innovation:</strong> We are committed to continuous
            improvement and staying ahead of the curve in financial
            technologies.
          </li>
          <li>
            <strong>Integrity:</strong> Trust and transparency are at the core
            of all our interactions and services.
          </li>
          <li>
            <strong>Collaboration:</strong> We believe in a synergistic
            approach, working together with our clients and partners to achieve
            mutual success.
          </li>
          <li>
            <strong>Excellence:</strong> We relentlessly pursue excellence in
            every aspect of our work, from customer service to technology
            development.
          </li>
        </ul>
        <h2 className="text-xl mt-6 mb-4 text-bold ">Our Services</h2>
        <p>At NewHorizon, we offer a range of fintech solutions, including:</p>
        <ul>
          <li>Quantitative financial cloud computing plugins</li>
          <li>Advanced fund management with AI integration</li>
          <li>Real-time trading signals and analytics</li>
        </ul>
        <h2 className="text-xl mt-6 text-bold mb-4">Contact Us</h2>
        <p>
          Interested in learning more about our services or collaborating with
          us? Reach out to us at{" "}
          <a className="text-mblue" href="mailto:newhorizontech@163.com">
            newhorizontech@163.com
          </a>
        </p>
        <p>
          Join us in our journey to revolutionize the fintech landscape.
          Together, we can unlock new horizons of financial wisdom and
          technology.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
