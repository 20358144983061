import React from "react";
import Header from "./common/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
const NotFoundStyles = `
  /* 404 Page Not Found CSS */
  .NotFound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #343a40; /* Sleek dark grey background for modern look */
  }

  .NotFound-content {
    text-align: center;
    max-width: 560px; /* Limit content width for better readability */
    width: 100%; /* Responsive width */
    margin: auto;
    color: white;
    padding: 20px;
    border-radius: 5px;
  }

  .content-title {
    font-size: 150px; /* Extra large font size for '404' */
    margin-bottom: 32px; /* Increased space below the '404' title */
    color: #f39c12; /* Theme color for contrast */
    text-shadow: 2px 2px #000; /* Text shadow for depth */
  }

  .content-text {
    font-size: 24px; /* Clear instruction text size */
    margin-bottom: 32px; /* Space before the button */
  }

  .home-link {
    display: inline-block; /* To apply padding and margin correctly */
    color: #f39c12; /* Button text color */
    background-color: transparent; /* Transparent background */
    text-decoration: none; /* No underline */
    font-weight: bold; /* Bold font for button text */
    font-size: 18px; /* Font size for visibility */
    border: 2px solid #f39c12; /* Solid border with theme color */
    padding: 12px 24px; /* Ample padding for button size */
    border-radius: 5px; /* Rounded corners for the button */
    transition: all 0.2s ease-in-out; /* Smooth transition for all properties */
    cursor: pointer; /* Cursor to pointer to signify clickable */
    margin-top: 20px; /* Space from text to button */
  }

  .home-link:hover {
    color: #343a40; /* Text color on hover to dark grey */
    background-color: #f39c12; /* Background color on hover to theme color */
    text-decoration: none; /* No underline on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
  }
`;

function NotFound() {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>404 Not Found | New Horizon</title>
          <meta name="robots" content="noindex" />
        </Helmet>

        <Header />
        <div className="NotFound-container">
          <div className="NotFound-content">
            <h1 className="content-title">404</h1>
            <p className="content-text">
              Sorry, we can't find the page you're looking for.
            </p>
            <a href="/" className="home-link">
              Return to Home
            </a>
          </div>
          <style>{NotFoundStyles}</style>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default NotFound;
