import React from "react";
import Header from "./common/Header";
import ScatterChartExample from "./common/ScatterChart";
//import HeatMapExample from "./common/HeatMapExample";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet";

function Fund() {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div className="mx-auto p-5 px-8 pb-[80px] rounded-lg bg-black text-white font-mono text-base">
        <ScatterChartExample />
        {/*  作为热力图不可以，热力图是二维数组 <HeatMapExample /> */}
      </div>
      <Footer />
    </div>
  );
}

export default Fund;
