/* 
以下是整合了英文页面名称、新的文件名，以及相关功能解释的列表：

1. **Dashboard (Dashboard.js)** - 主页和概览界面，显示市场和个人更新信息。
2. **Market Data & Fund Info (MarketDataFundInfo.js)** - 显示市场数据和您自己发行的基金数据的页面。
3. **Analytics (Analytics.js)** - 提供数据分析工具的页面。
4. **Trading Platform (TradingPlatform.js)** - 执行交易的平台页面。
5. **Quant Research (QuantResearch.js)** - 量化研究和因子库信息的页面。
6. **News & Education (NewsEducation.js)** - 提供最新金融新闻和教育资源的综合页面。
7. **Account Management (AccountManagement.js)** - 个人账户设置和偏好管理的页面。
8. **Investor Support (InvestorSupport.js)** - 为投资者提供支持和教育资料的页面。

为了更好地反映修改后的内容，您可以考虑以下新的文件名来体现它们增加的功能和信息：

1. `Dashboard.js` - 保持原名，作为主页和概览页面。
2. `MarketDataFundInfo.js` - "市场数据"与"基金信息"结合，表示这个页面现在包含了市场数据和AI基金概览。
3. `Analytics.js` - 保持原名，分析工具页面。
4. `TradingPlatform.js` - 保持原名，交易平台页面。
5. `QuantResearch.js` - 原名"FactorLibrary.js"改为"QuantResearch.js"，更全面地代表量化研究和因子库。
6. `NewsEducation.js` - 将"新闻和研究"与"支持或教育"结合，既提供新闻更新，也提供教育资料。
7. `AccountManagement.js` - 保持原名，用于账户管理。
8. `InvestorSupport.js` - 原名"SupportEducation.js"改为"InvestorSupport.js"，强调为投资者提供支持和教育资源。

这样的命名不仅清晰地表达了每个页面的核心功能，而且还能够在有限的菜单空间内优化用户导航体验。

确定页面的重要程度通常取决于业务需求和用户行为。以一个典型的金融服务网站为例，重要程度的顺序可能如下：

1. **Dashboard (Dashboard.js)** - 作为用户的主入口和总览，通常是最重要的页面。
2. **Market Data & Fund Info (MarketDataFundInfo.js)** - 市场数据和基金信息对用户做出投资决策至关重要。
3. **Trading Platform (TradingPlatform.js)** - 实时交易平台对于执行交易的用户非常重要。
4. **Quant Research (QuantResearch.js)** - 量化研究对专业用户和分析师来说是核心工具。
5. **Analytics (Analytics.js)** - 分析工具帮助用户理解数据和市场动向。
6. **Account Management (AccountManagement.js)** - 账户管理保证了用户可以管理他们的个人信息和偏好设置。
7. **News & Education (NewsEducation.js)** - 最新的新闻和教育资源对于保持用户的知识更新很有帮助。
8. **Investor Support (InvestorSupport.js)** - 投资者支持是增强用户体验和提供帮助的重要环节。

不过，这个顺序应该根据您的客户群体和业务模式进行调整。例如，如果您的用户群体重视教育和研究，那么"新闻与教育"可能会更为重要。
 */

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { LogoutButton } from "./LogoutButton";
import { useTranslation } from "react-i18next";
import { FaCog, FaLanguage } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0); // 当滚动超过一定距离时设为 true
    };

    // 添加滚动事件监听
    window.addEventListener("scroll", handleScroll);

    // 清理函数
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const navLinkStyles = (path) => ({
    color: isActive(path) ? "#2962ff" : "white",
    textDecoration: "none",
    padding: "10px 15px",
    fontSize: 16,
    fontWeight: 500,
  });

  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "zh" : "en";
    i18n.changeLanguage(newLanguage);
    // 存储当前语言设置到浏览器本地存储
    localStorage.setItem("language", newLanguage);
  };

  // 从浏览器本地存储中获取当前语言设置
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const currentLanguage = i18n.language === "en" ? "English" : "中文"; // Determine the current language

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  // 处理登出逻辑的函数
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false); // 更新状态以反映用户已登出
  };

  useEffect(() => {
    // 你也可以在这里添加逻辑来验证token的有效性
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // 更新窗口宽度状态
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 根据屏幕宽度决定是否显示汉堡包按钮和导航菜单
  const isMobile = windowWidth <= 768;
  const shouldHideMenu = windowWidth > 768 && windowWidth <= 1024;

  // 根据屏幕宽度决定按钮排列方式
  const isWideScreen = windowWidth > 1024;

  // 根据按钮排列方式设置样式
  const buttonContainerStyle = {
    display: isWideScreen ? "flex" : "block", // 水平排列或垂直排列
    flexDirection: isWideScreen ? "row" : "column", // 水平排列时使用行布局，垂直排列时使用列布局
  };

  const buttonStyle = {
    padding: "5px 15px",
    borderRadius: "4px",
    fontSize: "12px",
    margin: windowWidth <= 1024 ? "30px 0px 30px 0px" : "10px 30px 10px 10px", // 根据屏幕宽度设置margin
  };

  const iconStyle = {
    marginRight: "8px", // 添加一些间距以分隔图标和文本
    fontSize: "24px", // 增大图标的大小
  };

  // 汉堡包按钮样式
  const menuButtonStyles = {
    display: isMobile || shouldHideMenu ? "block" : "none", // 在768到1024像素宽度间显示按钮
    background: "none",
    border: "none",
    color: "white",
    fontSize: "24px",
    cursor: "pointer",
    outline: "none",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
  };

  // 导航样式
  const navStyles = {
    display: isMenuOpen || (!isMobile && !shouldHideMenu) ? "flex" : "none",
    flexDirection: isMobile || shouldHideMenu ? "column" : "row", // 在移动端或中等屏幕宽度下使用垂直方向的折叠菜单
    alignItems: "center",
    justifyContent: "right",
    position: isMobile || shouldHideMenu ? "absolute" : "relative",
    top: isMobile || shouldHideMenu ? "60px" : "unset",
    left: 0,
    right: 0,
    width: "100%",
    background: isMobile || shouldHideMenu ? "#000" : "transparent",
    margin: 0,
    padding: isMobile ? "10px 0" : "0",
    transition: "all 0.3s ease",
  };

  const linkStyle = {
    color: "white",
    padding: "8px 10px",
    textDecoration: "none", // 去除下划线
    backgroundColor: "#222", // 使用彭博终端的高亮颜色
    border: "none",
    marginLeft: "4px",
    borderRadius: "4px",
    fontSize: "12px",
    minWidth: "40px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const loginButtonStyle = {
    ...linkStyle,
    background: "#222",
    margin: windowWidth <= 1024 ? "30px 0px" : "0px 10px 0px 6px", // 根据屏幕宽度设置margin
  };

  const registerButtonStyle = {
    ...linkStyle,
    backgroundColor: "#222",
    margin: windowWidth <= 1024 ? "0px 0px" : "0px", // 根据屏幕宽度设置margin
  };

  const buttonVariants = {
    hover: {
      scale: 1.05, // 缩放到 105% 大小
      backgroundColor: "#333", // 悬停时更改背景颜色为 #333
    },
    tap: {
      scale: 0.95, // 缩放到 95% 大小
    },
  };

  // 头部样式
  const headerStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#000",
    padding: "10px 0px",
    height: 60,
    position: isSticky ? "fixed" : "relative", // 根据 isSticky 更改位置属性
    top: 0,

    zIndex: 1000, // 确保头部在其他内容上方
    transition: "all 0.3s ease", // 平滑过渡效果
    // 其他样式保持不变
  };

  // Logo样式
  const logoStyles = {
    color: "#ffffff", // 黑色或深蓝色
    fontSize: isMobile ? "20px" : "18px", // 调整字体大小
    fontWeight: "bold", // 粗体
    fontFamily: "Arial, Helvetica, sans-serif", // 无衬线字体
    letterSpacing: "1px", // 字母间距
    width: isMobile ? "210px" : "330px",
    textTransform: "uppercase", // 大写字母
    padding: isMobile ? "0 20px" : "0", // 保持原有的padding设置
  };

  const logoLinkStyle = {
    ...logoStyles,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  };

  return (
    <header className="w-[100vw]" style={headerStyles}>
      <a className="p-0 " href="/" style={logoLinkStyle}>
        <img
          className="h-[26[px] w-[26px] mr-[16px] ml-[30px] sm:!ml-4 md:!ml-6"
          src={logo}
          alt="New Horizon Logo，新长慧和 Logo"
        />

        <div>{t("Header:Logo")}</div>
      </a>
      <nav style={navStyles}>
        <a
          href="https://chromewebstore.google.com/detail/newhorizon-optimizer/icjbokeihkhdilfokigedgpoifnagkjo"
          className="no-underline px-4 py-2.5 text-f font-medium whitespace-nowrap"
        >
          {t("Header:TradingviewOptimizer")}

          <span className="ml-2 bg-G text-white px-2 py-1 sm:w-[80px] sm:text-[10px] md:text-xs lg:text-xs font-bold rounded-full inline-block whitespace-nowrap">
            Try for free
          </span>
        </a>

        <a href="/subscribe" style={navLinkStyles("/subscribe")}>
          {t("Header:SubscriptionPlan")}
        </a>
        <a href="/FactorLibrary" style={navLinkStyles("/FactorLibrary")}>
          {t("Header:FactorLibrary")}
        </a>

        <a href="/about-us" style={navLinkStyles("/about")}>
          {t("Header:AboutUs")}
        </a>
        {!isAuthenticated && (
          <div style={buttonContainerStyle}>
            <motion.a
              className="whitespace-nowrap"
              href="/login"
              style={{ ...loginButtonStyle }}
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              {t("Header:Login")}
            </motion.a>
            <motion.a
              className="whitespace-nowrap"
              href="/register"
              style={{ ...registerButtonStyle }}
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              {t("Header:Register")}
            </motion.a>
          </div>
        )}
        {isAuthenticated && (
          <a
            href="/accountmanagement"
            className="flex items-center"
            style={navLinkStyles("/accountmanagement")}
          >
            <FaCog className="mr-2" /> {t("Header:AccountManagement")}
          </a>
        )}
        {isAuthenticated && <LogoutButton onLogout={handleLogout} />}
        <motion.button
          className="bg-G text-f border-none flex items-center cursor-pointer"
          onClick={toggleLanguage}
          style={buttonStyle}
          variants={buttonVariants}
          whileHover="hover"
          whileTap="tap"
        >
          <FaLanguage style={iconStyle} /> {currentLanguage}
        </motion.button>
      </nav>
      <button
        className=""
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        style={menuButtonStyles}
      >
        {isMenuOpen ? "×" : "☰"}
      </button>
    </header>
  );
};

export default Header;
