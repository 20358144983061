import React from "react";

const RangeDetectorStrategyDescription = ({ i18n }) => (
  <div>
    {i18n.language === "en" && (
      <div className="factor_info_en">
        <p>
          <b>[NewHorizon]⚛️ Range Detector Indicator Strategy</b>
        </p>
        <br />
        <p>
          <b>📈 Strategy Overview:</b>
          <br />
          Welcome to the [NewHorizon]⚛️ Range Detector Indicator strategy! This
          strategy focuses on identifying potential pivot points in the market
          using deviation and depth parameters. By analyzing the price movements
          and their deviations, this strategy aims to provide traders with
          insights into potential trend reversals and range-bound conditions.
        </p>{" "}
        <br />
        <p>
          <b>Key Features:</b>
          <br />- <b>Pivot Settings:</b> Customize the deviation and depth
          parameters to adjust the sensitivity of the pivot detection.
          <br />- <b>Range Detection:</b> Activate range detection to identify
          periods of consolidation in the market.
          <br />- <b>Trend Settings:</b> Adjust the bands' percentage offset to
          fine-tune the trend identification.
          <br />- <b>UI Settings:</b> Customize the appearance of the indicator
          with options for coloring bars and displaying bands.
        </p>{" "}
        <br />
        <p>
          <b>🚀 Elevate Your Trading with NewHorizon Optimizer:</b>
          <br />
          Optimize your trading strategies with the NewHorizon Optimizer, a
          cutting-edge Chrome extension for TradingView. Explore more and refine
          your strategies to perfection at [NewHorizon](https://nhwt.tech).
        </p>{" "}
        <br />
        <p>
          <b>📊 Strategy Details:</b>
          <br />
          The [NewHorizon]⚛️ Range Detector Indicator strategy employs the
          following settings:
          <br />- <b>Deviation:</b> A multiplier affecting the price deviation
          from the previous pivot for a new pivot bar.
          <br />- <b>Depth:</b> The minimum number of bars considered when
          analyzing pivots.
          <br />- <b>Bands % Offset:</b> The percentage offset for the upper and
          lower bands in trend settings.
          <br />- <b>Color Bars and Bands:</b> Options to color bars and show
          upper, lower, and mid bands for better visualization.
        </p>{" "}
        <br />
        <p>
          <b>🤖 How to Use:</b>
          <br />
          1. Apply the [NewHorizon]⚛️ Range Detector Indicator to your chart.
          <br />
          2. Customize the pivot settings to suit your trading style.
          <br />
          3. Activate range detection and adjust the trend settings as needed.
          <br />
          4. Monitor the indicator for pivot points and trend changes.
        </p>{" "}
        <br />
        <p>
          <b>💡 Tips for Success:</b>
          <br />
          - Keep an eye on the deviation and depth parameters to capture
          significant pivots effectively.
          <br />
          - Use range detection to identify potential consolidation areas in the
          market.
          <br />- Combine this indicator with other analysis tools for a
          comprehensive trading strategy.
        </p>{" "}
        <br />
        <p>
          <b>🌐 Discover More:</b>
          <br />
          Ready to take your trading to new heights? Check out the NewHorizon
          Optimizer and explore a world of optimization possibilities:
          [NewHorizon](https://nhwt.tech).
        </p>
      </div>
    )}
    {i18n.language === "zh" && (
      <div class="factor_info_zh">
        <p>
          <b># [NewHorizon]⚛️ 范围检测指标策略</b>
        </p>
        <p>
          <b>📈 策略概览：</b>
          <br />
          欢迎来到 [NewHorizon]⚛️
          范围检测指标策略！此策略专注于使用偏差和深度参数识别市场中的潜在枢轴点。通过分析价格运动及其偏差，此策略旨在为交易者提供潜在趋势反转和区间绑定条件的见解。
        </p>
        <p>
          <b>关键特性：</b>
          <br />- <b>枢轴设置：</b> 自定义偏差和深度参数以调整枢轴检测的灵敏度。
          <br />- <b>范围检测：</b> 激活范围检测以识别市场中的整合期。
          <br />- <b>趋势设置：</b> 调整带宽百分比偏移以微调趋势识别。
          <br />- <b>界面设置：</b> 自定义指标外观，包括颜色条和显示带选项。
        </p>
        <p>
          <b>🚀 通过 NewHorizon 优化器提升您的交易：</b>
          <br />
          使用 NewHorizon 优化器优化您的交易策略，这是一个用于 TradingView
          的尖端 Chrome 扩展。在 [NewHorizon](https://nhwt.tech)
          探索更多并完善您的策略。
        </p>
        <p>
          <b>📊 策略细节：</b>
          <br />
          [NewHorizon]⚛️ 范围检测指标策略采用以下设置：
          <br />- <b>偏差：</b> 影响新枢轴条与前一枢轴的价格偏差的乘数。
          <br />- <b>深度：</b> 分析枢轴时考虑的最小条数。
          <br />- <b>带宽 % 偏移：</b> 趋势设置中上下带宽的百分比偏移。
          <br />- <b>颜色条和带宽：</b>{" "}
          颜色条选项和显示上、下、中带宽以更好地进行可视化。
        </p>
        <p>
          <b>🤖 如何使用：</b>
          <br />
          1. 将 [NewHorizon]⚛️ 范围检测指标应用于您的图表。
          <br />
          2. 根据您的交易风格自定义枢轴设置。
          <br />
          3. 激活范围检测并根据需要调整趋势设置。
          <br />
          4. 监视指标以寻找枢轴点和趋势变化。
        </p>
        <p>
          <b>💡 成功秘诀：</b>
          <br />
          - 密切关注偏差和深度参数以有效捕捉重要枢轴点。
          <br />
          - 使用范围检测来识别市场中潜在的整合区域。
          <br />- 将此指标与其他分析工具结合使用，以形成全面的交易策略。
        </p>
        <p>
          <b>🌐 发现更多：</b>
          <br />
          准备将您的交易提升到新的高度吗？查看 NewHorizon
          优化器并探索优化的世界： [NewHorizon](https://nhwt.tech)。
        </p>
      </div>
    )}
  </div>
);

export const RangeDetectorStrategy = {
  id: 4,
  nameKey: "factorLibrary:factorLibrary.RangeDetectorStrategyTitle",
  descriptionComponent: RangeDetectorStrategyDescription,
  factorUrls: [
    "https://www.tradingview.com/script/ZQFomv82-NewHorizon-Range-Detector-Indicator/",
  ],
  imageName: "RangeDetectorStrategy",
};
